<template>
  <div id="Password" class="wrap">
    <van-nav-bar
      z-index="10"
      :fixed="true"
      placeholder
      :title="$t('My.PersonalInformation.Password.title')"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-notice-bar color="var(--theme)" background="var(--bg)">
      {{ $t('My.PersonalInformation.Password.PleaseEnter') }}
    </van-notice-bar>
    <van-field
      label-width="6.5rem"
      colon
      v-model="DataArr.OldPassword"
      :label="$t('password.old')"
      :placeholder="$t('password.old.placeholder')"
    />
    <!-- 新密码 -->
    <van-field
      label-width="6.5rem"
      colon
      v-model="DataArr.Password"
      :label="$t('My.PersonalInformation.Password.ConfirmPassword')"
      :placeholder="$t('My.PersonalInformation.Password.ConfirmPasswordNote')"
    />
    <!-- 再次确认密码 -->
    <van-field
      label-width="8rem"
      colon
      v-model="DataArr.ConfirmPassword"
      :label="$t('password.confirmPassword')"
      :placeholder="$t('password.confirmPassword.placeholder')"
    />
    <div class="button-confirm">
      <van-button size="normal" type="default" block @click="ButtomEvent()">{{
        $t('My.PersonalInformation.Password.Button')
      }}</van-button>
    </div>
  </div>
</template>

<script>
import { getNewPhone } from '@/utils/tools'
import Modelu from '@/utils/Module'
import { REQUEST_API } from '@/http/api' // 引入封装的axios
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  NoticeBar,
  Field,
  Toast,
} from 'vant'

export default {
  name: 'portrait',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [Toast.name]: Toast,
  },
  data() {
    return {
      isDisabled: false, // 验证码按钮
      userInfo: {},
      DataArr: {
        Phone: '',
        Code: '112233',
        // OldPassword: '',
        // NewPassword: '',
        Password: '',
        buttonName: this.$t('My.PersonalInformation.Password.ButtonCode'), // 按钮的提示
        time: 60,
      },
      codeImg: '',
      GetCodeTime: 0,
      GetCodeInterval: null
    }
  },
  created() {
    // this.ButtomCode();
    this.getUserInfo();
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    // 获取验证码
    ButtomCode() {
      if (this.DataArr.Phone !== '') {
        if(!this.GetCodeTime){
          this.GetCodeTime = 60;
          this.GetCodeInterval = setInterval(()=>{
            this.GetCodeTime -= 1;
            if(this.GetCodeTime === 0){
              clearInterval(this.GetCodeInterval);
            }
          },1000)
          let me = this
          // 获取验证码的请求
          let formdata = {
            // phone: this.DataArr.Phone,
            phone: getNewPhone(this.DataArr.Phone),
            type: 1,
            cmd: '100002',
            /**
             * TO DO
             * 后面新增的
             */
            channel: Modelu.signStrFunc(),
            xsend: Modelu.getXsend(),
          }
          this.$api.getSMS({ userphone: this.DataArr.Phone,must: 2 },res=>{
            this.$toast(this.$t('Hint.SendCodeSuccess'));
          },err=>{
            this.$toast(err)
          })
          // REQUEST_API(formdata).then((data) => {
          //   console.log(data)
          //   if (data.data.sc == 0) {
          //     /**
          //      * TO DO
          //      * 现在没有图形验证码了
          //      */
          //     this.codeImg = `data:image/jpg;base64,${data.data.notifyList[0].data.code}`
          //     me.isDisabled = true
          //     /**
          //      * TO DO
          //      * 下面的我没有动
          //      */
          //     let interval = window.setInterval(function () {
          //       me.DataArr.buttonName = me.DataArr.time
          //       --me.DataArr.time
          //       if (me.DataArr.time < 0) {
          //         me.DataArr.buttonName = me.$t(
          //           'My.PersonalInformation.Password.ButtonCode'
          //         )
          //         me.DataArr.time = 60
          //         me.isDisabled = false
          //         window.clearInterval(interval)
          //       }
          //     }, 1000)
          //   }
          // })
        }
      } else {
        Toast(this.$t('My.PersonalInformation.Password.verifyPhone'))
      }
    },
    // 提交的
    ButtomEvent() {
      if (
        // this.DataArr.Phone != '' &&
        this.DataArr.Code != '' &&
        this.DataArr.Password != '' &&
        // this.DataArr.NewPassword != ''
        this.DataArr.OldPassword != ''
      ) {
        if(this.DataArr.Password !== this.DataArr.ConfirmPassword) {
          Toast(this.$t('hint.passwordDiff'))
          return
        }
        let formData = {
          cmd: '200006',
          nickname: this.Name,
          uid: this.module.UIDEvent(),
          phone: this.DataArr.Phone,
          code: this.DataArr.Code,
          pwd: this.DataArr.Password,
        }
        this.$api.editPwd({
          code: this.DataArr.Code,
          newpass: this.DataArr.Password,
          // newpass: this.DataArr.NewPassword,
          oldpass: this.DataArr.OldPassword
        },res=>{
          Toast(this.$t('Hint.success'))
          // this.$router.go(-1)
          setTimeout(()=>{
            this.$router.replace({name: 'login'})
          },800)
        },err=>{
          Toast(this.$t('Hint.failed'))
        })
        // REQUEST_API(formData)
        //   .then((resp) => {
        //     if (resp.data.sc == 0) {
        //       Toast(this.$t('Hint.success'))
        //       this.$router.go(-1)
        //     } else {
        //       Toast(this.$t('Hint.failed'))
        //     }
        //   })
        //   .catch((e) => {
        //     console.log(e)
        //   })
      } else {
        Toast(this.$t('My.PersonalInformation.Password.PhoneNew'))
      }
    },
    getUserInfo(){
      Toast.loading({
        forbidClick: true,
        duration: 0,
      })
      this.$api.getUserInfo(res=>{
        this.DataArr.Phone = res.userphone;
        Toast.clear();
      },err=>{
        Toast.clear();
        this.$toast(err);
      })
    }
  },
}
</script>

<style lang="less">
#Password {
  width: 100%;
  height: 100%;
  .van-notice-bar{
    border-bottom: 1px solid var(--theme);
  }
  .van-cell {
    background-color: var(--bg);
    .van-cell__title {
      > span {
        font-size: 0.875rem;
      }
    }
    .van-field__label{
      color: var(--sizeColor);
    }
    .van-field__control{
      color: var(--theme);
    }
    input::placeholder{
      color: var(--theme);
    }
  }
  .van-cell::after{
    border-bottom: 1px solid var(--theme);
  }
  .button-confirm {
    width: 100%;
    margin-top: 1.5625rem;
    > .text {
      color: red;
      width: 90%;
      margin: 0 auto;
      font-size: 0.75rem;
      margin-bottom: 1.5625rem;
    }
    .code-img {
      width: 90%;
      // height: calc(44rem / 16);
      height: calc(80rem / 16);
      margin: calc(15rem / 16) auto;
      display: flex;
    }
    .van-button {
      width: 90%;
      margin: 0 auto;
      border-radius: 0.5rem;
      // background-color: #2f3848;
      // background-color: var(--bg);
      // color: var(--theme);
      background-color: var(--newBtn);
      color: var(--btnsizeColor);
      border: none;
      // color: #ffffff;
    }
    .van-button::before{
      border: none;
    }
  }
}
</style>
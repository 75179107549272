export default {
  signStr: '1001', // 渠道
  // tab按钮
  tab: {
    my: 'Mon',
    home: 'Maison',
    record: 'Enregistrer',
    grab: 'Saisir',
    financing: 'Financement',
    make_money: 'Saisir'
  },
  newTab: {
    home: 'Maison',
    money: 'Argent',
    shop: 'Boutique',
    order: 'Commande',
    my: 'Mon',
    team: 'Équipe'
  },
  // 公共的key
  common: {
    unrealized: 'Action',
    all: 'Tous',
    distributionTip: '0 Livraison 0 Frais de distribution',
    easeBack: 'Atténuer',
    addCarSuccess: 'Rejoignez les achats avec succès',
    deleteConfirm: "Êtes-vous sûr de supprimer l'élément sélectionné?"
  },
  My: {
    download: "Télécharger l'application",
    personal: 'Informations personnelles',
    preOrde: 'Dossier de précommande',
    account: 'Détails du compte',
    recharge: 'Record de reprise',
    withdrawal: 'Retrait',
    invite: 'Inviter des amis',
    announcement: 'Annonce',
    service: 'Service',
    team: "Rapport de l'équipe",
    quit: 'Arrêter',
    logoutEvent: 'Confirmer à la sortie?',
    language: 'Paramètres de langue',
    languageButton: {
      english: 'English', // 英语
      indonesian: 'Indonesian', // 印尼语
    },
    logout: 'Se déconnecter', // 登出
    // 个人中心的值-小饶
    MemberInformation: {
      SilverMember: 'Membre de Silver',
      Invitation: "Code d'invitation",
      balance: 'Solde du compte',
      Recharge: 'Reprise',
      Withdraw: 'Retirer',
      forzenAll: 'Argent surgelé'
    },
    // 个人中心-小饶 -Personal information
    PersonalInformation: {
      // 个人中心选项的
      Page: {
        title: 'Mon information',
        Avatar: 'Avatar',
        Nickname: 'Surnom',
        PhoneNumber: 'Numéro de téléphone',
        BankAccounts: 'Mon adresse de portefeuille',
        Password: 'Mot de passe',
        TransactionPassword: 'Mot de passe de transaction',
        ReceivingAddress: 'Adresse de réception'
      },
      // 个人中心-修改头像
      portrait: {
        SetAvatar: 'Set Avatar',
        button: 'Définir maintenant'
      },
      // 个人中心-修改用户名
      Name: {
        ModifyNickname: 'Modifier le surnom',
        title: 'Modifiez votre surnom',
        name: 'Surnom',
        Modify: 'Modifier',
        Please: 'Veuillez saisir un surnom'
      },
      // 个人中心-银行卡信息
      BankAccounts: {
        BankAccounts: 'Carte bancaire contraignante',
        YourInformation: 'Vos informations',
        RealName: 'Vrai nom',
        RealNameNote: 'Veuillez définir votre vrai nom',
        PhoneNo: 'Numéro de téléphone',
        PhoneNoNote: 'Veuillez régler le numéro de téléphone',
        YourBankCard: 'Vos informations de carte bancaire',
        BankAccount: 'Compte bancaire',
        BankAccountNote: 'Veuillez définir le compte bancaire',
        BankName: 'Nom de banque', // 银行name
        BankNameData: {
          option1: 'Choisissez la banque de compte',
          option2: 'Asie centrale bancaire',
          option3: 'Banque Negara Indonésie',
          option4: 'Banque Rakyat Indonésie',
          option5: 'Mandiri'
        }, // 银行选项
        BankBranch: 'Succursale de la banque',
        BankBranchNote: 'Veuillez définir la succursale bancaire',
        MoreInformation: "Plus d'information",
        WhatsApp: 'Whatsapp',
        WhatsAppNote: 'Veuillez définir le numéro WhatsApp',
        Text: "Rappel: Bonjour, afin de garantir vos droits, veuillez lier le numéro de carte correct et les informations bancaires. Si le compte ne peut pas être reçu en raison d'un remplissage incorrect, cette plate-forme ne prend aucune responsabilité!",
        Settings: 'Paramètres', // 确定（提示）
      },
      // 个人中心-修改密码
      Password: {
        title: 'Modifier le mot de passe de connexion',
        PleaseEnter: 'Veuillez saisir votre nouveau mot de passe',
        phone: 'téléphone',
        NewPhone: 'Veuillez saisir votre numéro de téléphone portable',
        verificationCode: 'code Verifikasi',
        verificationCodeNote: 'Veuillez saisir un code de vérification',
        oldPassword: 'ancien mot de passe',
        oldPasswordNote: 'Entrez votre ancien mot de passe',
        ConfirmPassword: 'nouveau mot de passe',
        ConfirmPasswordNote: 'Entrez votre nouveau mot de passe',
        Text: "N'oubliez pas le mot de passe, si vous oubliez le mot de passe, veuillez contacter le service client.",
        ButtonCode: 'Obtenir le code de vérification',
        Button: 'OK pour modifier',
        verifyPhone: 'Veuillez saisir votre numéro de téléphone portable',
        PhoneNew: 'Veuillez confirmer que vous avez terminé'
      },
      // 个人中心-修改支付密码
      // 个人中心-修改用户地址
      Site: {
        title: "Réception de la modification de l'adresse",
        ReceivingIdentity: "Recevoir des informations d'identité",
        ReceivingAddress: "Réception des informations d'adresse",
        DetailedAddress: 'Adresse détaillée',
        DetailedAddressNote: "Veuillez saisir l'adresse détaillée",
        Set: 'Définir comme adresse commune',
        Button: 'Confirmer',
        hint: 'Veuillez confirmer que vous avez terminé'
      },
      // 个人中心-账户信息
      AccountData: {
        title: 'détails de la transaction',
        To: 'POUR',
        search: 'Recherche',
        Cancel: 'Annuler',
        Semua: 'Tous les types',
        Penarikan: 'Billets de retrait',
        IsiUlang: 'Mutation complémentaire'
      },
      // 个人中心-充值记录
      RechargeRecord: {
        title: 'Record de reprise',
        Recharge: 'Remplir hors ligne',
        Online: 'Recharge en ligne',
        Amount: 'Montant de recharge:',
        Order: 'Numéro de commande:',
        Status: 'État de recharge:'
      },
      // 个人中心-提款记录
      WithdrawalRecord: {
        title: 'Dossier de retrait',
        withdrawal: 'Retrait de la carte bancaire'
      },
      // 个人中心-邀请好友
      InviteFriends: {
        Invite: 'Inviter le lien: cliquez sur Copier',
        InvitationCode: "Code d'invitation:",
        Click: 'Copier le lien'
      },
      // 个人中心-系统公告
      Announcement: { title: 'Annonce', System: 'Annonce système' },
      // 个人中心-团队报告
      TheTeamReport: {
        title: "Rapport de l'équipe",
        To: 'Pour',
        search: 'recherche',
        Cancel: 'Annuler',
        balance: "Montant de l'équipe",
        turnover: "Reprise de l'équipe",
        recharge: "Profit de commande d'équipe",
        withdrawal: "Retrait de l'équipe",
        Commission: "Profit de commande d'équipe",
        charge: 'Nombre total de personnes',
        people: "Nombre d'utilisateurs avec recharge",
        members: 'Nombre de nouveaux utilisateurs',
        NewPeople: 'NOUVEAU USIN',
        Active: 'Nombre de nouveaux utilisateurs',
        Level1: 'Niveau 1',
        Level2: 'Niveau 2',
        Level3: 'Niveau 3'
      },
    },
    // 首页跳转下的四个静态页面 - 小饶
    PlatformIntroduction: {
      PlatformIntroduction: 'Introduction de la plate-forme',
      Two: 'Cette plate-forme est une alliance composée de millions de commerçants. Son principe de service est: les traders de plate-forme augmentent le volume de négociation et les membres de la plate-forme gagnent des revenus de bénéfices en recevant des commandes.',
      Three: "Le cloud intelligent tiers contrôle la plate-forme de correspondance des commandes d'hôte. La plate-forme coopère avec d'autres marchands de plate-forme d'achat en ligne pour faire correspondre les commandes commerciales avec les utilisateurs de la plate-forme via un système cloud intelligent, afin d'augmenter le volume des transactions et la réputation du vendeur sur la plate-forme principale. La méthode de livraison du bon de commande est améliorée. La commande est automatiquement envoyée par le serveur cloud intelligent distant. Les utilisateurs de la plate-forme n'ont qu'à fournir l'adresse complète du récepteur, le numéro de téléphone et certaines informations d'achat directement sur la plate-forme pour entrer l'hôte Smart Cloud.",
      Four: "Afin d'aider les vendeurs à améliorer efficacement le taux de réussite des commandes et à éviter le risque que les vendeurs vérifient les fausses commandes, la plate-forme optimisera la correspondance des commandes de la journée en fonction de votre type de propriété intellectuelle et d'équipement opérationnelle actuelle. Par conséquent, la correspondance des commandes doit être réalisée par l'algorithme intelligent du cloud computing. Veuillez attendre patiemment. Les utilisateurs de la plate-forme deviendront automatiquement l'agent de la plate-forme en faisant la promotion de nouvelles personnes à rejoindre la plate-forme, et ils peuvent obtenir des bénéfices supplémentaires.",
      Five: "Il vous suffit d'utiliser votre temps libre pour surfer sur Internet, et vous pouvez facilement réaliser des bénéfices riches chaque jour. Après avoir cliqué et réussi à envoyer la commande, le principal et le profit seront remboursés."
    },
    RuleDescription: {
      RuleDescription: 'Description de la règle',
      One: "1. Les membres doivent remplir l'adresse du destinataire et améliorer les informations personnelles avant de prendre la commande. Commissions des membres de la plate-forme différentes selon différentes tâches. Le nombre de commandes générées par le système est basée sur 30% à 70% du solde de votre compte.",
      Two: "2. Étant donné que l'ordre des marchandises nécessite un certain temps pour s'appliquer, l'ordre ne peut être reçu qu'avec le vendeur après la commande de la commande, donc l'achèvement de la Commission de la plate-forme et de la banque tripartite et des membres utilisent le système T + 1., À savoir: aujourd'hui, se retirer et de l'argent sera reçu la veille de la veille 23h59. Si la commande ne s'applique pas après l'heure spécifiée, la plate-forme paiera en argent, terminera le compte et émettra les paiements dans le délai spécifié.",
      Three: "3. L'état de la salle appropriée et le ratio des commissions des membres de l'argent: tous les membres de Silver peuvent prendre 10 commandes par jour, et la Commission pour la commande est de 0,30% du nombre de marchandises.",
      Four: "Membres d'or: Pour ouvrir les membres de l'or, vous devez inviter jusqu'à 3 personnes à entrer dans cette salle. Vous pouvez prendre 35 commandes par jour et la Commission des commandes représente 0,35% du montant de marchandises.",
      Five: "Membres de Platinum: Pour ouvrir les membres de Platinum, vous devez inviter jusqu'à 10 personnes à entrer dans cette salle. Vous pouvez obtenir 40 commandes par jour, et la Commission pour la commande est de 0,40% du nombre de marchandises.",
      Six: "Membres de Platinum: Pour ouvrir les membres de Platinum, vous devez inviter jusqu'à 10 personnes à entrer dans cette salle. Vous pouvez obtenir 40 commandes par jour, et la Commission pour la commande est de 0,40% du nombre de marchandises.",
      Seven: 'Membres Diamond: Pour ouvrir les membres Diamond, vous devez inviter 30 personnes à entrer dans cette salle. Vous pouvez obtenir 45 commandes par jour, et la Commission pour ces commandes représente 0,45% du montant des marchandises.',
      Eight: '4. Veuillez vérifier le numéro de carte de dépôt de plate-forme avant le remplissage et la plate-forme modifiera le numéro de compte irrégulièrement. La plate-forme ne sera pas responsable des pertes de biens causées par des erreurs opérationnelles personnelles.',
      Nine: "Remarque: veuillez vérifier les informations sur la carte bancaire de la plate-forme, qu'il s'agisse de cette plate-forme pour chaque transfert pour éviter votre perte économique.",
      Ten: "5. Pour protéger le bénéfice entre le vendeur et la plate-forme, donc chaque IP, le numéro de carte bancaire et le numéro de mobile ne peuvent s'appliquer qu'à un seul compte. Toutes les principales plates-formes E-Niaga ont des machines de contrôle des risques, si de nombreux utilisateurs dans la même zone et la même IP qui achètent dans le même magasin, entraîneront l'élimination des produits explosifs et affecteront la crédibilité et les ventes de vendeurs.",
      Eleven: "Si quelqu'un soumet une demande pour plusieurs comptes, les contrevenants gèlent les comptes en permanence et gelont les soldes de 30 jours. Lorsque vous devez retirer le solde gelé (fin de la fin de la période), veuillez contacter notre service client en ligne pour activer le compte à retirer.", //
      Twelve:
        "* Différents niveaux peuvent obtenir des nombres impairs et des ratios de commission. Plus le niveau d'adhésion est élevé, plus vous pouvez prendre de chiffres étranges. Plus l'ordonnance est élevée, plus la commission est élevée.", //
    },
    // 首页会员升级页面 - 小饶
    MemberUpgrade: {
      MemberUpgrade: 'Mise à niveau des membres',
      Member: 'Niveau des membres ：',
      Orders: 'Commandes disponibles tous les jours:',
      Commission: 'Augmentation des bénéfices',
      DedicatedOne: 'Service client dédié',
      DedicatedTwo: 'Service client dédié',
      Swipe: 'Glisser à gauche et à droite pour en voir plus',// 说明
      One: {
        Silver: 'Membre de Silver',
        Need: "Besoin d'inviter 0 personnes",
        Withdrawal: 'Limite de retrait: 50.000.000',
        quantity: 'Quantité de commande: 10 / jour',
        rate: 'Taux de bénéfice: 0,30%',
        fee: 'Frais de retrait: 2,00%',
        Activated: 'Activé'
      },
      Two: {
        Silver: "Membre de l'or",
        Need: "Besoin d'inviter 3 personnes",
        People: '3 personnes encore courtes',
        Withdrawal: 'Limite de retrait: 50.000.000',
        quantity: 'Quantité de commande: 35 / jour',
        rate: 'Taux de bénéfice: 0,35%',
        fee: 'Frais de retrait: 2,00%',
        Activated: 'Montant de recharge quotidienne: 1.000.000',
        Invite: 'Inviter maintenant'
      },
      Three: {
        Silver: 'Membre du platine',
        Need: "Besoin d'inviter 10 personnes",
        People: '10 personnes encore courtes',
        Withdrawal: 'Limite de retrait: 50.000.000',
        quantity: 'Quantité de commande: 40 / jour',
        rate: 'Taux de bénéfice: 0,40%',
        fee: 'Frais de retrait: 2,00%',
        Activated: 'Montant de recharge quotidienne: 1,500.000',
        Invite: 'Inviter maintenant'
      },
      Four: {
        Silver: 'Membre du diamant',
        Need: "Besoin d'inviter 30 personnes",
        People: '30 personnes encore courtes',
        Withdrawal: 'Limite de retrait: 50.000.000',
        quantity: 'Quantité de commande: 45 / jour',
        rate: 'Taux de bénéfice: 0,45%',
        fee: 'Frais de retrait: 2,00%',
        Activated: 'Montant de recharge quotidienne: 2.000.000',
        Invite: 'Inviter maintenant'
      },
    },
    PhoneNew: 'Veuillez confirmer que vous avez terminé', // 是否输入完毕的轻提示
    // 轻提示 - 小饶
    Toast: { One: 'Sauvegarde réussie', Two: 'Sans succès' },
    // 确定和取消的多文本 -- 小饶
    Button: { One: 'Annuler', Two: 'Confirmer' },
    logout: 'Se déconnecter', // 个人中心下的退出按钮-小饶
  },
  Financing: {
    time: 'Temps',
    day: 'Jour',
    Fina: 'Fonds',
    assets: 'Actif total',
    amount: 'Montant financier',
    revenue: 'Revenu total',
    earnings: "Revenus d'hier",
    Balance: 'Transfert de solde',
    RP: '  ""',
    Please: 'Veuillez saisir le transfert en quantité Silakan Masukkan Transfer Dalam Jumlah',
    Estimated: 'Revenus estimés',
    Income: 'Norme de revenu',
    TransferOut: 'Transférer',
    ransferIn: 'Transférer dans',
    SukuBung: 'Suku bunga',
    Transfer: 'Transfert en échec',
    Pleaseint: 'Veuillez saisir le montant',
    Successful: 'Transfert réussi',
    Please:
      'Veuillez saisir le transfert en montant, veuillez saisir le transfert en quantités', // 请输入转入金额
    RansferInSU: { FinancialAcc: "Dossier d'accès financier", Acc: "Journal d'accès" },
    // 首页客服页面
    ServiceSu: {
      Please: "Entrez s'il vous plait...",
      send: 'envoyer',
      Online: 'un service en ligne',
      Service: 'Service',
      Customer: 'Pelayanan Pelanggan',
      Ifyou: "Si vous rencontrez des problèmes et avez besoin d'aide, veuillez contacter en ligne Cunstomer Servic",
      Layanana8: 'Centre de service client n ° 8 (WhatsApp)',
      Layanana9: 'Centre de service client n ° 9 (WhatsApp)',
      Dedicated: 'Service de countomer dédié',
      Telegram8: 'Layanana Pelanggan n ° 8 (télégramme)',
      Telegram008: 'Telegram008',
      Telegram9: 'Layanana Pelanggan n ° 9 (télégramme)',
      Telegram009: 'Telegram009'
    },
  },
  Home: {
    "HallofFame": "Temple de la renommée",
    "Member": "NWES membre",
    "Mission": "Salle de mission",
    "Learnde": "Apprendre",
    "Hello": "Bonjour",
    "Let": "Laissez Victory Mall ouvrir la voie à la richesse",
    "total": "Mes actifs totaux",
    "Withdraw": "Retirer",
    "Recharge": "Reprise",
    "RP": " ",
    "earningsRp": "Les revenus d'hier",
    "incomeRp": "Incomières cumulatifs",
    "rarningsRp": "Les revenus d'aujourd'hui",
    "Service": "Service",
    "Invite": "Inviter",
    "Lazada": "Lazada",
    "Bukalapak": "Bukalapak",
    "Shopee": "Acheteur",
    "Tokopedia": "Tokopedia",
    "silver": "Canal pour les membres de l'argent",
    "gold": "Canal pour les membres de l'or",
    "platinum": "canal pour les membres du platine",
    "diamond": "canal pour les membres du diamant",
    "Agent": "Profit de commande d'agent",
    "Order": "Commander des bénéfices", //滚动信息
    // 首页充值页面
    "RechargeSu": {
      "Recharge": "Reprise",
      "Catatan": "Catatan Khusus:",
      "Next": "Suivant",
      "RechargeAm": "Montant de recharge",
      "Enter": "Entrez le montant de la recharge",
      "Quick": "Sélection rapide",
      "nameP": "Veuillez saisir votre vrai nom",
      "name": "Nom",
      "Pleaseamount": "Veuillez saisir le montant",
      "Pleaseint": "Veuillez saisir le montant",
      "Pleasename": "S'il vous plaît entrez votre nom"
    },
    // 首页子页面-Withdraw
    "WithdrawSu": {
      "Withdraw": "Retirer",
      "Withdrawal": "Montant de retrait",
      "WithdrawalRe": "Taux de retrait",
      "Input": "Montant de retrait des entrées",
      "Balance": "Équilibre",
      "WithdrawAll": "Se retirer",
      "Mobile": "numéro de téléphone portable",
      "Bank": "Numéro de carte bancaire",
      "Owned": "Banque appartenant",
      "Account": "Nom du compte",
      "Transaction": "Mot de passe de transaction",
      "Withdrawim": "Se retirer immédiatement",
      "Pleaseamount": "Veuillez saisir le montant",
      "Pleasephone": "Veuillez entrer votre numéro de téléphone",
      "Pleasecard": "Veuillez saisir votre numéro de carte bancaire",
      "Pleasebank": "Veuillez saisir votre nom de banque",
      "Pleasename": "S'il vous plaît entrez votre nom",
      "Pleasepassword": "Veuillez saisir le mot de passe de la transaction",
      "Pleaseint": "Veuillez saisir le montant",
      "PasswordError": "Mauvais mot de passe",
      "Successful": "Déjà appliqué pour le retrait",
      "Insufficient": "Solde de retrait insuffisant",
      "Unbound": "La carte bancaire n'a pas été liée",
      "Binding": "Lié votre carte bancaire",
      "idCard": "Numéro de carte d'identité"
    }
  },
  Login: {
    "indexTitle": "Se connecter",
    "remenber": "Rappelez-vous le mot de passe",
    "versions": "V.86",
    "masuk": "S'identifier",
    "name": "Téléchargement de l'application",
    "forgot": "Oublié",
    "registrasi": "Inscription",
    "loginButton": "S'identifier",
    "nameInput": "Entrez le numéro de mobile",
    "pwdInput": "Tapez votre mot de passe",
    "notLoginHint": "S'identifier",
    "goLoginText": "confirmer",
    "Forgot": {
      "title": "Récupérer mot de passe",
      "registerTitle": "Enregistrer",
      "phone": "Entrez votre numéro de téléphone",
      "code": "Entrez le code de vérification",
      "area": "Veuillez saisir le code régional",
      "sendCode": "envoyer le code",
      "password": "Mot de passe",
      "againPassword": "Confirmez le mot de passe",
      "confirm": "Soumettre",
      "goBack": "Avait un compte, connectez-vous maintenant",
      "register": "Daftar"
    },
    "Daftar": {
      "username": "Nom d'utilisateur",
      "title": "Enregistrer",
      "area": "Veuillez saisir le code régional",
      "phone": "Entrez le numéro de mobile",
      "code": "Entrez le code de vérification",
      "userName": "surnom",
      "password": "Mot de passe",
      "againPassword": "Confirmez le mot de passe",
      "payPassword": "Mot de passe de paiement",
      "referrer": "Code d'invitation (ID)"
    }
  },
  Cart: {
    title: 'Boutique de commande', // 订单商城
    explain: 'Description', // 说明
    grade: {
      SliverMember: 'Mèche\nMembre', // 白银
      GoldMember: 'Or\nMembre', // 黄金
      PlatinumMember: 'Platine\nMembre', // 白金
      DiamondMember: 'Diamant\nMembre', // 钻石
    },
    info: {
      // allMoney: 'My total\n Aset Rp', // 我的总资产Rp
      allMoney: 'Mon total\n Actifs  ', // 我的总资产Rs
      dayAllOrder: `Aujourd'hui\n commande totale`, //今天的总订单
      dayTrustMoney: `Aujourd'hui\n commission`, // 今天委托金
      yesterdayMoney: `Yesterday's\n revenu`, // 昨天的收入
    },
    text: {
      rebateAndPrincipal: 'Retour\n capital\n et\n Profit', // 返佣及本金
      "residue": "Restant",
      "one": "Commande",
      "rebate": "Bénéfice de retour",
      "buyBtn": "Acheter maintenant",
      "toGet": "Akan Mendapat",
      "orderSuccess": "Commander un succès",
      "orderGetMoney": "Paiement réussi",
      "orderWaiting": "En attendant",
      "orderOver": "Fait"
    },
    state: {
      "all": "Tous",
      "going": "Sur processe",
      "paid": "Payé",
      "frozen": "Congelé",
      "over": "Fait"
    },
    zzInfo: {
      buyIng: 'Achat', // 购买中
      buySucceedFindDT:
        "Cliquez sur la vue d'état après l'achat\n est réussi", // 购买成功点击动态查看即可
      "ok": "D'accord",
      "buyFailed": "Achat raté",
      "notLevel": "Votre niveau VIP est insuffisant",
      "buyJurisdiction1": "Si vous voulez acheter un orde d'adhésion en or",
      "buyJurisdiction2": "Si vous voulez acheter une commande d'adhésion au platine",
      "buyJurisdiction3": "Si vous voulez acheter une commande d'adhésion au diamant",
      "meet": "L'une des conditions suivantes doit être remplie",
      "invite1": "Invitez 3 amis à gagner de l'argent ensemble",
      "invite4": "Invitez 10 amis à gagner de l'argent ensemble",
      "invite3": "Invitez 30 amis à gagner de l'argent ensemble",
      "buySendMoney": "2. Dépenser Rs2000 pour acheter des adhésions Diamond",
      "invite2": "Inviter des amis",
      "buy": "Acheter maintenant",
      "myMoney": "Le solde de votre compte",
      "under": "Dessous",
      "notSendMoney": "Je ne peux pas faire de transaction",
      "goTopUp": "S'il vous plaît aller recharger",
      "know": "Je sais",
      "account": "Règlement"
    },
    "hint": {
      "p1": "1. Différents niveaux d'adhésion ont des bons de commande différents",
      "p2": "2. Plus votre niveau d'adhésion est élevé, plus le montant du bénéfice de commande peut être acheté",
      "p3": "3. Pour les commandes achetées, vous pouvez surveiller l'état de la commande à tout moment"
    }
  },
  Hint: {
    "SendCodeSuccess": "Le code de vérification est envoyé avec succès!",
    "closed": "Fermé",
    "notTime": "Pas de temps sélectionné",
    "notTimeFailed": "La sélection du temps a échoué",
    "success": "Réussi",
    "failed": "Échoué",
    "phoneNot": "Pas de HP / Non Akun Tidak Bisa Kosong",
    "pwdInconformity": "Mot de passe pas même",
    "notMoney": "Money insuffisant",
    "notEvent": "La fonctionnalité ne peut pas encore utiliser",
    "notInput": "Entrée de données incomplète",
    "loading": "Chargement",
    "notMore": "Pas plus",
    "notPhone": "Le compte n'existe pas",
    "yesPhone": "Le compte existe déjà",
    "levelNot": "Niveau",
    "serverMaintenance": "Soyez patient, le jeu est sous maintenance",
    "invite": "Invitez des amis à gagner de l'argent ensemble",
    "goodsNumNo": "Quantité insuffisante",
    "haveBuy": "Acheté",
    "depositBtnText1": "Diterima",
    "depositBtnText2": "Disponible",
    "depositBtnText3": "Recevoir",
    "commissionMoney": "Bénéfice de retour:",
    "bill": "facture",
    "billRecordTitle": "Records de facturation",
    "balance": "équilibre",
    "reference": "Code d'invitation à coller",
    "updateText": "Veuillez mettre à jour la dernière version"
  }, // 提示
  "newAdd": {
    "all": "tous",
    "Completed": "complété",
    "unfinished": "inachevé",
    "GetCodeBtn": "Obtenir le code de vérification",
    "copySuccess": "Copiez le succès!",
    "copyError": "Copiez l'échec!",
    "noLoginUserName": "Connectez-vous s'il vous plaît",
    "Withdraw": "Retirer",
    "Recharge": "Reprise",
    "commission": "Profit",
    "single": "seul",
    "missionHall": "Salle de mission",
    "videoTutorial": "Didacticiel vidéo",
    "helpCenter": "Centre d'aide",
    "inviteFriends": "Inviter des amis",
    "play": "Joue maintenant",
    "help1Title": "Comment gagner des commissions avec Mudo",
    "help1Context": "Les propriétaires d'entreprises de commerce électronique doivent augmenter les ventes du magasin, nous n'avons donc qu'à remplir les exigences du magasin de commerce électronique et à compléter la commande sur le logiciel Mudo.Le propriétaire nous donnera un profit.Chaque utilisateur peut recevoir 10 tâches de commande par jour.Vous pouvez réaliser un bénéfice de 10% à 30% en une journée.",
    "help2Title": "Comment augmenter le nombre de mission",
    "help2Context": "Parce qu'il y a trop d'employés à temps partiel, l'entreprise stipule que tous les employés à temps partiel en ligne utilisent le seul WhatsApp à contacter et à recevoir une mission.",
    "help3Title": "Comment terminer la mission",
    "help3Context": "C'est pour aider les grands commerçants à augmenter les ventes et à vous récompenser en fonction du nombre de transactions.Pour chaque commande réussie, vous gagnez des commissions et les vendeurs gagnent la réputation.",
    "referralLink": "Lien de référence:",
    "copy": "copie",
    "paste": "Pâte",
    "blance": "Équilibre",
    "todayOrder": "La commande d'aujourd'hui",
    "provided": "Les données sont fournies par",
    "Transaction": "Transaction",
    "Frozen": "Installer",
    "Done": "Fait",
    "Cancel": "Annuler",
    "ExpectedReturn": "Retour attendu:",
    "ImmediatePayment": "Paiement immédiat",
    "AvailableNum": "Il y a toujours",
    "OrderImmediately": "Commandez immédiatement",
    "MakeMoneyEveryDay": "Gagner de l'argent tous les jours",
    "TodayRecord": "Le record d'aujourd'hui",
    "AllocatCommissions": "Allocation des commissions",
    "FrozenAmount": "Solde en suspens",
    "TaskMatch": "Ordre des tâches au hasard",
    "AvailableBalance": "Solde disponible",
    "WaitDispatch": "En attente de Dispatch, veuillez contacter le directeur du magasin!",
    "OrderDescription": "Description de la commande",
    "CouldMatch": "Les tâches sont appariées au hasard par le système",
    "OrderPerDay": "seul.",
    "DescriptionRate": "Le bénéfice de placement des commandes est uniformément",
    "DescriptionPeriod": "du montant de la commande.",
    "Description3": "Le système est basé sur la technologie LBS et correspond automatiquement aux produits via le cloud.",
    "Description4": "Afin d'éviter la supervision de la plate-forme, si la commande n'est pas confirmée et soumise dans les 20 minutes, le système gèlera le montant de la commande.",
    "Note": "Note:",
    "DescriptionNote": "Veuillez lire attentivement le contenu pertinent: après que le système de blockchain P2P correspond à la commande de l'utilisateur, la plate-forme Remarque: la plate-forme soumettra les informations de commande à l'arrière-plan du commerçant.Si l'utilisateur ne soumet pas de commande dans les 60 minutes, le montant de la commande sera gelé par le système.Afin d'éviter le système de supervision, veuillez accepter après confirmation, toutes les tâches ne peuvent pas être annulées ou remplacées après avoir reçu!S'il y a d'autres problèmes qui doivent être traités, veuillez cliquer sur Arrêter la commande à temps pour éviter la congélation des heures supplémentaires, merci pour votre soutien",
    "OrderNum": "Numéro de commande:",
    "TotalOrderPrice": "Prix total de la commande",
    "SubmitNow": "Soumettre maintenant",
    "Record": "Enregistrer",
    "MemberAccount": "Compte membre",
    "AccountPlaceholder": "Veuillez saisir votre compte membre",
    "RechargeAmount": "Montant de recharge",
    "RechargeAmountPlaceholder": "Veuillez saisir la quantité de recharge",
    "SelectRechargeMethod": "Sélectionnez la méthode de recharge",
    "NotBeenSentOrder": "La commande n'a pas été envoyée, veuillez contacter le personnel pour envoyer la commande!",
    "TodayOrderOver": "Contacter le service client en ligne",
    "NotLogin": "Vous n'êtes pas connecté, veuillez vous connecter et réessayer!",
    "HaveNoteMethod": "Ouverture limitée, veuillez contacter l'équipe de vente!",
    "Login": "Connexion",
    "AccountRecord": "Revenu et dépenses",
    "RealName": "Nom du compte",
    "IFSCCODE": "Code IFSC",
    "BankName": "Nom de banque",
    "ChainType": "Type de chaîne",
    "BankNum": "Numéro de mobile （ID GCash）",
    "Mobile": "Mobile",
    "MobileNum": "Numéro de portable",
    "Email": "E-mail",
    "EmailRequired": "Un e-mail est requis",
    "AddBankCardNote": "Les informations de retrait doivent être remplies correctement",
    "AddWalletNote": "Remarque: L'adresse du portefeuille est des informations importantes, veuillez les remplir attentivement!",
    "Submit": "Soumettre",
    "AddBankCardTitle": "Ajouter GCASH",
    "AddWalletTitle": "Ajouter l'adresse du portefeuille",
    "BankCard": "carte bancaire",
    "WalletInfo": "Informations sur le portefeuille",
    "Name": "nom",
    "CardNum": "Numéro de carte (ID AC)",
    "WalletAddress": "Adresse",
    "Reading": "est en train de lire ...",
    "AllBalance": "équilibre global",
    "EnterWithdrawalAmount": "Veuillez saisir le montant du retrait",
    "AllOut": "Tous dehors",
    "AskNow": "Confirmer",
    "EnterPwdPlaceholder": "Veuillez saisir un mot de passe",
    "NotHaveCard": "Vous n'avez pas configuré les informations de retrait, voulez-vous aller dans les paramètres?",
    "NotHaveWallet": "Vous n'avez pas configuré votre adresse de portefeuille, voulez-vous accéder au paramètre?",
    "GetBankInfoFail": "Échec de la récupération des informations bancaires",
    "EnterValidPayPwd": "Veuillez saisir un mot de passe de paiement valide!",
    "Balance": "Équilibre",
    "Total": "Total",
    "total": "total",
    "records": "enregistrements",
    "DisplayPerPage": "Chaque page s'affiche",
    "NullNum": "",
    "pages": "pages.",
    "toAudit": "auditer",
    "succeed": "Réussi",
    "RechargeRecordTitle": "Record de reprise",
    "rests": "repos",
    "Arrive": "Arriver",
    "Income": "Revenu",
    "Expenditure": "Dépense",
    "forzenCommission": "bénéfice non réclamé",
    "companyIntro": "Profil de l'entreprise",
    "companyTitle": "Tata Mall fait partie du groupe Tata",
    "warmTitle": "Avertissement",
    "companyText": "Tata Group est le plus grand groupe d'entreprises de l'Inde, fondée en 1868 et dont le siège est à Mumbai, en Inde.Les opérations commerciales impliquent sept domaines d'activité: les communications et les technologies de l'information, l'ingénierie, les matériaux, les services, l'énergie, les produits de consommation et les produits chimiques.En tant que groupe d'entreprises en croissance rapide en Inde, le groupe Tata compte plus de 200 filiales, avec des opérations dans plus de 80 pays sur 6 continents et des exportations de produits et services à 85 pays.Le chiffre d'affaires total du groupe Tata pour l'exercice 2020-2021 est de 100,09 milliards de dollars américains, dont 58% proviennent des opérations à l'étranger.Le nombre d'employés du groupe à travers le monde est d'environ 450 000.Depuis 153 ans, le nom Tata en Inde a été largement respecté pour son adhésion aux bonnes valeurs et à l'éthique des affaires.Classé comme la 11e entreprise la plus respectée au monde par Forbes.",
    "depositAddress": "Adresse de dépôt",
    "rechargeSteps": "Étapes de reprise",
    "completePayment": "Paiement complet",
    "rechargeNow": "Gérer maintenant",
    "rechargeCancel": "Annuler le paiement",
    "bankName": "Nom de banque",
    "payee": "Le bénéficiaire",
    "cardNum": "Numéro de carte bancaire",
    "reviewing": "À l'étude",
    "remittance": "Réussi",
    "notThrough": "Échoué",
    "OrderID": "Numéro de commande",
    "Amount": "montant",
    "toCutScreen": "Veuillez prendre une capture d'écran si l'image n'est pas disponible!",
    "cardTabBank": "Banque",
    "cardTabUSDT": "USDT",
    "name": "Nom",
    "chainType": "Type de chaîne",
    "newNavTitle": "Soumettre la commande",
    "shopMoney": "Quantité de marchandises",
    "actualMoney": "Soumission réelle",
    "commitBtnText": "Générer des commandes",
    "commitHint": "Entrée incomplète"
  },
  // 我的
  "mine": {
    "servier": "Serveur",
    "integral": "Intégral",
    "balance": "Racheter",
    "record": "Enregistrer",
    "wtdAmt": "Échanger des points",
    "avaBlc": "Quantité d'échange",
    "allWtd": "Tous",
    "close": "Annuler",
    "commit": "Confirmer",
    "wtdHint": "Veuillez saisir le montant de retrait correct"
  },
  "add": {
    "Commission": "Montant instable aujourd'hui",
    "Yesterday": "Hier",
    "Cumulative": "Cumulatif",
    "Today": "Aujourd'hui",
    "TeamNum": " Taille totale de l'équipe",
    "TeamMoney": " Profit total de l'équipe",
    "Personalcenteraccountchangerecord": "Enregistrement du changement de compte",
    "lockedbalance": "encours",
    "Cumulativeinvitation": "Invitation cumulée",
    "Validinvitation": "Invitation valide",
    "Accumulatedrebate": "Remise accumulée"
  },
  "home.btn.wallet": "Fonds",
  "home.btn.recharge": "Soumettre",
  "home.btn.withdraw": "Retirer",
  "home.btn.invite": "Inviter",
  "home.btn.message": "Message",
  "page.grab": "Saisir",
  "orderCommission": "Commander des bénéfices",
  "commission": "Profit",
  "todayIncome": "Aujourd'hui revenu",
  "personalEarnings": "Revenus personnels",
  "completedOrders": "Commandes terminées",
  "lockedOrders": "Commandes verrouillées",
  "unfinishOrder": "Ordres inachevés",
  "frozonAmount": "Fonds instables",
  "currentBalance": "Solde actuel",
  "page.mine": "Détails du compte",
  "mine.invitationCode": "Code d'invitation",
  "mine.serverCenter": "Centre de serveur",
  "mine.tools.total": "Total",
  "mine.tools.financial": "Fonds",
  "mine.tools.details": "Détails",
  "mine.tools.freeze": "Équilibre",
  "mine.serviceTools.personal": "Personnel",
  "mine.serviceTools.teamReport": "Rapport de l'équipe",
  "mine.serviceTools.invite": "Compte familial",
  "mine.serviceTools.message": "Message",
  "mine.serviceTools.aboutUs": "À propos de nous",
  "mine.serviceTools.security": "Sécurité",
  "mine.serviceTools.address": "Adresse",
  "mine.serviceTools.inviteTask": "Inviter la tâche",
  "page.team": "Équipe",
  "page.aboutus": "À propos de nous",
  "page.financial": "Fonds",
  "page.financialRecord": "Dossier financier",
  "hint.InternetErr": "Erreur de réseau, veuillez réessayer plus tard!",
  "totalDeposit": "Dépôt total",
  "totalRevenue": "Revenu total",
  "btn.transferInto": "Transférer dans",
  "btn.transferOut": "Transférer",
  "page.transferInto": "Transférer dans",
  "page.transferOut": "Transférer",
  "balanceTransfer": "Transfert de solde",
  "availableBalance": "Solde disponible",
  "depositRate": "Taux de dépôt",
  "days": "jours",
  "hint.success": "Succès",
  "hint.fail": "Échoué",
  "status.into": "Transférer dans",
  "status.out": "Transférer",
  "label.amount": "Montant",
  "label.time": "Temps",
  "Button": {
    "cancle": "Annuler",
    "confirm": "Confirmer"
  },
  "dialog.title.inputPaypass": "Entrez le mot de passe de paiement",
  "dialog.nopaypass": "Le mot de passe de paiement n'a pas été défini, cliquez sur OK pour accéder au paramètre!",
  "mine.serviceTools.lan": "Langue",
  "checkLans": "Commutation de la langue",
  "mine.tools.commission": "Profit",
  "page.commissionRecord": "Profit",
  "noticeList.all": "Tous",
  "noticeList.unread": "Non lu",
  "successWithdrawof": "retrait réussi de",
  "home.title.notice": "Avis",
  "home.title.patener": "En partenariat avec",
  "login.rememberPassword": "Rappelez-vous le mot de passe",
  "page": {
    "cart": "Chariot",
    "order": "Commande",
    "financing": "Financement",
    "goods": "Marchandises",
    "levelUpgrade": "Mise à niveau de niveau",
    "PlatformIntroduction": "Introduction de la plate-forme",
    "RuleDescription": "Description de la règle",
    "AgentCooperation": "Coopération des agents",
    "CommonProblem": "Problème commun",
    "MemberUpgrade": "Mise à niveau des membres",
    "login": "Connexion",
    "forgot": "Oublié",
    "registrasi": "Registrasi",
    "record": "Enregistrer",
    "Recharge": "Soumettre",
    "inviteFriends": "Inviter des amis",
    "Withdraw": "Retirer",
    "Service": "Service",
    "DialoguePage": "Page de dialogue",
    "user": "Personnel",
    "RansferIn": "Ransférine",
    "PersonalCenter": "Centre personnel",
    "Portrait": "Portrait",
    "Name": "Nom",
    "Account": "Compte",
    "Password": "Mot de passe",
    "PaymentCode": "Code de paiement",
    "Site": "Placer",
    "AccountData": "Accountdata",
    "RechargeRecord": "Record de reprise",
    "WithdrawalRecord": "Dossier de retrait",
    "InviteFriends": "Inviter des amis",
    "Announcement": "Annonce",
    "TheTeamReport": "Le rapport de l'équipe",
    "BillRecord": "Record de facture",
    "BankCardAdd": "Ajouter une carte bancaire",
    "NewRecharge": "Reprise",
    "RechargeList": "Liste de remontée",
    "newWithdraw": "Retirer",
    "withdrawList": "retrait de la liste",
    "SettingPayPwd": "Définir PayPass",
    "MakeMoney": "Faire de l'argent",
    "team": "équipe",
    "qrPay": "QRCODE-PAY",
    "bankPay": "bancaire",
    "aboutus": "À propos de nous",
    "financial": "Fonds",
    "financialRecord": "Dossier financier",
    "TransferInto": "Transférer dans",
    "TransferOut": "Transférer",
    "CommissionRecord": "Profit",
    "Security": "Sécurité",
    "Address": "Adresse",
    "Message": "Message",
    "InviteTask": "Invitetask",
    "Notice": "Avis",
    "modifyPaymentPassword": "Modifier le mot de passe de paiement"
  },
  copyLink: "Copier le lien",
  "teamReport": {
    "title": "Rapport de l'équipe",
    "subTitle": "Aperçu de l'équipe subordonnée",
    "taskTitle": "Invitez {num} les gens à s'inscrire et à terminer la commande",
    "bonus": "Prime",
    "Unfinished": "Inachevé",
    "Getted": "Gêné"
  },
  copy: "Copie",
  "address": {
    "name": "Nom",
    "inputPlaceholder": "Veuillez remplir",
    "phone": "Téléphone",
    "areaPlaceholer": "Adresse de livraison Informatique",
    "submit": "Sauvegarder"
  },
  "password.old": "ancien mot de passe",
  "password.old.placeholder": "Entrez votre ancien mot de passe",
  "password.confirmPassword": "Confirmez le mot de passe",
  "password.confirmPassword.placeholder": "Entrez à nouveau le nouveau mot de passe",
  "hint.passwordDiff": "les deux mots de passe ne correspondent pas",
  "loginType": {
    "phone": "Téléphone",
    "email": "E-mail"
  },
  "Login.Daftar.email": "E-mail",
  "Login.emailInput": "Entrer votre Email",
  "My.PersonalInformation.Password.email": "e-mail",
  "My.PersonalInformation.Password.NewEmail": "Veuillez saisir votre e-mail cellulaire",
  "mine.serviceTools.certify": "Vérification",
  "Certification": {
    "type": {
      "id": "Numéro d'identification",
      "driver": "Le permis de conduire",
      "passport": "Passeport"
    },
    "name": "Nom",
    "license": "Numéro de licence",
    "upload": "Pièce jointe",
    "submit": "Soumettre",
    "incompleteInformation": "Veuillez remplir les informations et réessayer!",
    "submitSuccess": "Les informations de certification ont été soumises, veuillez attendre l'examen!",
    "photo": "Photo d'identité",
    "review": "à l'étude",
    "verified": "vérifié"
  },
  "upload": {
    "uploading": "Téléchargement...",
    "failed": "le téléchargement a échoué"
  },
  "login.forgetPassword": "Mot de passe oublié, veuillez contacter le service client!",
  "newAdd.BankNum.placeholder": "Exemple: 09xxxxxxxxx",
  "page.certification": "VÉRIFICATION D'IDENTITÉ",
  "newAdd.page": "page",
  "newAdd.record": "enregistrer",
  "emailFormatError": "Veuillez saisir l'adresse e-mail correcte!",
  "urlParsePlaceholder": "Veuillez saisir l'identification de la pâte",
  "btn.Identify": "Identifier immédiatement",
  "produceStatus.confirming": "Être confirmé",
  "produceStatus.paying": "Être payé",
  "produceStatus.paid": "Payé",
  "produceStatus.done": "Fait",
  "produceStatus.cancle": "Remboursé",
  "order.old": "Ordonnance de revenu élevé",
  "order.new": "Commande régulière",
  "welfareDialogTitle": "Conseils de prestations",
  "viGrabDesces": {
    "1": "Chaque commande peut être sélectionnée comme ordonnance chanceuse et la Commission sera augmentée à {taux}%.Veuillez contacter votre mentor de sensibilisation pour demander d'autres affectations de sensibilisation",
    "2": "Aide aux vendeurs des acheteurs à compléter la création de commandes virtuelles, à augmenter la visibilité et à gagner des commissions",
    "3": "Veuillez contacter votre tuteur de promotion dès que possible après avoir reçu la commande, soumettre les données de commande et terminer la tâche",
    "4": "Le nombre de tâches est limité, veuillez prêter attention aux suggestions de promotion de l'instructeur lors de l'acceptation des commandes",
    "5": "Le montant du remboursement sera émis dans les 5-10 minutes après avoir terminé la tâche.Si vous avez des questions, veuillez consulter un service client expert ou en ligne.",
    "6": "Effectuez les tâches assignées par Shopee, améliorez le niveau VIP et obtenez des commissions plus élevées pour les tâches FackBook",
    "7": "Terminé des acheteurs désignés avec accumulation pour obtenir des commissions supplémentaires.Pour des récompenses spécifiques, veuillez consulter le spécialiste de la promotion ou le service client en ligne"
  },
  "expectedEarningsTitle": "Bénéfices attendus",
  "rechargeDesc": {
    "nav": "Description de recharge",
    "p1": "Nous avons maintenant beaucoup d'employés à accomplir des tâches en même temps.",
    "p2": "Un vendeur ne peut pas fournir suffisamment de comptes",
    "p3": "Nous publions donc notre compte vendeur dans un autre blanc",
    "p4": "Lorsque vous devez terminer la tâche, vous pouvez contacter l'assistance des ventes pour enregistrer votre canal de paiement"
  },
  chat: {
    "list": {
      "nav": "Message",
      "hours": "{h} heures",
      "minutes": "{M} Minutes",
      "ago": "{time} il y a",
      "image": "image"
    },
    "room": {
      "msgPlaceholder": "Entrez un message ...",
      "send": "Envoyer",
      "sender": "Expéditeur",
      "sendTime": "Envoyer un temps",
      "content": "Contenu"
    }
  },
  "Login.Daftar.selectCurrency": "Sélectionnez la devise",
  "mine.totalBalance": "Solde total",
  newBank: {
    "usdt": {
      "name": "Nom",
      "currency": "Monnaie",
      "chainType": "Type de chaîne",
      "depositAddress": "Adresse de dépôt",
      "submit": "soumettre",
      "bank": "Banque",
      "wallet": "Portefeuille",
      "bankName": "Nom de la banque",
      "bankCard": "Carte bancaire"
    },
    "walletList": {
      "pageTitle": "Gestion du portefeuille",
      "name": "Alias du portefeuille:",
      "coinType": "Monnaie:",
      "chainType": "Type de chaîne:",
      "walletAddress": "Adresse:",
      "addDate": "date:",
      "btnText": "Ajouter l'adresse du portefeuille",
      "deleteConfirmText": "Êtes-vous sûr de vouloir supprimer l'adresse du portefeuille?",
      "btnConfirm": "Supprimer",
      "btnCancle": "Cancle",
      "successHint": "réussi"
    },
    "withdrawNum": "Nombre de retraits",
    "AllOut": "Tous",
    "balance": "Équilibre global",
    "handlingFee": "Frais de gestion",
    "actualAmount": "Montant actuel"
  },
  "hashPlaceholder": "Veuillez saisir le hachage",
  "recharge.status.error": "Amazon Virtual Order Creation a échoué, veuillez soumettre la valeur de hachage correcte et le montant de l'élément",
  "recharge.actualAmount": "Compte réel",
  "login.userAgreement": "Accord de l'utilisateur",
  "login.title1": "Chasser",
  "login.title2": "Content de te revoir!",
  "registe.title1": "Chanter",
  "registe.title2": "Content de te revoir!",
  "Login.Daftar.selectCurrency": "Choisissez la monnaie",
  "mine.totalBalance": "Solde total",
  "login.forgetPassword": "Vous avez oublié votre mot de passe, veuillez contacter le service client!",
  "Home.HallofFame": "Temple de la renommée",
  "btn.Identify": "Apprenez immédiatement",
  "My.service": "service",
  "mine.credit": "Crédit",
  "page.checkin": "Enregistrement",
  "checkin.submit": "Enregistrement",
  "checkin.success": "Connectez-vous avec succès",
  "checkin.fail": "Échec de la connexion",
  "todayChecked": "Vous êtes connecté, merci de ne pas répéter l'opération !",
  "submit.checked": "connecté",
  "hint.inviteBanned": "Désolé, votre compte n'a pas encore ouvert de compte familial, veuillez contacter le service client ou votre mentor pour faire une demande d'ouverture !",
  "bannerText1": "{Name} Tank complète {Money} pour obtenir {Money1} Commission",
  "bannerText2": "Félicitations {nom} Sélectionnez la tâche premium pour obtenir une commission de 30%",
  "grap": {
    "btask": "Tâches bonus",
    "wtask": "Tâches de travail"
  },
  "new_goods": {
    "custdown": "Compte à rebours",
    "hours": "HEURES",
    "minutes": "MINUTES",
    "seconds": "Secondes",
    "descBtn": "Description de la tâche",
    "taskBtn": "Finissez la tâche"
  },
  "gift": {
    "gift_shop": "Boutique de souvenirs",
    "my_gift": "Mon cadeau",
    "away": "Révéler",
    "buy": "Achat",
    "overlay": {
      "inputPlaceholder": "Entrez le numéro de chambre",
      "num": "Nombre de cadeaux",
      "room": "Offrir un numéro de chambre",
      "no_data": "Pas de cadeau"
    },
    "detailTitle": "Dossier cadeau",
    "num": "quantité",
    "room": "Numéro de chambre"
  },
  "p2p": {
    "trade_method": {
      "buy": "Acheter",
      "sell": "Vendre"
    },
    "coinList": {
      "orders": "{__} commande(s)",
      "label": {
        "amount": "Montant",
        "price": "Prix",
        "limit": "Limite"
      }
    },
    "tradeDetails": {
      "trade_title_sell": "Combien de {__} souhaitez-vous vendre ?",
      "trade_title_buy": "Combien de {__} souhaitez-vous acheter ?",
      "submit_now": "Envoyer {__}"
    },
    "orderDetails": {
      "sellTitle": "En attente du paiement de l'autre partie",
      "buyTitle": "En attente de votre paiement",
      "sellHint": "L'autre partie doit effectuer le paiement dans les {__}, sinon la commande sera automatiquement annulée.",
      "buyHint": "Effectuez le paiement dans les {__}. Après avoir effectué le paiement, cliquez sur contacter le vendeur",
      "payInfo_title": "Informations de paiement",
      "label": {
        "totalAmount": "Montant total",
        "pricePerUnit": "Prix unitaire",
        "quantity": "Quantité",
        "counterpaty": "Contrepartie",
        "payMethod": "Méthode de paiement",
        "fullName": "Nom complet",
        "email": "Email",
        "phone": "Numéro de téléphone"
      },
      "btn_service": "Contacter le vendeur",
      "btn_sell": "Contacter l'acheteur"
    },
    "limitHint": {
      "notEnough": "Échec de la commande, le vendeur n'a pas assez de USD",
      "less": "Échec de la commande, limite de transaction minimale {__}",
      "more": "Échec de la commande, limite de transaction maximale {__}"
    }
  },
  "paypassDialog": "Vous n'avez pas encore défini de mot de passe de paiement. Allez le définir."
}
export default {
  // 公共的key
  common: {
    unrealized: 'ยังไม่สมบูรณ์',
    all: 'ทั้งหมด',
    distributionTip: '0 ดอลลาร์สำหรับค่าส่ง 0',
    easeBack: 'ไม่ต้องห่วง',
    addCarSuccess: 'ประสบความสำเร็จในการเพิ่มรถเข็น',
    deleteConfirm: 'คุณแน่ใจ หรือว่า ต้องการลบรายการ ที่เลือกไว้?'
  },
  //商品详情
  goodsDetail: {
    goodsDetail: 'รายละเอียดสินค้า',
    shopping: 'กำลังเข้าไป',
    end: 'สิ้นสุดระยะทาง',
    sold: 'ขายแล้ว',
    goodsTips: 'รายการนี้ มีมูลค่า 500 กรัม/รายการ ที่มีมูลค่าต่ำกว่า 500 กรัม และราคา ที่แตกต่างกัน',
    quickTime: 'เร็ว ที่สุด ที่จะไปถึงใน 29 นาที',
    specifications: 'รายละเอียด',
    preservationConditions: 'บันทึกเงื่อนไข',
    coldStorage: 'แช่เย็น',
    shelfLife: 'วันหมดอายุ',
    day: '15 วัน',
    addToCar: 'เข้าร่วมซื้อรถเข็น'
  },
  //购物车
  car: {
    title: 'รถเข็น',
    delete: 'ลบ',
    tip: 'รถเข็น ที่ว่า งเปล่า~',
    walk: 'ไปปาร์ตี้กัน',
    all: 'การเลือก',
    guess: 'เดาว่า คุณคงชอบ'
  },
  // 首页
  home: {
    HallofFame: 'หอเกียรติยศ',
    chooseLocation: 'โปรดเลือกตำแหน่ง..',
    more: 'เพิ่มเติม',
    home: 'หน้าแรก',
    category: 'การเรียงลำดับ',
    eat: 'กินอะไร?',
    cart: 'รถเข็น',
    mine: 'ของฉัน',
    inputGoodsName: 'ป้อนชื่อรายการ',
    vipDesc: 'การเข้าร่วมสมาชิกคาดว่า จะประหยัดประมาณ 806 ดอลลาร์ต่อปี',
    vipSubDesc: 'ลด 50 %>',
    specialZone: 'พื้น ที่พิเศษ',
    foodie: 'วันกินของ',
    dinner: 'อาหารค่ำ',
    popularity: 'คะแนนนิยม',
    goodChoose: 'การเลือก',
    limitBuy: 'ถูก จำกัด',
    bottomTip: 'ฉันมีขีด จำกัด',
    chooseAddress: 'โปรดเลือก ที่อยู่',
    currentLocation: 'ตำแหน่งปัจจุบันของคุณ',
    nearbyAddress: 'ที่อยู่ใกล้ ๆ'
  },
  // 分类
  category: {
    tip: 'ป้อนชื่อรายการ',
    bottomTip: 'เอาล่ะมาดูประเภทอื่นกัน'
  },
  // 吃什么
  eat: {
    all: 'ทั้งหมด',
    close: 'ลาก่อน',
    pleaseHolder: 'ค้นหาสูตรอาหารส่วนผสม',
    menu: 'เมนูวันนี้',
    menuDevide: 'การเรียงลำดับเมนู',
    recent: 'เพิ่งหามา',
    tip_bottom: 'มันจบแล้วหยุดดึงได้แล้ว!',
    tip: 'ยังไม่มีการจัดเก็บคอลเลกชัน~'
  },
  // 我的
  mine: {
    title: 'ของฉัน',
    phone: 'หมายเลขโทรศัพท์',
    login: 'ล็อกอินทันที',
    myOrder: 'คำสั่งของฉัน',
    allOrder: 'ดูรายการ ที่สั่งทั้งหมด',
    myBill: 'คูปองของฉัน',
    myLocation: 'ที่อยู่การรับของฉัน',
    myCar: 'กรีนการ์ดของฉัน',
    servier: 'ติดต่อฝ่ายบริการลูกค้า',
    servierTime: 'เวลาการบริการลูกค้า 07.00-22 : 00 น.',
    feedback: 'ความคิดเห็น',
    switchLanguage: 'ภาษา',
    chinese: 'ภาษาจีน',
    english: 'ภาษาอังกฤษ',
    version: 'รุ่นปัจจุบัน',
    waitingPay: 'ค่าใช้จ่ายในภายหลัง',
    waitingReceive: 'กำลังรับของ',
    waitingComment: 'ที่จะให้คะแนน',
    waitingFeedback: 'หลังจากการขาย/คืนเงิน',
    unrealized: 'หน้าคืนเงินยังไม่เปิดเลย!',
    tip2: 'อย่าลืมสั่งดาวดวงเล็ก ๆ นะคะ❤️~',
    tip3: '💘ขอบคุณสำหรับความสนใจ💘',
    tip4: 'ค้นหาในคลับ \nGeek-James/ddBuy \n🦉ยินดีต้อนรับสู่ข้อเสนอการปรับ แต่ง🙉',
    feedbackTip: 'ตอนนี้ ยังไม่มีการสั่งซื้อ',
    cardPrise: 'ราคากรีนการ์ด',
    moreMessage: 'ยินดี ที่ได้รู้จักค่ะ...',
    openCard: 'เปิดกรีนการ์ด',
    recomend: 'กรีนการ์ดทั้งหมดอยู่ ที่ 6.66 ดอลลาร์สหรัฐ',
    cardTip1: 'บัตรประจำตัว',
    cardTip2: 'พิเศษเฉพาะ',
    cardTip3: 'อินทิกรัลสองเท่า',
    cardTip4: 'สิทธิพิเศษ',
    vipType: 'ลด 50 %',
    vipTime: 'ปีค.ศ. 365 วัน',
    vipRecommend: 'เท่ากับ 0.24 ดอลลาร์ต่อวัน',
    prise1: '¥88',
    prise2: '¥188',
    vipType1: 'ลด 70 %',
    vipTime1: 'การ์ดซีซั่น 90 วัน',
    vipRecommend1: 'ประมาณ 0.33 ดอลลาร์ต่อวัน',
    prise11: '¥30',
    prise12: '¥45',
    payMethod: 'วิธีการจ่ายเงิน',
    wechatPay: 'การชำระเงินผ่านทางจดหมาย',
    aliPay: 'และเงิน ที่จ่ายไป',
    huabeiPay: 'ก็จ่ายไปสิ',
    payImmeatally: 'จ่ายทันที',
    cardUeless: 'กรีนการ์ดยังไม่เปิด',
    tip: 'นั่นคือ ทั้งหมด ที่! ข้าทำไม่ได้หรอก~~',
    changeNickName: 'แก้ไขชื่อเล่น',
    confirm: 'บันทึก',
    nickName: 'โปรดตั้งชื่อเล่นใหม่',
    personal: 'แก้ไขข้อมูลส่วนบุคคลสำเร็จ!',
    message: 'ชื่อเล่นครับ',
    myBills: 'คูปองของฉัน',
    persent: 'กรุณากดเลข ที่ 520...',
    bill: 'คูปอง',
    condition: 'ไม่มีระดับการใช้งาน\nส่วนลดพิเศษ 1.5 ดอลลาร์',
    rmb: 'หยวน',
    myOrders: 'คำสั่งของฉัน',
    itemsTitle: ['ทั้งหมด', 'ค่าใช้จ่ายในภายหลัง', 'กำลังรับของ', 'ที่จะให้คะแนน'],

    personalInfo: 'ข้อมูลส่วนบุคคล',
    head: 'หัว',
    user_name: 'ชื่อเล่น',
    userSex: 'เพศ',
    Brithday: 'วันเกิด',
    phoneNumber: 'หมายเลขโทรศัพท์',
    noInput: 'ไม่ต้องกรอก',
    logout: 'ออกจากการล็อกอิน',
    infoTip: 'แก้ไขข้อมูลส่วนบุคคลสำเร็จ',
    loginInfo: 'แน่ใจนะว่า จะออกจากระบบ?',
    infoSuccess: 'ถอนตัว!',
    year: 'ปี',
    month: 'เดือน',
    day: 'วัน',
    man: 'รูปหล่อ',
    woman: 'คนสวย',

    greenCard: 'กรีนการ์ด',
    greenCardPower: 'สิทธิ์ของกรีนการ์ด',
    fiftypecert: 'ลด 50 % สำหรับกรีนการ์ด',
    cardRecoamnd: 'กรีนการ์ดจะได้คูปองส่วนลดทุกวัน',
    TodayExclusivecoupon: 'วันนี้ เอาไปให้หมดเลย',
    everyUpadate: 'ปรับปรุงทุกวันตอน 0 โมง',
    to: 'เติมเต็ม',
    toUse: 'ตั้งค่าโครงการ',
    immeatallyGet: 'รับทันที',
    weekBill: 'อาทิตย์นี้ จะได้คะแนน',
    cardTip7: 'รับทันที',
    billTip: 'กรีนการ์ดจะได้คูปองส่วนลดทุกวัน',
    currentGoods: 'การช้อปปิ้งปัจจุบันของคุณ',
    cardShop: 'เปิดกรีนการ์ดช้อปปิ้ง',
    cardBack: 'อินทิกรัลย้อนกลับเป็น',
    cardSpecialTip: 'เริ่มอินทิกรัลได้ในทันที',
    cardSpecial: 'กรีนการ์ดพิเศษพิเศษ',
    cardPriseb: '180 ดอลลาร์',
    cardPrisea: '88 ดอลลาร์',
    yearCard: 'การ์ดปี',
    openCards: 'เปิดกรีนการ์ด',
    onece: 'หนึ่งครั้ง',
    twice: 'สองเท่า'
  },
  // 订单
  order: {
    outTimeGetMoney: 'ได้รับการชดเชยมากกว่า 10 นาที',
    inputForm: 'เติมคำสั่ง',
    location: 'เลือก ที่อยู่ ที่จะรับ',
    arrivalTime: 'ถึงเวลา',
    total: 'เอาเลย',
    thing: 'บางอย่าง',
    wechatPay: 'การชำระเงินผ่านทางจดหมาย',
    aliPay: 'และเงิน ที่จ่ายไป',
    huabeiPay: 'ก็จ่ายไปสิ',
    use: 'ใช้',
    order12: 'อินทิกรัล',
    mark: 'หมายเหตุ',
    tip: 'การกรอกข้อมูลจะบอกเราได้ว่า คุณต้องการอะไรเป็นพิเศษ',
    goodsList: 'รายการสินค้า',

    totalMoney: 'จำนวนรายการ',

    sendMoney: 'ค่าจัดส่ง',
    point: 'อินทิกรัล',
    order19: 'จ่ายจริง',

    pay: 'จ่ายจริง',
    sendForm: 'ส่งคำสั่ง',

    selectArrivalTime: 'เลือกเวลาส่งมอบ',
    comfirm: 'แน่ใจ',
    goods: 'รายการ',
    all: 'เอาเลย',
    things: 'บางอย่าง',
    sigalPrice: 'ราคาเท่านั้น:',
    numbers: 'จำนวน:',
    addTip: 'ยังไม่มี ที่อยู่ให้เพิ่มเลยค่ะ😄',

    addLocation: 'เพิ่ม ที่อยู่',
    comfirmAndUse: 'บันทึก และใช้งาน',
    editLocation: 'แก้ไข ที่อยู่',
    myAddress: 'ที่อยู่ของฉัน',
    settlement: 'ตกลง',
    chooseGoods: 'กรุณาเลือกรายการ ที่ต้องการคำนวณ',
    deliveryTime: 'โปรดเลือกเวลาสำหรับการส่งมอบ',
    today: 'วันนี้',
    tomorrow: 'พรุ่งนี้'
  },
  Login: {
    indexTitle: 'ล็อกอิน',
    Daftar: {
      title: 'ล็อกอิน',
      phone: 'กรุณาใส่เบอร์มือถือของคุณ',
      code: 'โปรดป้อนรหัสการตรวจสอบ',
      password: 'โปรดป้อนรหัสผ่าน',
      againPassword: 'โปรดป้อนรหัสผ่านของคุณอีกครั้ง',
      remenber: 'จดจำรหัสผ่าน'
    },
    Forgot: {
      sendCode: 'ส่งแล้ว',
      confirm: 'ส่งข้อมูล',
      goBack: 'กลับ'
    },
    nameInput: 'กรุณาใส่เบอร์มือถือของคุณ',
    pwdInput: 'โปรดป้อนรหัสผ่าน',
    remenber: 'จดจำรหัสผ่าน',
    loginButton: 'ล็อกอิน',
    registrasi: 'ลงทะเบียน'
  },
  // 登录
  login: {
    title: 'ล็อกอิน',
    phoneNumber: 'หมายเลขโทรศัพท์',
    phoneNumberNotCorrect: 'รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง',
    phoneNumberNotEmpty: 'หมายเลขโทรศัพท์ไม่ว่า ง',
    passNumberNotEmpty: 'รหัสผ่านไม่สามารถว่า งได้',
    phoneInput: 'กรุณาพิมพ์หมายเลขโทรศัพท์ของคุณ',
    pass: 'รหัสผ่าน',
    passTip: 'โปรดป้อนรหัสผ่าน',
    varify: 'โปรดป้อนรหัสการตรวจสอบ',
    otherMethods: 'วิธีการล็อกอินอื่น ๆ',
    wechat: 'เข้าสู่ระบบอ่านจดหมาย',
    qqchant: 'คิวคิวเข้าสู่ระบบ',
    tipTile: 'คำแนะนำ ที่ดี：',
    tipContent: 'หมายเลขโทรศัพท์ ที่ไม่ได้ลงทะเบียนจะลงทะเบียนอัตโนมัติเมื่อเข้าสู่ระบบด้วยตัวแทนยินยอม',
    tip: 'โปรโตคอลผู้ใช้',
    tipProcy: 'กลยุทธ์ความเป็นส่วนตัว',
    login: 'ล็อกอิน',
    resgin: 'ลงทะเบียน',
    passTip2: 'โปรดป้อนรหัสผ่าน (ไม่น้อยกว่า 6 บิต)',
    sendVerify: 'ส่งรหัสการตรวจสอบ',
    hasSend: 'ส่งแล้ว',
    phoneVerify: 'หมายเลขบัญชีเข้าสู่ระบบ',
    smsMessage: 'เข้ารหัสการตรวจสอบข้อความ',
    switchLoginMsg: 'หมายเลขบัญชีเข้าสู่ระบบ',
    messageSuccess: 'ป้อนรหัสผ่านสำเร็จโปรดป้อนในกล่องใส่ข้อมูล',
    pleaseInputCorrectPhoneNumber: 'กรุณาใส่เลขโทรศัพท์ ที่ถูกต้อง',
    pleaseInputCorrectVerifyumber: 'โปรดป้อนรหัสการตรวจสอบ ที่ถูกต้อง',
    otherWechat: 'ล็อคอินเข้าสู่ระบบจดหมาย - ยังไม่เสร็จสมบูรณ์',
    otherQQ: 'คิว ที่ล็อกอิน - ยังไม่เสร็จสมบูรณ์',
    message: 'ตรวจสอบรหัสผ่าน:'
  },
  Hint: {
    notMore: 'ไม่มีอีกแล้ว',
    loading: 'กำลังโหลด',
    phoneNot: 'หมายเลขโทรศัพท์ หรือหมายเลขบัญชีไม่ว่า ง',
    SendCodeSuccess: 'ส่งรหัสยืนยันเรียบร้อยแล้ว!',
    pwdInconformity: 'สองรหัสผ่านไม่สอดคล้องกัน',
    notInput: 'ป้อนข้อมูล ที่ยังไม่สมบูรณ์',
    failed: 'ล้มเหลว'
  },
  newTab: {
    home: 'หน้าแรก',
    money: 'เติมเงิน',
    order: 'คำสั่ง',
    my: 'ของฉัน',
  },
  tab: {
    make_money: 'กำลังรับ'
  },
  Cart: {
    zzInfo: {
      buyIng: 'ในการซื้อ',
      buyFailed: 'การซื้อล้มเหลว',
      notLevel: 'คุณขาดคะแนนสมาชิก',
      buyJurisdiction1: 'หากจำเป็นต้องซื้อคำสั่งซื้อทองคำสำหรับสมาชิก',
      buyJurisdiction2: 'หากจําเป็นต้องซื้อคําสั่งแพลตตินัมของสมาชิก',
      buyJurisdiction3: 'หากจำเป็นต้องซื้อเพชร ที่สั่งซื้อ',
      invite1: 'เชิญเพื่อน 3 คนมาทำเงินด้วยกัน',
      invite4: 'เชิญเพื่อน 10 คนมาทำเงินด้วยกัน',
      invite3: 'เชิญเพื่อน 30 คน และทำเงิน',
      invite2: 'เชิญเพื่อน ๆ',
      myMoney: 'ยอดเงินในบัญชีของคุณ',
      under: 'ต่ำกว่า',
      notSendMoney: 'ไม่สามารถต่อรองได้',
      goTopUp: 'กรุณาดำเนินการกำลังเสริม',
      know: 'เข้าใจแล้ว',
      account: 'ตกลง'
    },
    hint: {
      p1: '1. ระดับสมาชิก ที่แตกต่างกันมีการสั่งซื้อ ที่แตกต่างกัน',
      p2: '2. ระดับสมาชิกสูงขึ้นค่านายหน้าสูงขึ้น',
      p3: '3. สำหรับการสั่งซื้อแล้วคุณสามารถตรวจสอบสถานะการสั่งซื้อได้ตลอดเวลา'
    },
    state: {
      all: 'ทั้งหมด',
      going: 'ดำเนินการอยู่',
      paid: 'จ่ายไปแล้ว',
      frozen: 'มันนิ่งแล้ว',
      over: 'เรียบร้อยแล้ว',
      canceled: 'ยกเลิก'
    },
    info: {
      allMoney: 'ทรัพย์สินรวมของผม ฿',
      dayAllOrder: 'รายการทั้งหมดของวันนี้',
      dayTrustMoney: 'วันนี้ มอบหมายให้คิม',
      yesterdayMoney: 'รายได้ของเมื่อวาน'
    },
    text: {
      one: 'คำสั่ง'
    }
  },
  My: {
    download: 'ดาวน์โหลดAPP',
    personal: 'ข้อมูลส่วนตัว',
    recharge: 'บันทึกการเติมเงิน',
    withdrawal: 'เอาเงินออก',
    language: 'ตั้งค่าภาษา',
    logout: 'ออก',
    MemberInformation: {
      balance: 'ยอดคงเหลือของบัญชี',
      Withdraw: 'เอาเงินออก',
      Recharge: 'เติมเงิน',
      forzenAll: 'หยุดทั้งหมด'
    },
    PersonalInformation: {
      Page: {
        Avatar: 'หัว',
        Nickname: 'ชื่อเล่น',
        PhoneNumber: 'หมายเลขโทรศัพท์',
        // BankAccounts: 'บัตรธนาคาร', // 银行账户
        BankAccounts: 'ที่อยู่กระเป๋าสตางค์ของฉัน',
        Password: 'รหัสผ่าน',
        TransactionPassword: 'รหัสผ่านการทำธุรกรรม', // 交易密码
        title: 'ข้อมูลส่วนตัว'
      },
      portrait: {
        SetAvatar: 'ตั้งค่าหัว',
        button: 'แก้ไข'
      },
      Name: {
        ModifyNickname: 'แก้ไขชื่อเล่น',
        title: 'แก้ไขชื่อเล่น',
        name: 'ชื่อเล่น',
        Please: 'กรุณาใส่ชื่อเล่น ที่คุณต้องการแก้ไข'
      },
      Password: {
        title: 'แก้ไขรหัสล็อกอิน',
        PleaseEnter: 'โปรดป้อนรหัสผ่าน ที่คุณต้องการแก้ไข',
        phone: 'โทรศัพท์มือถือ',
        NewPhone: 'กรุณาใส่เบอร์มือถือของคุณ',
        verificationCode: 'ตรวจสอบรหัส',
        verificationCodeNote: 'โปรดป้อนรหัสการตรวจสอบ',
        ConfirmPassword: 'รหัสผ่าน',
        ConfirmPasswordNote: 'โปรดป้อนข้อมูลเชิงลึก ที่คุณต้องการแก้ไข',
        Text: 'โปรดจำรหัสผ่านหากคุณลืมรหัสผ่านโปรดติดต่อฝ่ายบริการลูกค้า。',
        Button: 'ยืนยันการแก้ไข',
        ButtonCode: 'รับรหัสยืนยัน', // 获取code
      },
      AccountData: {
        title: 'รายละเอียดการซื้อขาย'
      }
    }
  },
  newAdd: {
    all: 'ทั้งหมด',
    Completed: 'เรียบร้อยแล้ว',
    unfinished: 'ยังไม่เสร็จ',
    Income: 'รายได้',
    Expenditure: 'ค่าใช้จ่าย',
    GetCodeBtn: 'กำลังรับรหัสยืนยัน',
    copySuccess: 'การคัดลอกสำเร็จ',
    copyError: 'การคัดลอกล้มเหลว',
    noLoginUserName: 'เข้าสู่ระบบ',
    Withdraw: 'เอาเงินออก',
    Recharge: 'เติมเงิน',
    commission: 'ค่านายหน้า',
    single: 'คำสั่ง',
    missionHall: 'หอปฏิบัติการ',
    videoTutorial: 'บทเรียนวิดีโอ',
    helpCenter: 'ศูนย์ช่วยเหลือ',
    inviteFriends: 'เชิญเพื่อน ๆ',
    help1Title: 'วิธีการรับค่านายหน้าผ่านซีจี',
    help1Context: 'เจ้าของธุรกิจไฟฟ้าจำเป็นต้องเพิ่มยอดขายของร้านดังนั้น เรา\
    เพียงแค่ต้องทำตามคำขอของร้านค้าในซีจีเพื่อให้เสร็จสิ้นการสั่งซื้อเจ้าของจะให้ค่านายหน้าแ\
    ก่เราผู้ใช้ แต่ละคนจะได้รับ 10 ภารกิจต่อวันคุณจะได้ค่านายหน้า 10 % ถึง 30 เปอร์เซ็นต์ภายในหนึ่งวัน',
    help2Title: 'วิธีการเพิ่มจำนวนงาน',
    help2Context: 'เนื่องจากมีงานพาร์ทไทม์มากเกินไป บริษัท จึงกำหนดให้พนักงานทำ\
    งานพาร์ทไทม์ทุกคน ที่ทำงานอินเทอร์เน็ตใช้เพียงคนเดียวในการติดต่อ และรับงาน',
    help3Title: 'จะทำให้งานสำเร็จได้อย่างไร',
    help3Context: 'ช่วยเพิ่มยอดขาย และให้รางวัลตามปริมาณการซื้อขายสำหรับ แต่ล\
    ะคำสั่ง ที่ประสบความสำเร็จคุณได้ค่านายหน้า และผู้ขายได้รับชื่อเสียง',
    referralLink: 'แนะนำลิงก์',
    copy: 'คัดลอก',
    paste: 'Dán', // 粘贴
    blance: 'ยอดคงเหลือ',
    todayOrder: 'คำสั่งซื้อวันนี้',
    provided: 'ข้อมูลนี้ ได้รับการจัดหา',
    Transaction: 'ตกลง',
    Frozen: 'ความไม่สงบ',
    Done: 'เรียบร้อยแล้ว',
    Cancel: 'ยกเลิก',
    ExpectedReturn: 'คาดว่า จะกลับ:',
    ImmediatePayment: 'จ่ายทันที',
    AvailableNum: 'จำนวนครั้ง ที่ใช้',
    OrderImmediately: 'ไปยังโฟลเดอร์ทันที',
    MakeMoneyEveryDay: 'รายได้ของวัน',
    TodayRecord: 'คำอธิบายคำสั่งซื้อ',
    AllocatCommissions: 'การกระจายค่าคอมมิชชั่น',
    FrozenAmount: 'ยอดค้างชำระ',
    TaskMatch: 'สุ่มเลือกงาน',
    AvailableBalance: 'ยอดเงิน ที่มีอยู่',
    WaitDispatch: 'กรุณาติดต่อผู้จัดการร้านเพื่อรอการมอบหมายงาน!',
    OrderDescription: 'คำอธิบายคำสั่งซื้อ',
    CouldMatch: 'งานจะถูกสุ่มจับคู่โดยระบบ:',
    OrderPerDay: 'คำสั่ง。',
    DescriptionRate: 'รวมค่าคอมมิชชั่นเดียวเป็นจำนวนเงิน ที่สั่งซื้อ',
    DescriptionPeriod: '。',
    Description3: 'ระบบมีพื้นฐานมาจากเทคโนโลยีไอบีเอส และเข้าคู่กับสินค้าโดยอัตโนมัติผ่านเมฆ',
    Description4: 'เพื่อหลีกเลี่ยงไม่ให้ถูกควบคุม หากคำสั่งซื้อไม่ได้รับการยืนยันและจัดส่งภายใน 20 นาที คำสั่งซื้อจะถูกระงับ',
    Note: 'หมายเหตุ:',
    DescriptionNote: 'เมื่อแพลตฟอร์มตรงกับผู้ใช้ในคำสั่งซื้อ แลตฟอร์มจะส่งข้อมูลคำสั่งซื้อไปยังแบ็กเอนด์ของผู้ขาย หากผู้ใช้ไม่ส่งคำสั่งซื้อภายใน 20 นาที จำนวนการสั่งซื้อจะถูกระงับโดยระบบ บัญชีผู้ใช้ที่เสร็จสิ้นภารกิจสามารถรับค่าคอมมิชชั่น สิ่งนี้ส่งผลโดยตรงต่อเวลางานถัดไปของผู้ใช้และอัตราส่วนคอมมิชชัน! กรุณาชำระเงินและถอนให้เสร็จสิ้นภายในเวลาหลังจากได้รับคำสั่งงาน!',
    OrderNum: 'หมายเลขคำสั่ง:',
    TotalOrderPrice: 'ราคารวม:',
    SubmitNow: 'ส่งทันที',
    Record: 'การบันทึก',
    MemberAccount: 'บัญชีสมาชิก',
    AccountPlaceholder: 'กรุณากรอกบัญชีของคุณ',
    RechargeAmount: 'ค่าปริยาย',
    RechargeAmountPlaceholder: 'โปรดป้อนจำนวนเงิน ที่ชาร์จ',
    SelectRechargeMethod: 'เลือกรูปแบบการเติมเงิน',
    NotBeenSentOrder: 'ยังไม่ได้ส่งคำสั่งซื้อโปรดติดต่อพนักงาน และส่งรายการ ที่สั่ง!',
    TodayOrderOver: 'สั่งเรียบร้อยแล้วค่ะพรุ่งนี้ ลองใหม่นะคะ',
    NotLogin: 'คุณยังไม่ได้ล็อกอินโปรดลองอีกครั้งหลังจากล็อกอิน!',
    // HaveNoteMethod: 'ไม่มีวิธีชาร์จแบบนี้ ในตอนนี้ โปรดเลือกวิธีการชาร์จอื่น ๆ',
    "HaveNoteMethod": "เปิดจำนวนจำกัด โปรดติดต่อฝ่ายขาย!",
    Login: 'ล็อกอิน',
    AccountRecord: 'การเปลี่ยนแปลงของบัญชี',
    RealName: 'ชื่อจริง',
    IFSCCODE: 'ไอเอสซี',
    BankName: 'ชื่อบัตรธนาคาร',
    BankNum: 'เลขบัตรธนาคาร',
    Mobile: 'มือถือ',
    MobileNum: 'โทรศัพท์มือถือ',
    Email: 'กล่องจดหมาย',
    EmailRequired: 'กล่องจดหมายจะถูกเติม',
    AddBankCardNote: 'บัตรธนาคารเป็นข้อมูลสำคัญกรุณากรอกอย่างระมัดระวัง!',
    Submit: 'ส่งข้อมูล',
    AddBankCardTitle: 'เพิ่มบัตรธนาคาร',
    BankCard: 'บัตรธนาคาร',
    Name: 'ชื่อ?',
    CardNum: 'เลขบัตร',
    Reading: 'กำลังอ่านอยู่...',
    AllBalance: 'ยอดเงินรวม',
    EnterWithdrawalAmount: 'โปรดป้อนจำนวนรวม',
    AllOut: 'ทั้งหมด',
    AskNow: 'เอาเงินออก',
    EnterPwdPlaceholder: 'โปรดป้อนรหัสผ่าน',
    NotHaveCard: 'คุณยังไม่ได้ใช้บัตรเงินคุณต้องการใช้บัตรธนาคาร หรือเปล่า',
    GetBankInfoFail: 'การดึงข้อมูลธนาคารล้มเหลว',
    EnterValidPayPwd: 'โปรดป้อนรหัสผ่านการชำระเงิน ที่ถูกต้อง',
    Balance: 'ยอดคงเหลือ',
    Total: 'ทั้งหมด',
    total: 'ทั้งหมด',
    records: 'การบันทึก',
    DisplayPerPage: 'รูปสี่เหลี่ยมต่อหน้า',
    NullNum: 'แถบ',
    pages: 'หน้า。',
    toAudit: 'หลังจากพิจารณาคดี',
    succeed: 'สำเร็จ',
    RechargeRecordTitle: 'บันทึกการเติมเงิน',
    rests: 'อื่น ๆ',
    Arrive: 'กำลังไป',
    forzenCommission: 'หยุดค่านายหน้า',
    companyIntro: 'รายละเอียด บริษัท',
    companyTitle: 'ทาตามอลล์เป็นส่วนหนึ่งของทาทากรุ๊ป',
    warmTitle: 'คำแนะนำ ที่ดี',
    companyText: 'กลุ่มทาทาเป็นกลุ่มธุรกิจ ที่ใหญ่ ที่สุดในอินเดียก่อตั้งขึ้นในปีค.ศ. 1868 ในมุมไบอินเดีย การดำเนินงานทางธุรกิจ ที่เกี่ยวข้องกับทั้งเจ็ดสาขาเทคโนโลยีการสื่อสาร และข้อมูลวิศวกรรมการบริการพลังงานสินค้าอุปโภคบริโภค และผลิตภัณฑ์เคมี ในฐานะกลุ่มธุรกิจ ที่กำลังพัฒนาอย่างรวดเร็วของอินเดียกลุ่มทาทามี บริษัท มากกว่า 200 บริษัท ในกว่า 80 ประเทศในหกทวีป รายได้รวมในปีงบประมาณ 2021 เท่ากับ 100 ล้านดอลลาร์สหรัฐ และ 58 % นั้น มาจากธุรกิจต่างประเทศ กลุ่มมีพนักงานประมาณ 450,000 คนทั่วโลก ตั้ง แต่ปี 153 ชื่อทาตาได้รับความเคารพอย่างมากในอินเดียเพราะมันยึดมั่นในคุณค่า ที่ดี และศีลธรรมทางธุรกิจ อันดับ ที่ 11 ของ บริษัท ที่ได้รับความนิยมมาก ที่สุดในโลกจากฟอร์บส์',
    depositAddress: 'ที่อยู่เติมเงิน',
    rechargeSteps: 'ขั้นตอนการเติมเงิน',
    completePayment: 'การเติมเต็มเสร็จสมบูรณ์',
    rechargeNow: 'เติมเงินทันที',
    rechargeCancel: 'ยกเลิกการเติม',
    bankName: 'บัญชีธนาคาร',
    payee: 'ชื่อบัญชี',
    cardNum: 'ธนาคาร',
    reviewing: 'ในการตรวจสอบ',
    remittance: 'มีการโอนเงินแล้ว',
    notThrough: 'ล้มเหลว',
    OrderID: 'คำสั่งID',
    "Amount": "จำนวน",
    "toCutScreen": "ตรวจสอบภาพให้เรียบร้อย!",
    "cardTabBank": "ธนาคาร",
    "cardTabUSDT": "USDT",
    "name": "ชื่อ",
    "chainType": "ประเภทโซ่",
    // 这下面是新增 2022-11-30 的
    newNavTitle: 'ส่งคำสั่ง',
    shopMoney: 'จำนวนสินค้า', // 商品金额
    actualMoney: 'ส่งจริง', // 实际提交金额（客户手动填写）
    commitBtnText: 'สร้างคำสั่ง',
    commitHint: 'อินพุตไม่ครบ' // 输入不完整
  },
  // 我的
  "mine": {
    "servier": "บริการ",
    integral: 'อินทิกรัล', // 积分展示
    balance: 'แลกเปลี่ยน', // 兑换
    record: 'บันทึกการแลกเปลี่ยน', // 兑换记录
    wtdAmt: 'แลกคะแนน', // 兑换积分
    avaBlc: 'จำนวนที่แลก', // 兑换数量
    allWtd: 'แลกทั้งหมด', // 全部兑换
    close: 'ยกเลิก',
    commit: 'ยืนยัน',
    wtdHint: 'กรุณาป้อนจำนวนเงินถอนที่ถูกต้อง' // 请输入正确的提现金额
  },
  "add": {
    "Commission": "ค่าคอมมิชชั่น",
    "Yesterday": "เมื่อวานนี้",
    "Cumulative": "ค่าปริยาย",
    "Today": "วันนี้",
    "TeamNum": "ขนาดรวมของ teamspeak",
    "TeamMoney": "ค่าคอมมิชชั่นรวม",
    "Personalcenteraccountchangerecord": "บันทึกการเปลี่ยนบัญชี",
    "lockedbalance": "ยอดค้างชำระ",
    "Cumulativeinvitation": "การเชื้อเพลิง",
    "Validinvitation": "การตรวจสอบ",
    "Accumulatedrebate": "การสะสม"
  },
  "home.btn.wallet": "การเงิน",
  // "home.btn.recharge": "ชาร์จใหม่",
  "home.btn.recharge": "ส่ง",
  "home.btn.withdraw": "การถอน",
  "home.btn.invite": "เชิญ",
  "home.btn.message": "ข้อความ",
  "page.grab": "จับ",
  "orderCommission": "สั่งการ",
  "commission": "ค่าคอมมิชชั่น",
  "todayIncome": "รายได้วันนี้",
  "personalEarnings": "รายได้จากคน",
  "completedOrders": "คอมพลีเมนต์",
  "lockedOrders": "ล็อกเกอร์",
  "unfinishOrder": "ไม่มีใครเทียบได้",
  "frozonAmount": "กองทุนไม่มั่นคง",
  "currentBalance": "สมดุลปัจจุบัน",
  "page.mine": "รายละเอียดบัญชี",
  "mine.invitationCode": "รหัสผู้รับเชิญ",
  "mine.serverCenter": "เซอร์เวอร์เซ็นเตอร์",
  "mine.tools.total": "รวม",
  "mine.tools.financial": "การเงิน",
  "mine.tools.details": "รายละเอียด",
  "mine.tools.freeze": "ยอดค้างชำระ",
  "mine.serviceTools.personal": "ส่วนตัว",
  "mine.serviceTools.teamReport": "รายงาน teamspeak",
  "mine.serviceTools.invite": "บัญชีครอบครัว",
  "mine.serviceTools.message": "ข้อความ",
  "mine.serviceTools.aboutUs": "เกี่ยวกับ",
  "mine.serviceTools.security": "ความปลอดภัย",
  "mine.serviceTools.address": "ที่อยู่",
  "mine.serviceTools.inviteTask": "งานสำหรับใช้",
  "page.team": "ทีม",
  "page.aboutus": "เกี่ยวกับ",
  "page.financial": "การเงิน",
  "page.financialRecord": "บันทึกทางการเงิน",
  "hint.InternetErr": "network ผิดพลาดโปรดลองอีกครั้งในภายหลัง!",
  "totalDeposit": "เงินมัดจำรวม",
  "totalRevenue": "รายได้รวม",
  "btn.transferInto": "โอนเข้า",
  "btn.transferOut": "โปร่งแสง",
  "page.transferInto": "โอนเข้า",
  "page.transferOut": "โปร่งแสง",
  "balanceTransfer": "งบดุลการถ่ายโอน",
  "availableBalance": "ความสมดุล ที่มีอยู่",
  "depositRate": "ผู้รับฝากเงิน",
  "days": "วัน",
  "hint.success": "สำเร็จ",
  "hint.fail": "ล้มเหลว",
  "status.into": "โอนเข้า",
  "status.out": "โปร่งแสง",
  "label.amount": "จำนวน",
  "label.time": "เวลา",
  Button: {
    "cancle": "ยกเลิก",
    "confirm": "ยืนยัน"
  },
  "dialog.title.inputPaypass": "รหัสผ่านของบันทึกข้อมูล",
  "dialog.nopaypass": "คน ที่มีประสบการณ์มากมาย",
  "mine.serviceTools.lan": "ภาษา",
  "checkLans": "รหัสภาษา",
  "mine.tools.commission": "ค่าคอมมิชชั่น",
  "page.commissionRecord": "ค่าคอมมิชชั่น",
  "noticeList.all": "ทั้งหมด",
  "noticeList.unread": "ยังไม่ได้อ่าน",
  "successWithdrawof": "การประหาร ที่ประสบความสำเร็จ",
  "home.title.notice": "แจ้งให้ทราบ",
  "home.title.patener": "จดสิทธิบัตร",
  "login.rememberPassword": "รหัสผ่านจดจำ",
  "page": {
    "cart": "รถเข็น",
    "order": "ลำดับ",
    "financing": "การเงิน",
    "goods": "สินค้า",
    "levelUpgrade": "เลวินซิเวิล",
    "PlatformIntroduction": "- ชานเมือง",
    "RuleDescription": "กฎระเบียบ",
    "AgentCooperation": "ความร่วมมือของชาวเอเชีย",
    "CommonProblem": "ปัญหาสามัญชน",
    "MemberUpgrade": "อัพเกรดเป็นสมาชิก",
    "login": "ล็อกอิน",
    "forgot": "ลืมไปแล้ว",
    "registrasi": "นายทะเบียน",
    "record": "บันทึก",
    "Recharge": "ชาร์จใหม่",
    "inviteFriends": "เพื่อนรับเชิญ",
    "Withdraw": "ถอนตัว",
    "Service": "บริการ",
    "DialoguePage": "หน้าไดอะแกรม",
    "user": "ส่วนตัว",
    "RansferIn": "แรนเฟอร์ริน",
    "PersonalCenter": "ศูนย์ส่วนบุคคล",
    "Portrait": "สัญลักษณ์",
    "Name": "ชื่อ",
    "Account": "บัญชีผู้ใช้",
    "Password": "รหัสผ่าน",
    "PaymentCode": "รหัสผ่านการชำระเงิน",
    "Site": "Site",
    "AccountData": "บันทึกการเปลี่ยนแปลงบัญชี",
    "RechargeRecord": "คำสั่งเติมเงิน",
    "WithdrawalRecord": "ถอนคำสั่ง",
    "InviteFriends": "เชิญชวน",
    "Announcement": "ประกาศ",
    "TheTeamReport": "รายงานทีม",
    "BillRecord": "สั่งซื้อสินค้า",
    "BankCardAdd": "เพิ่มบัตรธนาคาร",
    "NewRecharge": "เติมเงิน",
    "RechargeList": "คำสั่งเติมเงิน",
    "newWithdraw": "ถอน",
    "withdrawList": "ถอนคำสั่ง",
    "SettingPayPwd": "เปลี่ยนรหัสผ่านการชำระเงิน",
    "MakeMoney": "หาเงิน",
    "team": "ทีม",
    "qrPay": "การชำระเงินด้วยรหัส QR",
    "bankPay": "ชำระเงินผ่านธนาคาร",
    "aboutus": "เกี่ยวกับเรา",
    "financial": "การจัดการทางการเงิน",
    "financialRecord": "บันทึกทางการเงิน",
    "TransferInto": "โอนเข้า",
    "TransferOut": "โอนออก",
    "CommissionRecord": "คณะกรรมการ",
    "Security": "ความปลอดภัย",
    "Address": "ที่อยู่",
    "Message": "ข้อมูล",
    "InviteTask": "ขอภารกิจ",
    "Notice": "ประกาศ",
    "modifyPaymentPassword": "เปลี่ยนรหัสผ่านการชำระเงิน"
  },
  copyLink: "คัดลอกลิงค์",
  "Home.WithdrawSu.Balance": "สมดุล",
  "teamReport": {
    title: "รายงานทีม",
    subTitle: "ภาพรวมทีมรอง",
    taskTitle: "เชิญ {num} คนลงทะเบียนและทำการสั่งซื้อให้เสร็จสิ้น",
    bonus: "โบนัส",
    Unfinished: "เลิกทำ",
    Getted: "ได้รับ"
  },
  copy: "สำเนา",
  address: {
    name: "ชื่อ",
    inputPlaceholder: "กรุณาป้อน",
    phone: "โทรศัพท์",
    areaPlaceholer: "ข้อมูลที่อยู่จัดส่ง",
    submit: "บันทึก"
  },
  "My.PersonalInformation.Name.Modify": "ปรับปรุงใหม่",
  "password.old": "รหัสผ่านเก่า",
  "password.old.placeholder": "รหัสผ่านของปูมบันทึก",
  "password.confirmPassword": "คำยืนยัน",
  "password.confirmPassword.placeholder": "ใส่รหัสผ่านใหม่อีกครั้ง",
  "hint.passwordDiff": "การแข่งขันคู่แฝด",
  "loginType": {
    "phone": "โทรศัพท์",
    "email": "อีเมล"
  },
  "Login.Daftar.email": "อีเมล",
  "Login.emailInput": "กรุณาใส่อีเมลของคุณ",
  "My.PersonalInformation.Password.email": "อีเมล",
  "My.PersonalInformation.Password.NewEmail": "กรุณากรอกอีเมล์ของคุณ",
  "mine.serviceTools.certify": "ใบรับรอง",
  "Certification": {
    "type": {
      "id": "ไอดีการ์ด",
      "driver": "ใบอนุญาตขับรถ",
      "passport": "พาสปอร์ต"
    },
    "name": "ชื่อ",
    "license": "หมายเลขใบอนุญาต",
    "upload": "สารคดี",
    "submit": "ส่งข้อมูล",
    "incompleteInformation": "จีบข้อมูล และพยายามอีกครั้ง!",
    "submitSuccess": "การรับรองข้อมูลแฮสเบส",
    "photo": "ID รูปถ่าย",
    "review": "ภายใต้การทบทวน",
    "verified": "ตรวจสอบแล้ว"
  },
  "upload": {
    "uploading": "กำลังอัปโหลด...",
    "failed": "การเพิ่มระดับ"
  },
  "viGrabDesces": {
    "1": "ทุกคำสั่งสามารถเลือกเป็น Lucky Order และค่าคอมมิชชั่นจะเพิ่มขึ้นเป็น {rate}% โปรดติดต่อที่ปรึกษาการขยายงานของคุณเพื่อสมัครรับการมอบหมายงานเพิ่มเติม",
    2: "ช่วยผู้ขาย Shopee สร้างคำสั่งซื้อเสมือนจริง เพิ่มการมองเห็น และรับคอมมิชชั่น",
    3: "โปรดติดต่อครูสอนพิเศษของคุณโดยเร็วที่สุดหลังจากได้รับคำสั่งซื้อ ส่งข้อมูลคำสั่งซื้อและดำเนินการให้เสร็จสิ้น",
    4: "งานมีจำนวนจำกัด โปรดใส่ใจกับคำแนะนำการเลื่อนขั้นของผู้สอนเมื่อรับคำสั่งซื้อ",
    5: "จำนวนเงินที่คืนจะออกให้ภายใน 5-10 นาทีหลังจากเสร็จสิ้นภารกิจ หากคุณมีคำถามใด ๆ โปรดปรึกษาผู้เชี่ยวชาญหรือฝ่ายบริการลูกค้าออนไลน์",
    6: "ทำงานที่ได้รับมอบหมายจาก Shopee ให้สำเร็จ ปรับปรุงระดับ VIP และรับค่าคอมมิชชั่นที่สูงขึ้นสำหรับงาน Fackbook",
    7: "เมื่อเสร็จสิ้นภารกิจ Shopee ที่กำหนด คุณจะได้รับค่าคอมมิชชั่นเพิ่มเติม สำหรับรางวัลเฉพาะ โปรดปรึกษาผู้เชี่ยวชาญด้านโปรโมชั่นหรือฝ่ายบริการลูกค้าออนไลน์"
  },
  "expectedEarningsTitle": "กำไรที่คาดหวัง",
  rechargeDesc: {
    nav: "คำอธิบายการเติมเงิน",
    p1: "ตอนนี้เรามีพนักงานจำนวนมากที่ทำงานเสร็จพร้อมๆ กัน",
    p2: "ผู้ขายรายเดียวไม่สามารถให้บัญชีได้เพียงพอ",
    p3: "ดังนั้นเราจึงโพสต์บัญชีผู้ขายของเราในช่องว่างอื่น",
    p4: "เมื่อคุณต้องการทำงานให้เสร็จสิ้น คุณสามารถติดต่อฝ่ายขายเพื่อลงทะเบียนช่องทางการชำระเงินของคุณ"
  },
  chat: {
    list: {
      nav: "ข้อความ",
      hours: "{h} ชั่วโมง",
      minutes: "{m} นาที",
      ago: "{time}ที่แล้ว",
      image: "รูปภาพ"
    },
    room: {
      msgPlaceholder: "ใส่ข้อความ...",
      send: "ส่ง",
      sender: "ผู้ส่ง",
      sendTime: "ส่งเวลา",
      content: 'เนื้อหา'
    }
  },
  newBank: {
    usdt: {
      name: "ชื่อ",
      currency: "สกุลเงิน",
      chainType: "ประเภทโซ่",
      depositAddress: "ที่อยู่ฝาก",
      submit: "ส่ง",
      "bank": "ธนาคาร",
      "wallet": "กระเป๋าสตางค์",
      "bankName": "ชื่อธนาคาร",
      "bankCard": "บัตรธนาคาร"
    },
    "walletList": {
      pageTitle: 'การจัดการกระเป๋าเงิน',
      "name": "นามแฝงกระเป๋าสตางค์:",
      "coinType": "สกุลเงิน: ",
      "chainType": "ประเภทโซ่:",
      "walletAddress": "ที่อยู่:",
      "addDate": "วันที่:",
      "btnText": "เพิ่มที่อยู่กระเป๋าเงิน",
      "deleteConfirmText": "คุณแน่ใจหรือว่าต้องการจะลบที่อยู่กระเป๋าคุมข้อมูล?",
      "btnConfirm": "ลบ",
      "btnCancle": "ยกเลิก",
      "successHint": "ประสบความสำเร็จ"
    },
    withdrawNum: "จำนวนการถอน",
    AllOut: "ทั้งหมด",
    balance: "ความสมดุลโดยรวม",
    handlingFee: "ค่าธรรมเนียมการจัดการ",
    actualAmount: "จำนวนเงินจริง"
  },
  "order.old": "ลำดับรายได้สูง",
  "order.new": "สั่งประจำ",
  "Login.Daftar.username": "ชื่อผู้ใช้",
  "Login.Daftar.referrer": "รหัสเชิญ(ID)",
  "bannerText1": "{name} เสร็จสมบูรณ์ {เงิน} จำนวนงานที่จะได้รับ {money1} ค่าคอมมิชชั่น",
  "bannerText2": "ขอแสดงความยินดี {ชื่อ} เลือกงานพรีเมี่ยมเพื่อรับค่าคอมมิชชั่น 30%",
  "grap": {
    "btask": "งานโบนัส",
    "wtask": "งานทำงาน"
  },
  "new_goods": {
    "custdown": "การนับถอยหลัง",
    "hours": "ชั่วโมง",
    "minutes": "นาที",
    "seconds": "ไม่กี่วินาที",
    "descBtn": "คำอธิบายงาน",
    "taskBtn": "ทำงานให้สำเร็จ"
  },
  "gift": {
    "gift_shop": "ร้านของขวัญ",
    "my_gift": "ของขวัญของฉัน",
    "away": "ให้ออกไป",
    "buy": "ซื้อ",
    "overlay": {
      "inputPlaceholder": "ป้อนหมายเลขห้อง",
      "num": "จำนวนของขวัญ",
      "room": "ของขวัญหมายเลขห้อง",
      "no_data": "ไม่มีของขวัญ"
    },
    "detailTitle": "บันทึกของขวัญ",
    "num": "ปริมาณ",
    "room": "หมายเลขห้อง"
  },
  "p2p": {
    "trade_method": {
      "buy": "ซื้อ",
      "sell": "ขาย"
    },
    "coinList": {
      "orders": "{__} คำสั่ง",
      "label": {
        "amount": "จำนวน",
        "price": "ราคา",
        "limit": "จำกัด"
      }
    },
    "tradeDetails": {
      "trade_title_sell": "คุณต้องการขาย {__} กี่หน่วย?",
      "trade_title_buy": "คุณต้องการซื้อ {__} กี่หน่วย?",
      "submit_now": "ส่ง {__}"
    },
    "orderDetails": {
      "sellTitle": "รอการชำระเงินจากฝ่ายตรงข้าม",
      "buyTitle": "รอการชำระเงินจากคุณ",
      "sellHint": "ฝ่ายตรงข้ามต้องชำระเงินภายใน {__} มิฉะนั้นคำสั่งจะถูกยกเลิกโดยอัตโนมัติ",
      "buyHint": "ชำระเงินภายใน {__} เมื่อคุณชำระเงินเรียบร้อยแล้ว คลิกที่ติดต่อผู้ขาย",
      "payInfo_title": "ข้อมูลการชำระเงิน",
      "label": {
        "totalAmount": "จำนวนทั้งหมด",
        "pricePerUnit": "ราคาต่อหน่วย",
        "quantity": "ปริมาณ",
        "counterpaty": "ฝ่ายตรงข้าม",
        "payMethod": "วิธีการชำระเงิน",
        "fullName": "ชื่อเต็ม",
        "email": "อีเมล",
        "phone": "หมายเลขโทรศัพท์"
      },
      "btn_service": "ติดต่อผู้ขาย",
      "btn_sell": "ติดต่อผู้ซื้อ"
    },
    "limitHint": {
      "notEnough": "ไม่สามารถทำคำสั่งได้ ผู้ขายมี USD ไม่เพียงพอ",
      "less": "ไม่สามารถทำคำสั่งได้ ขั้นต่ำของการซื้อขายคือ {__}",
      "more": "ไม่สามารถทำคำสั่งได้ ขั้นสูงสุดของการซื้อขายคือ {__}"
    }
  },
  "paypassDialog": "คุณยังไม่ได้ตั้งรหัสผ่านการชำระเงิน กรุณาไปตั้งค่า"
}
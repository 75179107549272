<template>
  <div class="welfare_dialog_overlay" @click.stop="handleOverlayClick" v-if="show">
    <div class="content_wrap" @click.stop="() => { }">
      <div class="title">{{ $t('welfareDialogTitle') }}</div>
      <div class="contents">{{ content }}</div>
      <div class="btn_wrap">
        <div class="btn" @click="read">{{ $t('Button.confirm') }}</div>
        <div class="clsoe_flag" v-if="!closeFlag">
          <van-count-down ref="countDown" :time="time" format="ss" @finish="finish" />
        </div>
        <div v-else class="close_icon">
          <van-icon name="cross" color="#000" @click="closeClick" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, CountDown } from 'vant';
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '',
    },
    msgId: {
      type: String | Number,
      default: '',
    },
    next: false,
    clickOverlayClose: false
  },
  data() {
    return {
      welfareDialogContent: '',
      time: 10*1000,
      closeFlag: false
    }
  },
  model: {
    prop: 'show',
    event: 'updateShow',
  },
  components: {
    [Icon.name]: Icon,
    [CountDown.name]: CountDown
  },
  methods: {
    handleOverlayClick() {
      if (this.clickOverlayClose) {
        this.$emit('updateShow', false)
      }
    },
    done() {
      this.$emit('updateShow', false)
    },
    read() {
      this.done()
      this.$api.readNotice(this.msgId, (res) => {
        const { ret, data: { next } } = res.data
        let params = {}
        if (ret === 1) params = next

        this.$emit('read', this.next, params)
      })
    },
    closeClick() {
      this.done()
    },
    finish(){
      this.closeFlag = true
    },
    reset() {
      this.closeFlag = false;
      this.$refs?.countDown?.reset();
      this.$refs?.countDown?.start()
    },
  },
  watch: {
    show(newValue){
      if(newValue){
        this.reset()
      }
    }
  },
}
</script>

<style lang="less" scoped>
.welfare_dialog_overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .content_wrap {
    padding: 122px 20px 74px;
    width: calc(90vw - 110px);
    height: calc(400px - 122px - 74px);
    background-image: url('../assets/home/dialog_bg.png');
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .title {
      font-weight: 700;
      font-size: 22px;
      text-align: center;
      color: #963115;
      color: yellow;
      margin-top: 40px;
    }

    .contents {
      height: 320px;
      margin: 6px 7px;
      padding-right: 6px;
      word-break: break-all;
      overflow: hidden;
      overflow-y: scroll;
      // color: #371204;
      color: #c3bfbf;
      line-height: 2;
      display: flex;
      align-items: center;
      font-size: 16px;
      justify-content: center;
      // font-weight: bold;
    }

    .btn_wrap {
      position: absolute;
      width: 90vw;
      left: 50%;
      transform: translateX(-50%);
      bottom: 35px;
      display: flex;
      justify-content: center;

      .btn {
        line-height: 34px;
        // background: red;
        width: 200px;
        // margin-left: 10px;
        text-align: center;
        color: #574d50;
        font-weight: 600;
      }
    }
    .clsoe_flag {
      position: absolute;
      z-index: 100;
      top: 0;
      top: 38px;
      /deep/ .van-count-down{
        color: #fff;
      }
    }
    .close_icon {
      position: absolute;
      z-index: 100;
      top: 0;
      top: 38px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #fff;
    }
  }
}
</style>
<template>
  <div class="wrap">
    <div class="content_top">
      <div class="top" :class="lan == 'ar' ? 'flex_reverse':''">
        <img :src="require('@/assets/new/logo.png')" alt="">
        <div class="right_bar">
          <template>
            <div class="right" @click="$router.push('/CheckIn')">
              <i class="iconfont icon-qiandao"></i>
              <!-- <div class="text">{{ $t("page.checkin") }}</div> -->
            </div>
            <div class="right" @click="toService">
              <i class="iconfont icon-custom-service"></i>
              <!-- <div class="text">{{ $t("My.service") }}</div> -->
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="userinfo_bar" :class="lan == 'ar' ? 'flex_reverse':''">
      <VanImage width="70" height="70" :src="getImageUrl(userInfo.userimg)" round>
        <VanImage slot="error" width="70" height="70" :src="require('@/assets/Img/myImg/MyOne.png')" round />
      </VanImage>
      <div class="left">
        <div class="level_bar">
          <template v-if="verConfig.useIngegrante">
            <div class="nickname">
              {{
          userInfo.username
            ? userInfo.username
            : $t("newAdd.noLoginUserName")
        }}
            </div>
            <div class="hr"></div>
            <div class="level">
              <img :src="userInfo.rank_picture" alt="">
              <span>{{ userInfo.rank_title }}</span>
            </div>
          </template>
          <template v-else>
            <div class="nickname">
              {{
          userInfo.username
            ? userInfo.username
            : $t("newAdd.noLoginUserName")
        }}
            </div>
          </template>
        </div>
        <div class="credit_bar">
          <template v-if="verConfig.useIngegrante">
            <div class="id">
              {{ `ID: ${userInfo.id || 0}` }}
            </div>
            <div class="credit">
              {{ `${$t("mine.credit")}: ${userInfo.credit || 0}` }}
            </div>
          </template>
          <template v-else>
            <div class="id">
              {{ `ID: ${userInfo.id || 0}` }}
            </div>
            <div class="level">{{ userInfo.rank_title }}</div>
          </template>
        </div>
      </div>
    </div>
    <!-- <div class="userinfo_bar">
      <VanImage width="70" height="70" :src="getImageUrl(userInfo.userimg)" round>
        <VanImage slot="error" width="70" height="70" :src="require('@/assets/Img/myImg/MyOne.png')" round />
      </VanImage>
      <div class="level_bar">
        <template v-if="verConfig.useIngegrante">
          <div class="nickname">
            {{
          userInfo.username
            ? userInfo.username
            : $t("newAdd.noLoginUserName")
        }}
          </div>
          <div class="hr"></div>
          <div class="level">
            <img :src="userInfo.rank_picture" alt="">
            <span>{{ userInfo.rank_title }}</span>
          </div>
        </template>
        <template v-else>
          <div class="nickname">
            {{
          userInfo.username
            ? userInfo.username
            : $t("newAdd.noLoginUserName")
        }}
          </div>
        </template>
      </div>
      <div class="credit_bar">
        <template v-if="verConfig.useIngegrante">
          <div class="id">
            {{ `ID: ${userInfo.id || 0}` }}
          </div>
          <div class="credit">
            {{ `${$t("mine.credit")}: ${userInfo.credit || 0}` }}
          </div>
        </template>
        <template v-else>
          <div class="id">
            {{ `ID: ${userInfo.id || 0}` }}
          </div>
          <div class="level">{{ userInfo.rank_title }}</div>
        </template>
      </div>
      <div class="service_bar" @click="toService">
        <i class="iconfont icon-custom-service"></i>
        <div class="text">{{ $t("My.service") }}</div>
      </div>
      <div class="checkin_bar" @click="$router.push('/CheckIn')">
        <i class="iconfont icon-qiandao"></i>
        <div class="text">{{ $t("page.checkin") }}</div>
      </div>
    </div> -->
    <div class="money_bars">
      <div class="trade">
        <div class="left">
          <div class="value">
            {{ $money(Number(userInfo.money).toFixed(2) || "0.00") }}
          </div>
          <div class="title">{{ $t("Home.WithdrawSu.Balance") }}</div>
        </div>
        <div class="left">
          <div class="value">
            {{ $money(Number(userInfo.point) || "0", false) }}
          </div>
          <div class="btns">
            <div class="titles">{{ $t("mine.integral") }}</div>
            <div class="redeem" @click="handleIntegralLink('balance')">
              {{ $t("mine.balance") }}
            </div>
            <div class="record" @click="handleIntegralLink('record')">
              {{ $t("mine.record") }}
            </div>
          </div>
        </div>
      </div>
      <div class="financial">
        <div class="btns">
          <div class="recharge" @click="toRecharge">
            {{ $t("home.btn.recharge") }}
          </div>
          <div class="withdraw" @click="$router.push('new-withdraw')">
            {{ $t("home.btn.withdraw") }}
          </div>
        </div>
      </div>
    </div>
    <div class="money_bar">
      <div class="financial" v-if="verConfig.selectCurrency">
        <div class="left">
          <div class="title">{{ $t("mine.totalBalance") }}</div>
          <div class="value">
            {{
          `${$money(totalBalance, false)}USDT/C ~ ${rateBalance} ${unitTitle}`
        }}
          </div>
        </div>
        <SelectCurrency v-model="unitTitle" style="width: fit-content" @onConfirm="unitChange">
          <i class="iconfont icon-xiugaioryijian"></i>
        </SelectCurrency>
      </div>
    </div>
    <div class="groups">
      <div class="group_item" v-for="item, index in newTools" :key="index" @click="handleClick(item)">
        <i class="iconfont" color="var(--theme)" :class="item.icon"></i>
        <span>{{ $t(item.title) }}</span>
      </div>
    </div>


    <van-cell-group class="group" inset v-for="(g_item, g_index) in tools" :key="g_index"
      :style="{ marginTop: g_index === 0 ? '0' : '' }">
      <van-cell v-for="(item, index) in g_item" :key="`${g_index}_${index}`" :title="$t(item.title)" is-link
        @click="handleClick(item)" v-show="(item.name === 'commission' && verConfig.mineHasCommissionRecords) ||
          item.name !== 'commission'
          ">
        <i class="iconfont" slot="icon" color="var(--theme)" :class="item.icon"></i>
      </van-cell>
    </van-cell-group>
    <div class="about_bar" v-if="verConfig.mineShowAboutBar">
      <div class="item_bar" v-for="(item, index) in company" :key="index" @click="handleTo(item)"
        :style="{ backgroundImage: `url(${item.img})` }">
        <div class="title">{{ item.title }}&gt;&gt;</div>
      </div>
    </div>
    <div class="logout">
      <div @click="logoutEvent" class="btn">
        {{ userInfo.token ? $t("My.logout") : $t("newAdd.Login") }}
      </div>
    </div>
    <van-action-sheet v-model="switchLansShow" :actions="lans" :cancel-text="$t('Button.cancle')"
      :description="$t('checkLans')" close-on-click-action @select="checkLans" />
    <van-popup v-model="withdrawalBoxShow" :close-on-click-overlay="false" round overlay-class="pop_up">
      <div class="withdrawal-content">
        <div class="withdrawal-title">{{ $t("mine.wtdAmt") }}</div>
        <div class="withdrawal-input">
          <span></span>
          <input type="number" :placeholder="$t('mine.wtdAmt')" v-model="withdrawalVal" />
        </div>
        <div class="withdrawal-other">
          <span>{{ $t("mine.avaBlc") }} {{ userInfo.point }}&nbsp;,</span>
          <p @click="handleAllWithdrawal">{{ $t("mine.allWtd") }}</p>
        </div>
        <div class="withdrawal-commit-btn">
          <span @click="handleWithdrawalCommit('close')">{{
          $t("mine.close")
        }}</span>
          <p @click="handleWithdrawalCommit('commit')">
            {{ $t("mine.commit") }}
          </p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image as VanImage,
  Dialog,
  Toast,
  Popup,
} from "vant";
import Tabbar from "@/components/Tabbar";
import { REQUEST_API } from "@/http/api"; // 引入封装的axios
import { GET_USER_INFP, editAvatar, withdrawalBalanceApi } from "@/api";
import { $get, $post } from "@/utils/request";
// 设置vant的多语言
import Cookies from "js-cookie";
import { Locale } from "vant";
import enUS from "vant/lib/locale/lang/en-US";
// import zhCN from 'vant/lib/locale/lang/zh-CN'
import zhCN from "@/i18n/zh.js";
import thTH from "@/i18n/th.js";
import trTR from "@/i18n/tr.js";
import { countTime, getMyTime, deciTurnMoney } from "@/utils/tools";
import { GET_CONFIG_INFO } from "../../api";
import { getLanguage, setLan, setLanguage, lans } from "../../i18n";
import SelectCurrency from "@/components/currencyPicker.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    this.newTools = [
      {
        img: true,
        title: "newTab.order",
        icon: "icon-jilu",
        name: "cart",
        func: () => {
          this.$router.push("cart");
        },
      },
      {
        title: "mine.tools.commission",
        icon: "icon-weibiaoti5",
        name: "commission",
        func: () => {
          this.$router.push("CommissionRecord");
        },
      },
      {
        title: "mine.tools.details",
        icon: "icon-jilu",
        func: () => {
          this.$router.push("AccountData");
        },
      },
      {
        title: "mine.serviceTools.certify",
        icon: "icon-shenfenrenzheng",
        func: () => {
          this.$router.push("/Certification");
        },
      },
      {
        title: "mine.serviceTools.personal",
        icon: "icon-shenfenrenzheng-tianxieshenfenxinxi",
        func: () => {
          this.$router.push("PersonalCenter");
        },
      },
      {
        title: "mine.serviceTools.invite",
        icon: "icon-24gl-heartPulse",
        func: () => {
          if (
            !this.userInfo.identity ||
            this.userInfo.identity.indexOf("agent") === -1
          ) {
            return Toast(this.$t("hint.inviteBanned"));
          }
          this.$router.push("inviteFriends");
        },
      },
    ]
    this.tools = [
      [
        {
          title: "login.userAgreement",
          icon: "icon-daohangshuoming",
          func: () => {
            this.$router.push("/UserAgreement");
          },
        },
        {
          title: "mine.serviceTools.aboutUs",
          icon: "icon-gongsiguanli",
          func: () => {
            this.$router.push("Aboutus");
          },
        },
        {
          title: "mine.serviceTools.lan",
          icon: "icon-lvzhou_yuyanqiehuan",
          isSwitchLans: true,
        },
        {
          title: "My.download",
          icon: "icon-xiazai",
          func: () => {
            if (localStorage.getItem("android")) {
              location.href = localStorage.getItem("android");
            } else {
              location.href = "http://www.baidu.com";
            }
          },
        },
      ]
    ];
    this.company = [
      {
        img: require("@/assets/home/notice.png"),
        title: this.$t("home.title.notice"),
        pagePath: "Notice",
      },
      {
        img: require("@/assets/home/about.png"),
        title: this.$t("mine.serviceTools.aboutUs"),
        pagePath: "AboutUs",
      },
    ];
    this.serviceTools = [
      {
        colors: ["#f8d78b", "#f0a430"],
        title: "mine.serviceTools.certify",
        // icon: require("@/assets/mine/certify.png"),
        icon: "icon-cardid",
        func: () => {
          this.$router.push("/Certification");
        },
      },
      {
        colors: ["#eb645a", "#e75147"],
        title: "mine.serviceTools.personal",
        // icon: require("@/assets/mine/user.png"),
        icon: "icon-wode1",
        func: () => {
          this.$router.push("PersonalCenter");
        },
      },
      // {
      //   colors: ["#83b7f4", "#153ed7"],
      //   title: "mine.serviceTools.teamReport",
      //   icon: require("@/assets/mine/team.png"),
      //   func: () => {
      //     this.$router.push("team");
      //   },
      // },
      {
        colors: ["#92deb7", "#4cbf8e"],
        title: "mine.serviceTools.invite",
        // icon: require("@/assets/mine/icon_invite.png"),
        icon: "icon-yaoqing",
        func: () => {
          this.$router.push("inviteFriends");
        },
      },
      // {
      //   colors: ["#d576f7", "#8d38f4"],
      //   title: "mine.serviceTools.message",
      //   icon: require("@/assets/mine/message.png"),
      //   func: () => {
      //     this.$router.push("Message");
      //   },
      // },
      {
        colors: ["#d576f7", "#8d38f4"],
        title: "login.userAgreement",
        // icon: require("@/assets/mine/userAgreement.png"),
        icon: "icon-xiugai",
        func: () => {
          this.$router.push("/UserAgreement");
        },
      },
      // {
      //   colors: ["#f8d78b", "#f0a430"],
      //   title: "mine.serviceTools.address",
      //   icon: require("@/assets/mine/address.png"),
      //   func: () => {
      //     this.$router.push("Address");
      //   },
      // },
      {
        colors: ["#a4e8d7", "#63b5c7"],
        title: "mine.serviceTools.aboutUs",
        // icon: require("@/assets/mine/about.png"),
        icon: "icon-gongsidizhi",
        func: () => {
          this.$router.push("Aboutus");
        },
      },
      {
        colors: ["#4760ff", "#0dccff"],
        title: "My.service",
        // icon: require("@/assets/mine/service.png"),
        icon: "icon-kefu",
        func: () => {
          this.$api.toService();
          // this.$toast("This feature has not been developed!")
        },
      },
      // {
      //   colors: ["#eb645a", "#e75147"],
      //   title: "mine.serviceTools.inviteTask",
      //   icon: require("@/assets/mine/task.png"),
      //   func: () => {
      //     this.$router.push("InviteTask");
      //     // this.$toast("This feature has not been developed!")
      //   },
      // },
      // {
      //   colors: ["#6fbef8", "#388ff4"],
      //   title: "mine.serviceTools.security",
      //   icon: require("@/assets/mine/user.png"),
      //   func: () => {
      //     this.$router.push("Security");
      //   },
      // },
      // 切换语言
      {
        colors: ["#92deb7", "#83b7f4"],
        title: "mine.serviceTools.lan",
        // icon: require("@/assets/mine/lang.png"),
        icon: "icon-yuyan",
        func: () => {
          this.switchLansShow = true;
        },
      },
      {
        colors: ["#3fbbfe", "#a541ff"],
        title: "My.download",
        // icon: require("@/assets/mine/download.png"),
        icon: "icon-xiazaidaoru",
        func: () => {
          if (localStorage.getItem("android")) {
            location.href = localStorage.getItem("android");
          } else {
            location.href = "http://www.baidu.com";
          }
        },
      },
    ];
    this.lans = this.verConfig.lans || lans;
    return {
      withdrawalBoxShow: false,
      withdrawalVal: null,
      country: Cookies.get("language"),
      language: "",
      // userInfo: {
      //   name: "",
      //   code: "",
      //   money: "0",
      //   url: "",
      // },
      money: "",
      test: {
        personal: "Personal information",
        preOrde: "Pre-order record",
        account: "Account details",
        recharge: "Recharge record",
        withdrawal: "Withdrawal record",
        invite: "Invite friends",
        announcement: "Announcement",
        team: "Team report",
        language: "Language setting",
      },
      gridItemArr: [
        {
          id: 1,
          url: require("@/assets/Img/myImg/Lark20220424-133047.png"),
          t: `add.Personalcenteraccountchangerecord`,
          flag: true,
          callBack: () => {
            this.$router.push("AccountData");
          },
        },
        {
          id: 2,
          url: require("@/assets/Img/myImg/Recharge.png"),
          t: `newTab.money`,
          flag: true,
          callBack: () => {
            this.$router.push("recharge-list");
          },
        },
        {
          id: 3,
          url: require("@/assets/Img/myImg/withdrawal.png"),
          t: `My.withdrawal`,
          flag: true,
          // t: this.$t("My.AccountRecord"),
          callBack: () => {
            if (this.$cookie.get("userInfo")) {
              this.$router.push("withdraw-list"); // 账户资料跳转-小饶
            } else {
              this.$toast(
                "You are not logged in. Please log in and try again!"
              );
            }
          },
        },
        {
          id: 4,
          url: require("@/assets/Img/myImg/personal-info.png"),
          t: `My.personal`,
          flag: true,
          callBack: () => {
            if (this.$cookie.get("userInfo")) {
              this.$router.push("PersonalCenter"); // 个人中心跳转-小饶
            } else {
              this.$toast(
                "You are not logged in. Please log in and try again!"
              );
            }
          },
        },
        {
          id: 5,
          url: require("@/assets/Img/myImg/kefu.png"),
          t: `mine.servier`,
          flag: true,
          callBack: () => {
            if (localStorage.getItem("service_url")) {
              // location.href = localStorage.getItem("service_url");
              this.$api.toService();
            } else {
              this.$toast("The call center is not online yet");
            }
          },
        },
        {
          id: 6,
          url: require("@/assets/Img/myImg/downT.png"),
          t: `My.download`,
          flag: this.$isDownloadFn,
          callBack: () => {
            if (this.$cookie.get("userInfo")) {
              if (localStorage.getItem("android")) {
                location.href = localStorage.getItem("android");
              } else {
                location.href = "http://www.baidu.com";
              }
            } else {
              this.$toast(
                "You are not logged in. Please log in and try again!"
              );
            }
          },
        },
        {
          id: 7,
          url: require("@/assets/Img/myImg/sharePink.png"),
          t: `My.invite`,
          flag: true,
          callBack: () => {
            // this.$router.push('InviteFriends') // 邀请好友跳转-小饶
            this.$router.push("inviteFriends");
          },
        },
        // {
        //   id: 7,
        //   url: require('@/assets/icon/myself/service.png'),
        //   t: `My.service`,
        //   callBack: () => {
        // this.$router.push('Announcement') // 系统公告-小饶
        // this.$api.toService()
        // },
        // },
        // {
        //   id: 8,
        //   url: require('@/assets/Img/myImg/Teamreport.png'),
        //   t: `My.team`,
        //   callBack: () => {
        //     this.$router.push('TheTeamReport') // 系统公告-小饶
        //   },
        // },
        /**
         * 语言修改
         */
        // {
        //   id: 9,
        //   url: require('../../assets/Img/myImg/language.png'),
        //   t: `My.language`,
        //   callBack: () => {
        //     this.show = true
        //   },
        // },
      ],
      show: false,
      actions: [
        {
          name: this.$t("My.languageButton.english"),
          type: "en",
        },
        {
          name: "中文",
          type: "zh",
        },
        {
          name: "ข้อความ",
          type: "th",
        },
        {
          name: "Türk dili",
          type: "tr",
        },
        // {
        //   name: this.$t('My.languageButton.indonesian'),
        //   type: 'idn',
        // },
        {
          disabled: true,
        },
      ],
      /**
       * new
       */
      levelList: [
        this.$t("Cart.grade.SliverMember"),
        this.$t("Cart.grade.GoldMember"),
        this.$t("Cart.grade.PlatinumMember"),
        this.$t("Cart.grade.DiamondMember"),
      ],
      levelText: "",
      shareUrl: "",
      switchLansShow: false,
      unit: "",
      rechargePath: this.verConfig.pathNewRecharge
        ? "third-recharge-n"
        : "new-recharge",
      // unitTitle: "USD",
    };
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    // [Image.name]: VanImage,
    [Dialog.Component.name]: Dialog.Component,
    [ActionSheet.name]: ActionSheet,
    [Popup.name]: Popup,
    Tabbar,
    SelectCurrency,
    VanImage,
  },
  computed: {
    ...mapGetters(["userInfo", "unitData"]),
    totalBalance() {
      const res = Number(this.userInfo.money) + Number(this.userInfo.point);
      return !!res ? res.toFixed(2) : "0.00";
    },
    rateBalance() {
      const res =
        (Number(this.userInfo.money) + Number(this.userInfo.point)) /
        this.unitRate;
      return !!res ? res.toFixed(2) : "0.00";
    },
    unitTitle() {
      if (!this.unit) {
        return this.userInfo.currency_code || "";
      }
      const unitItem = this.unitData.find((item) => item.code === this.unit);
      return !!unitItem ? unitItem.title : "USD";
    },
    unitRate() {
      if (!this.unit) {
        const itemTemp = this.unitData.find(
          (item) => item.title === this.userInfo.currency_code
        );
        return !!itemTemp ? itemTemp.rate : 1;
      }
      const unitItem = this.unitData.find((item) => item.code === this.unit);
      return !!unitItem ? unitItem.rate : 1;
    },
  },
  methods: {
    handleTo(item) {
      this.$router.push(item.pagePath);
    },
    toService() {
      this.$api.toService();
    },
    handleClick(item) {
      if (item.isSwitchLans) {
        return (this.switchLansShow = true);
      }
      item.func();
    },
    async unitChange(value, index) {
      // console.log(value, index, this.unit);
      this.unit = value.code;
      // this.unitTitle = value.title;
      try {
        const {
          data: { ret, data, msg },
        } = await $post("/users/edit", { currency_code: value.code });
        if (ret == 1) {
          // this.$api.updateUserInfo();
          this.$store.dispatch("getUserInfo");
        }
      } catch (error) {
        console.log(error);
      }
    },
    // async getUnitData() {
    //   try {
    //     const {
    //       data: { ret, msg, data },
    //     } = await $get("/third/currency/rate");
    //     if (ret == 1) {
    //       this.unitData = data;
    //     }
    //   } catch (error) {}
    // },
    handleIntegralLink(type) {
      if (type === "balance") this.withdrawalBoxShow = true;
      else this.$router.push({ name: "AccountData", query: { type: "point" } });
    },
    handleAllWithdrawal() {
      const { point } = this.userInfo;
      if (this.withdrawalVal === point) return false;
      this.withdrawalVal = point;
    },
    handleWithdrawalCommit(type) {
      if (type === "close") {
        this.withdrawalBoxShow = false;
        this.withdrawalVal = null;
        return false;
      }
      const { point } = this.userInfo;
      if (
        !this.withdrawalVal ||
        point < this.withdrawalVal ||
        this.withdrawalVal <= 0
      )
        return Toast(this.$t("mine.wtdHint"));
      this.allWithdrawalApi();
    },

    async allWithdrawalApi() {
      const res = await withdrawalBalanceApi(this.withdrawalVal);
      const { ret, msg, data } = res.data;
      if (ret !== 1) return Toast(msg);

      this.getUserInfo();
      this.handleWithdrawalCommit("close");
    },
    //会员说明
    explain() {
      this.$router.push({ name: "levelUpgrade" });
    },
    handleLinkBill() {
      this.$router.push("bill-record"); // 个人中心跳转-小饶
    },
    async getShare() {
      const res = await GET_CONFIG_INFO({ act: "share" });
      const { data, ret, msg } = res.data;
      if (ret === 1) {
        this.shareUrl = data.text;
      }
    },
    copyShare() {
      this.$copyText(this.shareUrl).then(() => {
        Toast("success");
      });
    },
    toRecharge() {
      if (this.ver == "amigo_vi") {
        this.$router.push("/RechargeDesc");
        return;
      }
      if (this.verConfig.pathNewRecharge)
        this.$router.push({ name: "thirdRechargeNew" });
      else this.$router.push({ name: "NewRecharge" });
    },
    onSelect(e) {
      console.log(e.type);
      this.country = e.type;
      switch (e.type) {
        case "en":
          this.$i18n.locale = e.type;
          Locale.use("en-US", enUS);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
        case "idn":
          this.$i18n.locale = e.type;
          Locale.use("en-US", enUS);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
        case "zh":
          this.$i18n.locale = e.type;
          Locale.use("zh-CN", zhCN);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
        case "th":
          this.$i18n.locale = e.type;
          Locale.use("th-TH", thTH);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
        case "tr":
          this.$i18n.locale = e.type;
          Locale.use("tr-TR", trTR);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
      }
      this.language = e.type;
      this.show = false;
      /**
       * new
       */
      this.levelList = [
        this.$t("Cart.grade.SliverMember"),
        this.$t("Cart.grade.GoldMember"),
        this.$t("Cart.grade.PlatinumMember"),
        this.$t("Cart.grade.DiamondMember"),
      ];
      let level =
        JSON.parse(localStorage.getItem("user-info"))[1].data.player.level || 1;
      this.levelList.forEach((_, i) => {
        if (level === i + 1) {
          this.levelText = _;
        }
      });
    },
    // 顶部导航栏的设置事件-小饶
    Personal() {
      if (this.$cookie.get("userInfo")) {
        this.$router.push("PersonalCenter");
      } else {
        this.$toast(this.$t("newAdd.NotLogin"));
      }
    },
    onClose() {
      this.show = false;
    },
    // 点击充值的按钮
    RechargeEvent() {
      this.$router.push(this.rechargePath);
    },
    // 点击提现按钮
    WithdrawEvent() {
      this.$router.push({ name: "newWithdraw" });
    },
    // 注销的按钮
    logoutEvent() {
      let _this = this;
      if (_this.userInfo.token) {
        Dialog.confirm({
          title: this.$t("My.quit"),
          message: this.$t("My.logoutEvent"),
          cancelButtonText: this.$t("My.Button.One"),
          confirmButtonText: this.$t("My.Button.Two"),
        })
          .then(() => {
            let pwdState = _this.$cookie.get("the-pwd-state");
            let pwdObj = _this.$cookie.get("the-pwd-obj");
            _this.$cookie.clear();
            _this.$cookie.set("the-pwd-state", pwdState);
            _this.$cookie.set("the-pwd-obj", pwdObj);
            _this.$router.push("/login");
            // window.location.reload()
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.$router.push("/login");
      }
    },
    // 获取账户余额
    BalanceEvent() {
      let form = {
        cmd: "202445",
        uid: this.module.UIDEvent(),
      };
      REQUEST_API(form).then((res) => {
        this.$store.commit("SET_MONEY_INFO", res.data.notifyList[0].data);
        // this.money = res.data.notifyList[0].data.diamond
        this.money = deciTurnMoney(this.$store.state.user.moneyObj.diamond);
      });
    },
    getImageUrl(url) {
      const reg = new RegExp("http");
      if (reg.test(url)) return url;
      return this.verConfig.url + url;
    },
    // 获取用户信息
    getUserInfo() {
      // if (this.$cookie.get('userInfo')) {
      //   this.userInfo = JSON.parse(this.$cookie.get('userInfo'))
      // }
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$api.getUserInfo(
        (res) => {
          Toast.clear();
          this.userInfo = res;
          this.unit = res.currency_code || "USD";
        },
        (err) => {
          Toast.clear();
        }
      );
    },
    getUserInfo2() {
      GET_USER_INFP()
        .then((r) => {
          if (r.data.ret === 1) {
            this.$cookie.set("userInfo", JSON.stringify(r.data.data));
            this.$cookie.set("token", r.data.data.token);
            this.userInfo = r.data.data;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getDownloadUrl() {
      let url = {};
      let down = {
        id: 5,
        url: require("@/assets/icon/myself/download.png"),
        t: `My.download`,
        callBack: () => {
          window.location.href = url.android;
        },
      };
      this.$api.getNotice((res) => {
        if (res.appurl_android) {
          url.android = res.appurl_android;
          // this.gridItemArr.push(down);
        }
      });
    },
    checkLans(actions, index) {
      if (actions.value === getLanguage()) {
        this.switchLansShow = false;
        return;
      }
      setLanguage(actions.value);
      location.reload();
    },
  },
  created() {
    // this.getUserInfo();
    // this.getDownloadUrl();
    // this.getShare();
    // this.getUnitData();
    this.$store.dispatch("getUserInfo")
    if (this.verConfig.mineShowWallet) {
      this.tools[0].splice(3, 0, {
        title: "newBank.walletList.pageTitle",
        icon: "icon-gl-debitCard",
        func: () => {
          this.$router.push("walletList");
        },
      });
    }
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@button-border-radius: 20px;
@background: var(--nav-background);
@bg: var(--bg);
@sizeColor: var(--sizeColor);

// 弹性布局
.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

// 文字超出省略号
.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

.wrap {
  min-height: 100vh;
  // background: #f6f8f9;
  background: @background;
  overflow: hidden;

  .userinfo_bar {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    // background-color: var(--bg);
    background-color: var(--marqueeItemColor);
    border-radius: calc(20rem / 16);
    padding: calc(16rem / 16);
    margin: calc(-60rem / 16) calc(13rem / 16) 0;

    .avatar {
      background-size: 100% 100%;
    }

    .left {
      margin-left: calc(16rem / 16);
    }

    .level_bar {
      display: flex;
      // justify-content: space-around;
      align-items: center;
      // color: #fff;
      color: var(--timeColor);
      margin-top: calc(14rem / 16);
      margin-bottom: calc(20rem / 16);
      // font-family: PingFangSC-Medium;
      font-size: calc(15rem / 16);

      .hr {
        width: 1px;
        height: calc(22rem / 16);
        // background: #fff;
        background: var(--timeColor);
        margin: 0 calc(16rem / 16);
      }

      .level {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: calc(26rem / 16);
          height: calc(26rem / 16);
          animation: floatAnimation 1.5s infinite;
        }

        span {
          margin-left: calc(4rem / 16)
        }

        @keyframes floatAnimation {
          0% {
            transform: translateY(0px);
          }

          50% {
            transform: translateY(-10px);
          }

          100% {
            transform: translateY(10px)
          }
        }
      }
    }

    .credit_bar {
      // width: 60%;
      .level_bar();
      justify-content: center;
      margin-top: 0;
      // color: #fff;
      color: var(--timeColor);

      >div {
        // width: 50%;
        // text-align: end;
        text-align: center;
        position: relative;
        padding-right: calc(16rem / 16);

        &:nth-child(1)::after {
          content: "|";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 16px;
        }

        &:nth-child(2) {
          // text-align: start;
          text-indent: calc(16rem / 16);
        }
      }
    }

    .invite_bar {
      background: #fff;
      border-radius: calc(6rem / 16);
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: calc(5rem / 16);

      .left {
        display: flex;
        align-items: center;

        .iconfont {
          font-size: calc(30rem / 16);
          // color: #1200dc;
        }

        .text {
          margin-left: calc(6rem / 16);
          // font-family: PingFangSC-Regular;
          font-size: calc(15rem / 16);
          // color: #1200dc;
        }
      }

      .btn_copy {
        background: #ffa300;
        padding: calc(6rem / 16) calc(10rem / 16);
        border-radius: 99px;
        font-size: calc(15rem / 16);
        line-height: 1;

        &:active {
          opacity: 0.8;
        }
      }
    }

    .service_com {
      position: absolute;
      top: calc(35rem / 16);
      text-align: center;
      // color: #fff;
      color: var(--theme);

      &:active {
        opacity: 0.8;
      }

      .iconfont {
        font-size: calc(31rem / 16);
      }

      .text {
        font-size: calc(15rem / 16);
      }
    }

    .service_bar {
      .service_com();
      right: calc(32rem / 16);
    }

    .checkin_bar {
      .service_com();
      right: none;
      left: calc(20rem / 16);
    }
  }

  .money_bar {
    padding: calc(13rem / 16);

    >div {
      background-size: 100% 100%;
      border-radius: calc(8rem / 16);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: calc(10rem / 16) calc(16rem / 16);
      padding-right: calc(9rem / 16);
      // color: #fff;
      margin-top: calc(13rem / 16);
      // background-color: var(--theme);
      // background-color: #fff;
      background-color: var(--marqueeItemColor);
      color: var(--timeColor);

      .title {
        color: var(--timeColor);
      }

      &:nth-child(1) {
        // background-image: url("../../assets/mine/bg/2.png");
        margin-top: 0;
      }

      &:nth-child(2) {
        // background-image: url("../../assets/mine/bg/3.png");
      }

      &:nth-child(3) {
        // background-image: url("../../assets/mine/bg/4.png");
      }

      .left {
        >.title {
          font-size: calc(15rem / 16);
        }

        >.value {
          font-size: calc(18rem / 16);
          margin-top: calc(20rem / 16);
        }
      }

      .btns {
        display: flex;

        >div {
          &:active {
            opacity: 0.8;
          }

          // background: #fff;
          // color: #1200dc;
          // background: var(--theme);
          // background: var(--nav-background);
          background: var(--btnColor);
          // color: #fff;
          color: var(--btnsizeColor);
          font-size: calc(12rem / 16);
          padding: calc(8rem / 16) calc(18rem / 16);
          border-radius: 99px;

          &:nth-child(2) {
            margin-left: calc(12rem / 16);
          }
        }
      }

      >.currencyPickerWrap {
        background: transparent;

        .iconfont {
          font-size: calc(28rem / 16);
          color: var(--timeColor);
        }
      }
    }
  }

  /deep/ .van-cell-group {
    background-color: var(--marqueeItemColor);
  }

  /deep/ .van-cell__right-icon {
    color: var(--timeColor);
  }

  .group {
    margin-top: calc(10rem / 16);

    &:nth-child(1) {
      margin-top: 0;
    }

    .iconfont {
      font-size: calc(20rem / 16);
      margin-right: calc(8rem / 16);
      color: var(--timeColor);
    }

    .van-cell {
      background-color: var(--marqueeItemColor);
    }

    .van-cell::after {
      border-bottom: none;
    }

    .van-cell__title {
      color: var(--timeColor);
    }
  }

  .trade_bar {
    display: flex;
    margin: calc(13rem / 16);
    // margin-top: 0;
    justify-content: space-between;
    @gap: calc(15rem / 16);

    >div {
      width: calc((100% - @gap) / 2);
      height: calc(60rem / 16);
      border-radius: 10px;
      background-color: #fff;
      // background-color: @bg;
      display: flex;
      justify-content: space-around;
      align-items: center;

      >.iconfont {
        font-size: calc(30rem / 16);
      }

      >.text {
        font-size: calc(14rem / 16);
        // color: #000;
      }
    }

    .recharge {
      // color: #955f00;
    }

    .withdraw {
      // color: #04672d;
    }
  }

  .about_bar {
    .trade_bar();

    >div {
      border-radius: 6px;
      justify-content: flex-end;
      align-items: center;
      background-size: 100% 100%;

      >.title {
        font-size: calc(13rem / 16);
        padding: calc(6rem / 16) calc(8rem / 16);
        padding-left: calc(12rem / 16);
        background-color: #0271dc;
        color: #fff;
        border-top-left-radius: 90px;
        border-bottom-left-radius: 90px;
      }
    }
  }

  .logout {
    width: 85%;
    margin: 20vw auto;
    margin-top: calc(54rem / 16);
    // background-color: @Color;
    background: var(--newBtn);
    margin-bottom: 20vw;
    padding: calc(12rem / 16) 0;
    // color: #fff;
    color: var(--btnsizeColor);
    border-radius: calc(40rem / 16);

    .btn {
      text-align: center;
    }
  }

  /deep/ .van-popup {
    min-width: 80%;
  }

  .withdrawal-content {
    min-width: 80%;
    padding: calc(20rem / 16);
    border-radius: calc(6rem / 16);
    background-color: @bg;

    >.withdrawal-title {
      font-size: calc(20rem / 16);
      // color: #aa9172;
      color: @Color;
      text-align: center;
    }

    >.withdrawal-input {
      margin: calc(14rem / 16) 0;
      flex: 1;

      >input {
        width: 100%;
        border: none;
        border-bottom: calc(1rem / 16) solid #000;
        font-size: calc(18rem / 16);
        padding-bottom: calc(5rem / 16);
        background: @background;
        padding-left: calc(10rem / 16);
        color: @Color;
      }

      input::placeholder {
        color: @sizeColor;
      }
    }

    >.withdrawal-other {
      display: flex;
      font-size: calc(14rem / 16);

      >span {
        // color: #9b9898;
        color: @sizeColor;
      }

      >p {
        margin-left: calc(10rem / 16);
        // color: #527eb4;
        color: @Color;
      }
    }

    >.withdrawal-commit-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: calc(20rem / 16);
      font-size: calc(16rem / 16);

      >span {
        // color: #9b9898;
        color: @sizeColor;
      }

      >p {
        margin-left: calc(20rem / 16);
        // color: #527eb4;
        color: @Color;
      }
    }
  }
}

.van-action-sheet {
  background-color: @bg;

  .van-action-sheet__description {
    color: @Color;
  }

  .van-action-sheet__gap {
    background-color: @background;
  }

  .van-action-sheet__description::after {
    border-bottom: 1px solid @background;
  }

  .van-action-sheet__cancel,
  .van-action-sheet__item {
    background-color: @bg;
    color: @Color;
  }
}

.content_top {
  background: url('../../assets/new/top_bg.png') no-repeat;
  height: calc(120rem / 16);
  background-size: 100% 100%;

  .top {
    padding: calc(16rem / 16) calc(13rem / 16);
    display: flex;
    justify-content: space-between;

    img {
      height: calc(25rem / 16);
    }

    .right_bar {
      display: flex;
      align-items: center;

      .right {
        margin-right: calc(10rem / 16);

        /deep/ .van-badge {
          border: none;
        }

        >.iconfont {
          color: var(--btnsizeColor);
          font-size: calc(28rem / 16);
        }
      }

      .right:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }
}

.money_bars {
  margin: calc(13rem / 16) calc(13rem / 16) 0;
  background-color: var(--marqueeItemColor);
  background-size: 100% 100%;
  border-radius: calc(8rem / 16);
  padding: calc(20rem / 16) calc(16rem / 16);
  padding-right: calc(9rem / 16);
  color: var(--timeColor);

  .trade {
    display: flex;
    justify-content: space-between;

    .left {

      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      .value {
        font-size: calc(16rem / 16);
      }

      .title {
        margin-top: calc(11rem / 16);
        font-size: calc(14rem / 16);
      }

      .btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: calc(8rem / 16);

        .titles {
          font-size: calc(14rem / 16);
        }

        .redeem,
        .record {
          background-color: var(--newBtn);
          color: var(--btnsizeColor);
          flex: 1;
          border-radius: calc(20rem / 16);
          padding: calc(6rem / 16);
          font-size: calc(12rem / 16);
          text-align: center;
        }

        .redeem {
          margin-left: calc(16rem / 16);
          margin-right: calc(8rem / 16);
        }
      }
    }
  }

  .financial {
    margin-top: calc(20rem / 16);

    .btns {
      display: flex;
      justify-content: space-between;

      .recharge,
      .withdraw {
        background-color: var(--newBtn);
        color: var(--btnsizeColor);
        flex: 1;
        border-radius: calc(20rem / 16);
        padding: calc(6rem / 16);
        font-size: calc(14rem / 16);
        text-align: center;
      }

      .recharge {
        margin-right: calc(20rem / 16);
      }
    }
  }
}
.groups{
  margin: 0 calc(13rem / 16) calc(13rem / 16);
  background-color: var(--marqueeItemColor);
  background-size: 100% 100%;
  border-radius: calc(8rem / 16);
  padding: calc(20rem / 16) calc(16rem / 16);
  padding-right: calc(9rem / 16);
  color: var(--timeColor);
  display: flex;
  flex-wrap: wrap;
  gap: calc(18rem / 16);
  .group_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: calc((100% / 4) - (18rem / 16)) ;
    // max-width: 25%;
    span{
      font-size: calc(14rem / 16);
      white-space: nowrap;
      margin-top: calc(6rem / 16);
    }
    .iconfont{
      font-size: calc(24rem / 16);
    }
  }
}
.flex_reverse{
  flex-direction: row-reverse;
}
</style>
<template>
  <div id="Password" class="wrap">
    <van-nav-bar z-index="10" :fixed="true" placeholder :title="$t('My.PersonalInformation.Password.title')" left-arrow
      @click-left="onClickLeft" />
    <van-notice-bar color="var(--theme)" background="var(--bg)">
      {{ $t('My.PersonalInformation.Password.PleaseEnter') }}
    </van-notice-bar>
    <van-field v-if="userInfo.setpaypass == 1" label-width="6.5rem" colon v-model="DataArr.OldPassword"
      :label="$t('password.old')" :placeholder="$t('password.old.placeholder')" />
    <van-field label-width="6.5rem" colon v-model="DataArr.Password"
      :label="$t('My.PersonalInformation.Password.ConfirmPassword')"
      :placeholder="$t('My.PersonalInformation.Password.ConfirmPasswordNote')" />
    <van-field label-width="8rem" colon v-model="DataArr.ConfirmPassword" :label="$t('password.confirmPassword')"
      :placeholder="$t('password.confirmPassword.placeholder')" />
    <div class="button-confirm">
      <van-button size="normal" type="default" block @click="ButtomEvent()">{{
        $t('My.PersonalInformation.Password.Button')
      }}</van-button>
    </div>
  </div>
</template>

<script>
import { getNewPhone } from '@/utils/tools'
import Modelu from '@/utils/Module'
import { SETTING_PAY_PDW } from '@/api'
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  NoticeBar,
  Field,
  Toast,
} from 'vant'

export default {
  name: 'portrait',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [Toast.name]: Toast,
  },
  data() {
    return {
      isDisabled: false, // 验证码按钮
      userInfo: {},
      DataArr: {
        OldPassword: '',
        Password: '',
        ConfirmPassword: ''
      },
      codeImg: '',
      GetCodeTime: 0,
      GetCodeInterval: null
    }
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    ButtomEvent() {
      if (this.userInfo.setpaypass == 1) {
        if (!this.DataArr.OldPassword) return Toast(this.$t('password.old.placeholder'));
      }
      if (!this.DataArr.Password) return Toast(this.$t('My.PersonalInformation.Password.ConfirmPasswordNote'));
      if (!this.DataArr.ConfirmPassword) return Toast(this.$t('password.confirmPassword.placeholder'));
      if (this.DataArr.Password !== this.DataArr.ConfirmPassword) return Toast(this.$t('hint.passwordDiff'));
      Toast.loading();
      SETTING_PAY_PDW({
        oldpass: this.DataArr.OldPassword,
        newpass: this.DataArr.Password,
      }).then(res => {
        Toast(res.msg)
        setTimeout(() => {
          this.getUserInfo();
          this.$router.back();
        }, 800);
      }).catch(err => {
        Toast(err.msg)
      })
    },
    getUserInfo() {
      Toast.loading();
      this.$api.getUserInfo(res => {
        this.userInfo = res
        Toast.clear();
      }, err => {
        Toast.clear();
        this.$toast(err);
      })
    }
  },
}
</script>

<style lang="less">
#Password {
  width: 100%;
  height: 100%;

  .van-notice-bar {
    border-bottom: 1px solid var(--theme);
  }

  .van-cell {
    background-color: var(--bg);

    .van-cell__title {
      >span {
        font-size: 0.875rem;
      }
    }

    .van-field__label {
      color: var(--sizeColor);
    }

    .van-field__control {
      color: var(--theme);
    }

    input::placeholder {
      color: var(--theme);
    }
  }

  .van-cell::after {
    border-bottom: 1px solid var(--theme);
  }

  .button-confirm {
    width: 100%;
    margin-top: 1.5625rem;

    >.text {
      color: red;
      width: 90%;
      margin: 0 auto;
      font-size: 0.75rem;
      margin-bottom: 1.5625rem;
    }

    .code-img {
      width: 90%;
      // height: calc(44rem / 16);
      height: calc(80rem / 16);
      margin: calc(15rem / 16) auto;
      display: flex;
    }

    .van-button {
      width: 90%;
      margin: 0 auto;
      border-radius: 0.5rem;
      // background-color: #2f3848;
      // background-color: var(--bg);
      // color: var(--theme);
      background-color: var(--newBtn);
      color: var(--btnsizeColor);
      border: none;
      // color: #ffffff;
    }

    .van-button::before {
      border: none;
    }
  }
}
</style>
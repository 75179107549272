<template>
  <div id="PersonalCenter" class="wrap">
    <van-nav-bar
      :fixed="true"
      :title="$t('My.PersonalInformation.Page.title')"
      left-arrow
      placeholder
      @click-left="onClickLeft"
    />
    <div class="content">
      <div
        class="content-item"
        :class="{ state: NavIndex == index }"
        v-for="(item, index) in Label"
        :key="index"
        @click="MousedownEvent(index, item.url)"
        v-show="!(verConfig.mineShowWallet && item.url === 'walletList')"
      >
        <div>
          <van-image width="1rem" height="1rem" :style="{flexShrink: 0}" round :src="item.img" />
          <span>{{ $t(item.text) }}</span>
        </div>
        <div>
          <van-image
            v-if="index == 0"
            width="2.6rem"
            height="2.5rem"
            :style="{flexShrink: 0}"
            round
            :src="
              getImage(userInfo[item.image]) ||
              require('../../assets/Img/myImg/MyOne.png')
            "
          />
          <span v-if="item.span">
            {{ userInfo[item.span] || $t(`users.${item.span}`) }}
          </span>
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
    <div class="logout" @click="logoutEvent">
      {{ userInfo.token ? $t("My.logout") : $t("newAdd.Login") }}
    </div>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  Toast,
  Dialog,
} from "vant";
import { mapGetters } from 'vuex';

export default {
  name: "PersonalCenter",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [Toast.name]: Toast,
  },
  data() {
    this.Label = [
      {
        id: "1",
        // img:require('@/assets/Img/myImg/MyOne.png'),
        img: require("@/assets/icon/myInfo/avatar.png"),
        image: "userimg",
        url: "Portrait",
        text: `My.PersonalInformation.Page.Avatar`,
      },
      {
        id: "2",
        // img:require('@/assets/Img/myImg/MyTwo.png'),
        img: require("@/assets/icon/myInfo/edt.png"),
        span: "username",
        // span: this.userInfo && this.userInfo.username,
        url: "Name",
        text: `My.PersonalInformation.Page.Nickname`,
      },
      {
        id: "3",
        // img:require('@/assets/Img/myImg/MyThree.png'),
        img: require("@/assets/icon/myInfo/phone.png"),
        span: "userphone",
        url: "/phone",
        banEdit: true,
        // text: `My.PersonalInformation.Page.PhoneNumber`,
        text: 'personal.page.phone'
      },
      {
        id: "4",
        // img:require('@/assets/Img/myImg/MyThree.png'),
        img: require("@/assets/icon/myInfo/mail.png"),
        span: "email",
        banEdit: true,
        url: "/email",
        text: `personal.page.email`,
      },
      {
        id: "4",
        // img:require('@/assets/Img/myImg/MyFour.png'),
        img: require("@/assets/icon/myInfo/bank.png"),
        // url: "add-bank-card",
        url: "walletList",
        text: `My.PersonalInformation.Page.BankAccounts`,
      },
      {
        id: "5",
        // img:require('@/assets/Img/myImg/MyFive.png'),
        img: require("@/assets/icon/myInfo/pwd.png"),
        url: "Password",
        text: `My.PersonalInformation.Page.Password`,
      },
      {
        id: "6",
        img: require('@/assets/icon/myInfo/pwd.png'),
        url: "PaymentCode",
        text: `My.PersonalInformation.Page.TransactionPassword`,
      },
      // {
      //     id:"7",
      //     img:require('@/assets/Img/myImg/MyFive.png'),
      //     url:"Site",
      //     text:`My.PersonalInformation.Page.ReceivingAddress`,
      // },
    ];
    return {
      // userInfo: {},
      NavIndex: -1, // 控制选中状态的
    };
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1);
    },
    // 标签鼠标按下
    MousedownEvent(num, url) {
      let _this = this;
      this.NavIndex = num;
      const item = this.Label[num];
      setTimeout(function () {
        _this.NavIndex = -1;
      }, 150);
      if (url != "") {
        if(item.banEdit){
          if(!!this.userInfo[item.span]) return Toast(this.$t("banEditUserInfo"));
        }
        this.$router.push(url);
      }
    },
    // 注销的按钮
    logoutEvent() {
      let _this = this;
      if (this.userInfo && _this.userInfo.token) {
        Dialog.confirm({
          title: this.$t("My.quit"),
          message: this.$t("My.logoutEvent"),
          cancelButtonText: this.$t("My.Button.One"),
          confirmButtonText: this.$t("My.Button.Two"),
        })
          .then(() => {
            let pwdState = _this.$cookie.get("the-pwd-state");
            let pwdObj = _this.$cookie.get("the-pwd-obj");
            _this.$cookie.clear();
            _this.$cookie.set("the-pwd-state", pwdState);
            _this.$cookie.set("the-pwd-obj", pwdObj);
            _this.$router.push("/login");
            // window.location.reload()
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.$router.push("/login");
      }
    },
    // 获取用户信息
    getUserInfo() {
      // if (this.$cookie.get("userInfo")) {
      //   this.userInfo = JSON.parse(this.$cookie.get("userInfo"));
      //   this.Label[0].image = this.userInfo.userimg;
      //   this.Label[1].span = this.userInfo.username;
      //   this.Label[2].span = this.userInfo.userphone;
      // }
      // this.$api.updateUserInfo((res) => {
      //   res && (this.userInfo = res);
      // });
      this.$store.dispatch("getUserInfo");
    },
    getImage(url) {
      const reg = new RegExp("http");
      if (reg.test(url)) {
        return url;
      }
      return this.verConfig.url + url;
    },
  },
  created() {
    this.getUserInfo();
    // if(this.module.PersonalEvnet()[1].data.player.icon!=""){
    //     this.Label[0].image = this.module.PersonalEvnet()[1].data.player.icon;
    // }else{
    //     this.Label[0].image = require('@/assets/Img/myImg/head.png')
    // }
    // this.Label[1].span = this.module.PersonalEvnet()[1].data.player.nickname
    // this.Label[2].span = this.module.PersonalEvnet()[0].data.info.phone
  },
  computed: {
    ...mapGetters(["userInfo"])
  }
};
</script>

<style lang="less">
@Color: var(--theme);
@background: var(--nav-background);
@bg: var(--bg);
@sizeColor: var(--sizeColor);
#PersonalCenter {
  width: 100%;
  height: 100%;

  .logout {
    width: 90vw;
    margin-left: 5vw;
    text-align: center;
    // color: #fff;
    // color: @Color;
    color: var(--btnsizeColor);
    line-height: calc(44rem / 16);
    // background: @bg;
    background-color: var(--newBtn);
    border-radius: calc(22rem / 16);
    position: fixed;
    bottom: 10vh;
  }
  @media screen and (min-width: 750px) {
    .logout {
      width: calc(750px * .9);
      margin-left: calc(750px * .1 / 2);
    }
  }

  .content {
    width: calc(100% - 2rem);
    padding: 0.125rem 1rem;

    .state {
      background-color: #ade1f5;
    }

    .content-item {
      height: 3.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      // border-bottom: 1px solid #eee;
      border-bottom: 1px solid @Color;

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;

        > span {
          font-size: 1rem;
          color: @sizeColor;
        }

        .van-image {
          margin-right: 0.3rem;
        }
      }

      > div:nth-child(2) {
        > span {
          font-size: 0.75rem;
          // color: #999;
          color: @Color;
        }

        .van-icon {
          margin-left: 0.75rem;
          // color: #ddd;
          color: @sizeColor;
        }
      }
    }
  }
}
.state{
  background-color: transparent !important;
}
</style>
export default {
  // 公共的key
  common: {
    unrealized: 'unvollständig',
    all: 'alle',
    distributionTip: '$0 für 0 Versand',
    easeBack: 'Mach dir keine Sorgen',
    addCarSuccess: 'Erfolgreich in den Warenkorb gelegt',
    deleteConfirm: 'Möchten Sie das ausgewählte Element wirklich löschen?'
  },
  //商品详情
  goodsDetail: {
    goodsDetail: 'Produktdetails',
    shopping: 'reingehen',
    end: 'Ende der Distanz',
    sold: 'verkauft',
    goodsTips: 'Dieser Artikel hat einen Wert von 500 g/Artikel mit einem Wert unter 500 g und anderen Preisen.',
    quickTime: 'am schnellsten in 29 Minuten zu erreichen',
    specifications: 'Einzelheiten',
    preservationConditions: 'Bedingungen speichern',
    coldStorage: 'gekühlt',
    shelfLife: 'Verfallsdatum',
    day: '15 Tage',
    addToCar: 'Einkaufswagen beitreten'
  },
  //购物车
  car: {
    "title": "Wagen",
    "delete": "löschen",
    "tip": "Der Warenkorb ist leer~",
    "walk": "Komm, wir gehen feiern",
    "all": "Auswahl",
    "guess": "Denke es wird dir gefallen."
  },
  // 首页
  home: {
    HallofFame: 'Ruhmeshalle',
    chooseLocation: 'Bitte wählen Sie einen Standort aus..',
    more: 'mehr',
    home: 'Startseite',
    category: 'Sortierung',
    eat: 'was essen?',
    cart: 'Wagen',
    mine: 'mein',
    inputGoodsName: 'Geben Sie den Namen des Elements ein.',
    vipDesc: 'Die Teilnahme der Mitglieder wird erwartet. spart etwa 806 Dollar pro Jahr',
    vipSubDesc: '50 % Rabatt>',
    specialZone: 'Sondergebiet',
    foodie: 'essen tag',
    dinner: 'Abendessen',
    popularity: 'Popularität',
    goodChoose: 'Auswahl',
    limitBuy: 'begrenzt',
    bottomTip: 'Ich habe eine Grenze',
    chooseAddress: 'Bitte wählen Sie eine Adresse aus',
    currentLocation: 'Deine aktuelle Position',
    nearbyAddress: 'in der Nähe'
  },
  // 分类
  category: {
    "tip": "Geben Sie den Namen des Elements ein.",
    "bottomTip": "Okay, kommen wir zu den anderen Typen."
  },
  // 吃什么
  eat: {
    "all": "alle",
    "close": "Verabschiedung",
    "pleaseHolder": "Suche nach Rezeptzutaten",
    "menu": "Heutiges Menü",
    "menuDevide": "Menüsortierung",
    "recent": "gerade gefunden",
    "tip_bottom": "Es ist vorbei, hör auf zu ziehen!",
    "tip": "Sammlung wurde noch nicht gespeichert~"
  },
  // 我的
  mine: {
    title: 'mein',
    phone: 'Telefonnummer',
    login: 'Jetzt einloggen',
    myOrder: 'meine Bestellung',
    allOrder: 'Alle bestellten Artikel anzeigen',
    myBill: 'mein gutschein',
    myLocation: 'meine Abholadresse',
    myCar: 'meine grüne karte',
    servier: 'Wenden Sie sich an den Kundendienst',
    servierTime: 'Kundendienstzeit 07.00-22: 00',
    feedback: 'Kommentar',
    switchLanguage: 'Sprache',
    chinese: 'Chinesisch',
    english: 'Englisch',
    version: 'aktuelles Modell',
    waitingPay: 'spätere Ausgaben',
    waitingReceive: 'Ware erhalten',
    waitingComment: 'bewerten',
    waitingFeedback: 'Nach Verkauf/Rückerstattung',
    unrealized: 'Die Rückerstattungsseite wurde noch nicht geöffnet!',
    tip2: 'Vergesst nicht einen kleinen Stern zu bestellen ❤️~',
    tip3: '💘Vielen Dank für Ihre Aufmerksamkeit 💘',
    tip4: 'im Verein suchen',
    feedbackTip: 'Geek-James/ddBuy',
    cardPrise: '🦉Willkommen bei Anpassungsangeboten🙉',
    moreMessage: 'Derzeit liegen keine Bestellungen vor',
    openCard: 'Greencard-Preis',
    recomend: 'uns auf Sie...',
    cardTip1: 'Grüne Karte öffnen',
    cardTip2: 'Alle Green Cards kosten 6,66 $.',
    cardTip3: 'Identifikation',
    cardTip4: 'exklusiv',
    vipType: 'Doppelintegral',
    vipTime: 'Privileg',
    vipRecommend: '50% Rabatt',
    prise1: 'Jahr 365 Tage',
    prise2: 'entspricht 0,24 $ pro Tag',
    vipType1: '¥88',
    vipTime1: '¥188',
    vipRecommend1: '70% Rabatt',
    prise11: '90-Tage-Saisonkarte',
    prise12: 'etwa 0,33 $ pro Tag',
    payMethod: '¥30',
    wechatPay: '¥45',
    aliPay: 'Bezahlverfahren',
    huabeiPay: 'Zahlung per Post',
    payImmeatally: 'und Geld bezahlt',
    cardUeless: 'Zahlen',
    tip: 'Sofortzahlung',
    changeNickName: 'Die Grüne Karte wurde noch nicht geöffnet.',
    confirm: 'Das ist alles! Ich kann es nicht~~',
    nickName: 'Spitznamen bearbeiten',
    personal: 'Aufzeichnung',
    message: 'Bitte legen Sie einen neuen Spitznamen fest.',
    myBills: 'Persönliche Informationen erfolgreich bearbeitet!',
    persent: 'Spitzname',
    bill: 'mein gutschein',
    condition: 'Bitte drücken Sie die Nummer 520...',
    rmb: 'Coupon',
    myOrders: 'Keine Dauerwelle',
    itemsTitle: 'Zusätzlicher Rabatt von 1,5 $',
    personalInfo: 'Yuan',
    head: 'meine Bestellung',
    user_name: 'Alle, spätere Ausgaben, Erhalt von Gegenständen, um zu punkten',
    userSex: 'persönliche Informationen',
    Brithday: 'Kopf',
    phoneNumber: 'Spitzname',
    noInput: 'Sex',
    logout: 'Geburtstag',
    infoTip: 'Telefonnummer',
    loginInfo: 'nicht ausfüllen',
    infoSuccess: 'Ausloggen',
    year: 'Persönliche Informationen erfolgreich bearbeitet',
    month: 'Möchten Sie sich wirklich abmelden?',
    day: 'Zurückziehen!',
    man: 'Jahr',
    woman: 'Monat',
    greenCard: 'Tag',
    greenCardPower: 'gutaussehend',
    fiftypecert: 'schöne Person',
    cardRecoamnd: 'Grüne Karte',
    TodayExclusivecoupon: 'Greencard-Berechtigung',
    everyUpadate: '50% Rabatt für Green Card',
    to: 'Green Card erhält jeden Tag Rabattcoupons.',
    toUse: 'Nimm heute alles.',
    immeatallyGet: 'Aktualisierung täglich um 0 Uhr',
    weekBill: 'füllen',
    cardTip7: 'Richten Sie ein Projekt ein',
    billTip: 'Hol es dir jetzt',
    currentGoods: 'Diese Woche gibt es Punkte.',
    cardShop: 'Hol es dir jetzt',
    cardBack: 'Green Card erhält jeden Tag Rabattcoupons.',
    cardSpecialTip: 'Ihren aktuellen Einkauf',
    cardSpecial: 'Öffnen Sie das Einkaufen mit der grünen Karte',
    cardPriseb: 'Das umgekehrte Integral ist',
    cardPrisea: 'Sofortiger Start von Integralen',
    yearCard: 'spezielle grüne Karte',
    openCards: '$180',
    onece: '$88',
    twice: 'Jahreskarte'
  },
  // 订单
  order: {
    outTimeGetMoney: 'mehr als 10 Minuten kompensiert',
    inputForm: 'Auftragserfüllung',
    location: 'Wählen Sie eine Adresse aus, die Sie erhalten möchten',
    arrivalTime: 'es ist Zeit',
    total: 'Fortfahren!',
    thing: 'etwas',
    wechatPay: 'Zahlung per Post',
    aliPay: 'und Geld bezahlt',
    huabeiPay: 'Zahlen',
    use: 'verwenden',
    order12: 'Integral',
    mark: 'Notiz',
    tip: 'Das Ausfüllen der Informationen sagt uns das Was wünschen Sie sich besonders?',
    goodsList: 'Produktliste',
    totalMoney: 'Anzahl der Teile',
    sendMoney: 'Versandkosten',
    point: 'Integral',
    order19: 'tatsächlich bezahlt',
    pay: 'tatsächlich bezahlt',
    sendForm: 'Bestellung senden',
    selectArrivalTime: 'Lieferzeit wählen',
    comfirm: 'Sicher',
    goods: 'Liste',
    all: 'Fortfahren!',
    things: 'etwas',
    sigalPrice: 'Nur Preis:',
    numbers: 'Menge:',
    addTip: 'Ich habe immer noch keine Adresse hinzuzufügen.',
    addLocation: 'Adresse hinzufügen',
    comfirmAndUse: 'speichern und verwenden',
    editLocation: 'Adresse bearbeiten',
    myAddress: 'meine Adresse',
    settlement: 'zustimmen',
    chooseGoods: 'Bitte wählen Sie einen Artikel aus berechnen',
    deliveryTime: 'Bitte wählen Sie eine Lieferzeit aus.',
    today: 'Heute',
    tomorrow: 'morgen'
  },
  Login: {
    indexTitle: 'Anmeldung',
    Daftar: {
      title: 'Anmeldung',
      phone: 'Bitte geben sie ihre Mobiltelefonnummer ein.',
      code: 'Bitte geben Sie den Bestätigungscode ein.',
      password: 'Bitte geben Sie Ihr Passwort ein.',
      againPassword: 'Bitte geben Sie Ihr Passwort erneut ein.',
      remenber: 'Passwort merken'
    },
    Forgot: {
      sendCode: 'gesendet',
      confirm: 'sende Informationen',
      goBack: 'geh zurück'
    },
    nameInput: 'Bitte geben sie ihre Mobiltelefonnummer ein.',
    pwdInput: 'Bitte geben Sie Ihr Passwort ein.',
    remenber: 'Passwort merken',
    loginButton: 'Anmeldung',
    registrasi: 'registrieren'
  },
  // 登录
  login: {
    title: 'Anmeldung',
    phoneNumber: 'Telefonnummer',
    phoneNumberNotCorrect: 'Ungültiges Telefonnummernformat',
    phoneNumberNotEmpty: 'Telefonnummer nicht verfügbar',
    passNumberNotEmpty: 'Das Passwort darf nicht leer sein.',
    phoneInput: 'Bitte geben Sie Ihre Telefonnummer ein.',
    pass: 'Passwort',
    passTip: 'Bitte geben Sie Ihr Passwort ein.',
    varify: 'Bitte geben Sie den Bestätigungscode ein.',
    otherMethods: 'Andere Anmeldemethoden',
    wechat: 'Melden Sie sich an, um E-Mails zu lesen',
    qqchant: 'Warteschlange Warteschlange anmelden',
    tipTile: 'Guter Rat:',
    tipContent: 'Telefonnummer Unregistrierte werden automatisch bei der Anmeldung bei einem zustimmenden Agenten registriert.',
    tip: 'Benutzerprotokoll',
    tipProcy: 'Datenschutzstrategie',
    login: 'Anmeldung',
    resgin: 'registrieren',
    passTip2: 'Bitte geben Sie ein Passwort ein (mindestens 6 Bit).',
    sendVerify: 'Bestätigungscode senden',
    hasSend: 'gesendet',
    phoneVerify: 'Login-Kontonummer',
    smsMessage: 'Verschlüsselte Nachrichtenüberprüfung',
    switchLoginMsg: 'Login-Kontonummer',
    messageSuccess: 'Passwort erfolgreich eingegeben, bitte geben Sie es in das Eingabefeld ein.',
    pleaseInputCorrectPhoneNumber: 'Bitte geben Sie eine gültige Telefonnummer ein.',
    pleaseInputCorrectVerifyumber: 'Bitte geben Sie einen gültigen Bestätigungscode ein.',
    otherWechat: 'E-Mail-Anmeldung - Nicht abgeschlossen',
    otherQQ: 'Angemeldete Warteschlange - Nicht abgeschlossen',
    message: 'Passwortüberprüfung:'
  },
  Hint: {
    notMore: 'nicht mehr',
    loading: 'Wird geladen',
    phoneNot: 'Telefonnummer oder Kontonummer ist nicht verfügbar',
    SendCodeSuccess: 'Bestätigungscode erfolgreich gesendet!',
    pwdInconformity: 'Zwei Passwörter sind inkonsistent.',
    notInput: 'Geben Sie unvollständige Informationen ein.',
    failed: 'scheitern'
  },
  newTab: {
    home: 'Startseite',
    money: 'nachfüllen',
    order: 'Reihenfolge',
    my: 'mein'
  },
  tab: {
    make_money: 'Empfang'
  },
  Cart: {
    zzInfo: {
      buyIng: 'kaufen',
      buyFailed: 'Kauf gescheitert',
      notLevel: 'Ihnen fehlen Mitgliedspunkte.',
      buyJurisdiction1: 'Wenn es notwendig ist, eine Goldbestellung für Mitglieder zu kaufen',
      buyJurisdiction2: 'Wenn es notwendig ist, die Platinbestellung des Mitglieds zu kaufen',
      buyJurisdiction3: 'Wenn es notwendig ist, bestellte Diamanten zu kaufen',
      invite1: 'Laden Sie 3 Freunde ein, um gemeinsam Geld zu verdienen.',
      invite4: 'Laden Sie 10 Freunde ein, um gemeinsam Geld zu verdienen.',
      invite3: 'Lade 30 Freunde ein und verdiene Geld',
      invite2: 'Freunde einladen',
      myMoney: 'Ihr Kontostand',
      under: 'niedriger',
      notSendMoney: 'nicht verhandelbar',
      goTopUp: 'Bitte machen Sie Verstärkung.',
      know: 'verstehen',
      account: 'zustimmen'
    },
    hint: {
      p1: '1. Es stehen verschiedene Mitgliedschaftsstufen zum Kauf zur Verfügung. anders',
      p2: '2. Je höher die Mitgliedsstufe, desto höher die Provision.',
      p3: '3. Für eine Bestellung können Sie jederzeit den Bestellstatus einsehen.'
    },
    state: {
      all: 'alle',
      going: 'im Gange',
      paid: 'bereits bezahlt',
      frozen: 'es ist immer noch',
      over: 'fertig',
      canceled: 'stornieren'
    },
    info: {
      allMoney: 'Mein Gesamtvermögen ฿',
      dayAllOrder: 'Die vollständige Liste von heute',
      dayTrustMoney: 'Heute Kim zugewiesen',
      yesterdayMoney: 'Das Ergebnis von gestern'
    },
    text: {
      one: 'Reihenfolge'
    }
  },
  My: {
    download: 'APP-Download',
    personal: 'persönliche Informationen',
    recharge: 'Rekord auffüllen',
    withdrawal: 'Nimm Geld raus',
    language: 'Sprache einstellen',
    logout: 'verlassen',
    MemberInformation: {
      balance: 'Kontostand',
      Withdraw: 'Nimm Geld raus',
      Recharge: 'nachfüllen',
      forzenAll: 'alles aufhören'
    },
    PersonalInformation: {
      Page: {
        Avatar: 'Kopf',
        Nickname: 'Spitzname',
        PhoneNumber: 'Telefonnummer',
        BankAccounts: 'meine Wallet-Adresse',
        Password: 'Passwort',
        TransactionPassword: 'Transaktions Passwort',
        title: 'persönliche Informationen'
      },
      portrait: {
        SetAvatar: 'Kopf setzen',
        button: 'richtig'
      },
      Name: {
        ModifyNickname: 'Spitznamen bearbeiten',
        title: 'Spitznamen bearbeiten',
        name: 'Spitzname',
        Please: 'Bitte geben Sie einen Spitznamen ein die Sie bearbeiten möchten'
      },
      Password: {
        title: 'Login-Code bearbeiten',
        PleaseEnter: 'Bitte geben Sie Ihr Passwort ein. die Sie bearbeiten möchten',
        phone: 'Mobiltelefon',
        NewPhone: 'Bitte geben sie ihre Mobiltelefonnummer ein.',
        verificationCode: 'Code überprüfen',
        verificationCodeNote: 'Bitte geben Sie den Bestätigungscode ein.',
        ConfirmPassword: 'Passwort',
        ConfirmPasswordNote: 'Bitte geben Sie Einblicke ein. die Sie bearbeiten möchten',
        Text: 'Bitte merken Sie sich Ihr Passwort, wenn Sie Ihr Passwort vergessen haben, wenden Sie sich bitte an den Kundendienst.',
        Button: 'Bearbeitung bestätigen',
        ButtonCode: 'Bestätigungscode erhalten'
      },
      AccountData: {
        title: 'Handelsdetails'
      }
    }
  },
  newAdd: {
    all: 'alle',
    Completed: 'fertig',
    unfinished: 'Noch nicht fertig',
    Income: 'Einkommen',
    Expenditure: 'Kosten',
    GetCodeBtn: 'Bestätigungscode erhalten',
    copySuccess: 'erfolgreich kopiert',
    copyError: 'Kopieren fehlgeschlagen',
    noLoginUserName: 'Anmeldung',
    Withdraw: 'Nimm Geld raus',
    Recharge: 'nachfüllen',
    commission: 'Kommission',
    single: 'Reihenfolge',
    missionHall: 'Labor',
    videoTutorial: 'Videounterricht',
    helpCenter: 'Hilfezentrum',
    inviteFriends: 'Freunde einladen',
    help1Title: 'So verdienen Sie Provisionen über CG',
    help1Context: 'Eigentümer von Elektrizitätsunternehmen müssen den Umsatz im Geschäft steigern, daher müssen wir nur der Aufforderung des CG-Geschäfts folgen, um die Bestellung abzuschließen, der Eigentümer gibt uns eine Provision, jeder Benutzer kann 10 Aufgaben pro Tag erhalten. Sie verdienen 10 % bis 30 % Provision innerhalb eines Tages.',
    help2Title: 'So erhöhen Sie die Anzahl der Aufgaben',
    help2Context: 'Weil es zu viele Teilzeitjobs gibt, fordert das Unternehmen Mitarbeiter dazu auf. Alles Teilzeitjobs Bei der Arbeit wird das Internet nur von einer Person genutzt, um Kontakt aufzunehmen und einen Job zu bekommen.',
    help3Title: 'Wie erledige ich die Arbeit?',
    help3Context: 'Verkaufszahlen steigern und Belohnungen entsprechend dem Handelsvolumen für jede erfolgreiche Bestellung erhalten Sie eine Provision. und der Verkäufer erlangte einen guten Ruf',
    referralLink: 'Links empfehlen',
    copy: 'Kopieren',
    paste: 'Dan',
    blance: 'Gleichgewicht',
    todayOrder: 'heutige Bestellung',
    provided: 'Diese Informationen wurden bereitgestellt',
    Transaction: 'zustimmen',
    Frozen: 'Unruhe',
    Done: 'fertig',
    Cancel: 'stornieren',
    ExpectedReturn: 'Voraussichtliche Rückkehr:',
    ImmediatePayment: 'Sofortzahlung',
    AvailableNum: 'wie oft verwendet',
    OrderImmediately: 'jetzt in den Ordner',
    MakeMoneyEveryDay: 'tägliches Einkommen',
    TodayRecord: 'Bestellbeschreibung',
    AllocatCommissions: 'Provisionsverteilung',
    FrozenAmount: 'offener Betrag',
    TaskMatch: 'zufällig ausgewählte Aufgaben',
    AvailableBalance: 'Verfügbares Guthaben',
    WaitDispatch: 'Bitte kontaktieren Sie den Filialleiter, um auf die Zuweisung zu warten!',
    OrderDescription: 'Bestellbeschreibung',
    CouldMatch: 'Aufgaben werden vom System zufällig zugeordnet:',
    OrderPerDay: 'Befehl.',
    DescriptionRate: 'Kombinieren Sie eine Provision für den Betrag der Bestellung.',
    DescriptionPeriod: '。',
    Description3: 'Das System basiert auf IBS-Technologie. und automatisch über die Cloud mit Produkten gekoppelt.',
    Description4: 'um nicht manipuliert zu werden Wenn die Bestellung nicht innerhalb von 20 Minuten bestätigt und geliefert wird, wird die Bestellung ausgesetzt.',
    Note: 'Notiz:',
    DescriptionNote: 'Wenn die Plattform mit dem Benutzer in der Bestellung übereinstimmt Die Plattform sendet Bestellinformationen an das Backend des Verkäufers. Wenn der Benutzer innerhalb von 20 Minuten keine Bestellung aufgibt, wird der Bestellbetrag vom System ausgesetzt. Konten, die Aufgaben erledigen, können Provisionen verdienen. Dies wirkt sich direkt auf die nächste Auftragszeit und das Provisionsverhältnis des Benutzers aus! Bitte schließen Sie die Ein- und Auszahlung innerhalb der Zeit nach Erhalt des Arbeitsauftrags ab!',
    OrderNum: 'Bestellnummer:',
    TotalOrderPrice: 'Gesamtpreis:',
    SubmitNow: 'sofort versenden',
    Record: 'Aufzeichnung',
    MemberAccount: 'Mitgliedskonto',
    AccountPlaceholder: 'Bitte geben Sie Ihr Konto ein.',
    RechargeAmount: 'Standard',
    RechargeAmountPlaceholder: 'Bitte geben Sie den Ladebetrag ein.',
    SelectRechargeMethod: 'Wählen Sie eine Form der Aufladung',
    NotBeenSentOrder: 'Die Bestellung wurde nicht versandt, bitte wenden Sie sich an das Personal und senden Sie die Bestellung ab!',
    TodayOrderOver: 'Schon bestellt, werde es morgen nochmal versuchen.',
    NotLogin: 'Sie sind nicht eingeloggt, bitte versuchen Sie es nach dem Login erneut!',
    HaveNoteMethod: 'eingeschränkte Öffnung Bitte wenden Sie sich an den Vertrieb!',
    Login: 'Anmeldung',
    AccountRecord: 'Kontowechsel',
    RealName: 'Vorname',
    IFSCCODE: 'ISK',
    BankName: 'Name der Bankkarte',
    BankNum: 'Bankkartennummer',
    Mobile: 'Mobiltelefon',
    MobileNum: 'Mobiltelefon',
    Email: 'Briefkasten',
    EmailRequired: 'Postfächer werden gefüllt.',
    AddBankCardNote: 'Bankkarte ist eine wichtige Information, bitte sorgfältig ausfüllen!',
    Submit: 'sende Informationen',
    AddBankCardTitle: 'Bankkarte hinzufügen',
    BankCard: 'Bankkarte',
    Name: 'Name?',
    CardNum: 'Kartennummer',
    Reading: 'Liest gerade...',
    AllBalance: 'Gesamtmenge',
    EnterWithdrawalAmount: 'Bitte geben Sie den Gesamtbetrag ein',
    AllOut: 'alle',
    AskNow: 'Nimm Geld raus',
    EnterPwdPlaceholder: 'Bitte geben Sie Ihr Passwort ein.',
    NotHaveCard: 'Sie haben noch keine Geldkarte verwendet. Möchten Sie eine Bankkarte verwenden?',
    GetBankInfoFail: 'Bankinformationen konnten nicht abgerufen werden.',
    EnterValidPayPwd: 'Bitte geben Sie ein gültiges Zahlungspasswort ein.',
    Balance: 'Gleichgewicht',
    Total: 'alle',
    total: 'alle',
    records: 'Aufzeichnung',
    DisplayPerPage: 'Quadratgesicht',
    NullNum: 'Bar',
    pages: 'Buchseite.',
    toAudit: 'nach Versuch',
    succeed: 'erfolgreich',
    RechargeRecordTitle: 'Rekord auffüllen',
    rests: 'andere',
    Arrive: 'gehen',
    forzenCommission: 'Kommission stoppen',
    companyIntro: 'Unternehmensprofil',
    companyTitle: 'Tata Mall ist Teil der Tata-Gruppe.',
    warmTitle: 'guter Rat',
    companyText: 'Die Tata Group ist Indiens größte Unternehmensgruppe, die 1997 gegründet wurde. 1868 in Mumbai, Indien Geschäftsbetrieb bezogen auf alle sieben Bereiche der Kommunikationstechnik und Ingenieurinformationen, Energiedienstleistungen, Konsumgüter. und chemische Produkte als Unternehmensgruppe Die Tata Group ist Indiens sich schnell entwickelnder Staat und hat mehr als 200 Unternehmen in mehr als 80 Ländern auf sechs Kontinenten. Der Gesamtumsatz im Geschäftsjahr 2021 betrug 100 Millionen US-Dollar und 58 % davon stammten aus dem internationalen Geschäft. Die Gruppe beschäftigt seit 153 weltweit rund 450.000 Mitarbeiter. Der Name Tata genießt in Indien hohes Ansehen, weil er an guten Werten und Geschäftsethik festhält. Das Beste der Welt von Forbes',
    depositAddress: 'Prepaid-Adresse',
    rechargeSteps: 'Aufladevorgang',
    completePayment: 'Nachschub abgeschlossen',
    rechargeNow: 'sofort auffüllen',
    rechargeCancel: 'Befüllung abbrechen',
    bankName: 'Bankkonto',
    payee: 'Kontobezeichnung',
    cardNum: 'Bank',
    reviewing: 'überprüfen',
    remittance: 'Das Geld wurde überwiesen',
    notThrough: 'scheitern',
    OrderID: 'Befehls-ID',
    Amount: 'Anzahl',
    toCutScreen: 'Schauen Sie sich die Bilder an!',
    cardTabBank: 'Bank',
    cardTabUSDT: 'USDT',
    name: 'Name',
    chainType: 'Kettentyp',
    newNavTitle: 'Bestellung senden',
    shopMoney: 'Anzahl der Produkte',
    actualMoney: 'wirklich versenden',
    commitBtnText: 'Bestellung anlegen',
    commitHint: 'unvollständige Eingabe'
  },
  // 我的
  "mine": {
    servier: 'Aufschlag',
    integral: 'Integral',
    balance: 'Austausch',
    record: 'Protokoll austauschen',
    wtdAmt: 'Punkte einlösen',
    avaBlc: 'Wechselbetrag',
    allWtd: 'Alle einlösen',
    close: 'stornieren',
    commit: 'bestätigen',
    wtdHint: 'Bitte geben Sie einen gültigen Auszahlungsbetrag ein.'
  },
  "add": {
    Commission: 'Kommission',
    Yesterday: 'gestern',
    Cumulative: 'Standard',
    Today: 'Heute',
    TeamNum: 'Gesamtgröße des Teamspeaks',
    TeamMoney: 'Gesamtprovision',
    Personalcenteraccountchangerecord: 'Kontoänderungsprotokoll',
    lockedbalance: 'offener Betrag',
    Cumulativeinvitation: 'Kraftstoff',
    Validinvitation: 'Überprüfung',
    Accumulatedrebate: 'Akkumulation'
  },
  'home.btn.wallet': 'Finanzen',
  'home.btn.recharge': 'senden',
  'home.btn.withdraw': 'Rückzug',
  'home.btn.invite': 'einladen',
  'home.btn.message': 'Nachricht',
  'page.grab': 'halt',
  orderCommission: 'Reihenfolge',
  commission: 'Kommission',
  todayIncome: 'heutiges Einkommen',
  personalEarnings: 'Einkommen von Menschen',
  completedOrders: 'ergänzen',
  lockedOrders: 'Schließfach',
  unfinishOrder: 'unvergleichlich',
  frozonAmount: 'instabiler Fonds',
  currentBalance: 'aktuelles Guthaben',
  'page.mine': 'Kontodetails',
  'mine.invitationCode': 'Eingeladene ID',
  'mine.serverCenter': 'Serverzentrum',
  'mine.tools.total': 'zusammen',
  'mine.tools.financial': 'Finanzen',
  'mine.tools.details': 'Einzelheiten',
  'mine.tools.freeze': 'offener Betrag',
  'mine.serviceTools.personal': 'persönlich',
  'mine.serviceTools.teamReport': 'Teamspeak-Bericht',
  'mine.serviceTools.invite': 'Familienkonto',
  'mine.serviceTools.message': 'Nachricht',
  'mine.serviceTools.aboutUs': 'um',
  'mine.serviceTools.security': 'Sicherheit',
  'mine.serviceTools.address': 'die Anschrift',
  'mine.serviceTools.inviteTask': 'Arbeit für den Gebrauch',
  'page.team': 'Team',
  'page.aboutus': 'um',
  'page.financial': 'Finanzen',
  'page.financialRecord': 'finanzielle Aufzeichnung',
  'hint.InternetErr': 'Netzwerkfehler, bitte versuchen Sie es später erneut!',
  totalDeposit: 'Gesamteinzahlung',
  totalRevenue: 'Bruttoeinkommen',
  'btn.transferInto': 'überweisen',
  'btn.transferOut': 'durchscheinend',
  'page.transferInto': 'überweisen',
  'page.transferOut': 'durchscheinend',
  balanceTransfer: 'Bilanz übertragen',
  availableBalance: 'das vorhandene Guthaben',
  depositRate: 'Depot',
  days: 'Tag',
  'hint.success': 'erfolgreich',
  'hint.fail': 'scheitern',
  'status.into': 'überweisen',
  'status.out': 'durchscheinend',
  'label.amount': 'Anzahl',
  'label.time': 'Zeit',
  Button: {
    cancle: 'stornieren',
    confirm: 'bestätigen'
  },
  'dialog.title.inputPaypass': 'Passwort des Datensatzes',
  'dialog.nopaypass': 'Menschen mit viel Erfahrung',
  'mine.serviceTools.lan': 'Sprache',
  checkLans: 'Sprachcode',
  'mine.tools.commission': 'Kommission',
  'page.commissionRecord': 'Kommission',
  'noticeList.all': 'alle',
  'noticeList.unread': 'ungelesen',
  successWithdrawof: 'erfolgreiche Ausführung',
  'home.title.notice': 'Notiz',
  'home.title.patener': 'Patent',
  'login.rememberPassword': 'Passwort merken',
  "page": {
    cart: 'Wagen',
    order: 'Nummer',
    financing: 'Finanzen',
    goods: 'Produkt',
    levelUpgrade: 'Levinzivil',
    PlatformIntroduction: '- Vorort',
    RuleDescription: 'Vorschriften',
    AgentCooperation: 'Asiatische Zusammenarbeit',
    CommonProblem: 'häufiges Problem',
    MemberUpgrade: 'Mitgliedschafts-Upgrade',
    login: 'Anmeldung',
    forgot: 'vergessen',
    registrasi: 'Registrator',
    record: 'Aufzeichnung',
    Recharge: 'aufladen',
    inviteFriends: 'Gast Freund',
    Withdraw: 'zurückziehen',
    Service: 'Aufschlag',
    DialoguePage: 'Diagrammseite',
    user: 'persönlich',
    RansferIn: 'Ranferrin',
    PersonalCenter: 'persönliches Zentrum',
    Portrait: 'Symbol',
    Name: 'Name',
    Account: 'Benutzerkonto',
    Password: 'Passwort',
    PaymentCode: 'Zahlungskennwort',
    Site: 'Grundstück',
    AccountData: 'Kontoänderungsprotokoll',
    RechargeRecord: 'Bestellung aufladen',
    WithdrawalRecord: 'Auftrag zurückziehen',
    InviteFriends: 'einladen',
    Announcement: 'bekannt geben',
    TheTeamReport: 'Mannschaftsbericht',
    BillRecord: 'Befehl',
    BankCardAdd: 'Bankkarte hinzufügen',
    NewRecharge: 'nachfüllen',
    RechargeList: 'Bestellung aufladen',
    newWithdraw: 'zurückziehen',
    withdrawList: 'Auftrag zurückziehen',
    SettingPayPwd: 'Zahlungskennwort ändern',
    MakeMoney: 'Geld verdienen',
    team: 'Team',
    qrPay: 'Zahlung per QR-Code',
    bankPay: 'Bankbezahlung',
    aboutus: 'über uns',
    financial: 'Finanzverwaltung',
    financialRecord: 'finanzielle Aufzeichnung',
    TransferInto: 'überweisen',
    TransferOut: 'heraus übertragen',
    CommissionRecord: 'Planke',
    Security: 'Sicherheit',
    Address: 'die Anschrift',
    Message: 'Information',
    InviteTask: 'eine Mission anfordern',
    Notice: 'bekannt geben',
    modifyPaymentPassword: 'Zahlungskennwort ändern'
  },
  "teamReport": {
    title: 'Mannschaftsbericht',
    subTitle: 'Überblick über das sekundäre Team',
    taskTitle: 'Laden Sie {num} Personen ein, sich zu registrieren und ihren Kauf abzuschließen.',
    bonus: 'Bonus',
    Unfinished: 'rückgängig machen',
    Getted: 'erhalten'
  },
  address: {
    name: 'Name',
    inputPlaceholder: 'Bitte eingeben',
    phone: 'Telefon',
    areaPlaceholer: 'Informationen zur Lieferadresse',
    submit: 'Aufzeichnung'
  },
  copyLink: 'Link kopieren',
  'Home.WithdrawSu.Balance': 'Gleichgewicht',
  copy: 'Kopieren',
  'My.PersonalInformation.Name.Modify': 'renovieren',
  'password.old': 'Altes Passwort',
  'password.old.placeholder': 'Log-Passwort',
  'password.confirmPassword': 'Behauptung',
  'password.confirmPassword.placeholder': 'Geben Sie das neue Passwort erneut ein.',
  'hint.passwordDiff': 'Wettbewerb der Zwillinge',
  'Login.Daftar.email': 'Email',
  'Login.emailInput': 'wieder bemerken',
  'My.PersonalInformation.Password.email': 'Email',
  'My.PersonalInformation.Password.NewEmail': 'Bitte geben Sie ihre E-Mail-Adresse ein.',
  'mine.serviceTools.certify': 'Zertifikat',
  expectedEarningsTitle: 'erwarteter Gewinn',
  'order.old': 'hoher Einkommensrang',
  'order.new': 'regelmäßige Bestellung',
  "loginType": {
    phone: 'Telefon',
    email: 'Email'
  },
  "Certification": {
    "type": {
      id: 'Ausweis',
      driver: 'Fahrerlaubnis',
      passport: 'Reisepass'
    },
    name: 'Name',
    license: 'amtliches Kennzeichen',
    upload: 'Dokumentarfilm',
    submit: 'sende Informationen',
    incompleteInformation: 'Flirten Sie mit Informationen und versuchen Sie es erneut!',
    submitSuccess: 'Hashbase-Datenauthentifizierung',
    photo: 'Lichtbildausweis',
    review: 'unter Überprüfung',
    verified: 'Geprüft'
  },
  "upload": {
    uploading: 'Hochladen...',
    failed: 'aufleveln'
  },
  "viGrabDesces": {
    '1': 'Jede Bestellung kann als Glücksbestellung ausgewählt werden und die Provision erhöht sich auf {rate} %. Bitte wenden Sie sich an Ihren Außendienstberater, um weitere Zuweisungen zu beantragen.',
    '2': 'Helfen Sie Shopee-Verkäufern, virtuelle Bestellungen zu erstellen Sichtbarkeit erhöhen und Provisionen verdienen',
    '3': 'Bitte wenden Sie sich nach Erhalt Ihrer Bestellung so schnell wie möglich an Ihren Tutor. Bestelldaten übermitteln und abschließen.',
    '4': 'Die Arbeit ist begrenzt Bitte beachten Sie bei der Auftragsannahme die Förderhinweise des Ausbilders.',
    '5': 'Der Rückerstattungsbetrag wird innerhalb von 5-10 Minuten nach Abschluss der Aufgabe ausgestellt. Bei Fragen wenden Sie sich bitte an einen Spezialisten oder Online-Kundendienst.',
    '6': 'Erledigen Sie von Shopee zugewiesene Aufgaben, verbessern Sie Ihr VIP-Level und verdienen Sie höhere Provisionen für Fackbook-Jobs.',
    '7': 'Indem Sie die vorgesehenen Shopee-Aufgaben erledigen, verdienen Sie zusätzliche Provisionen. für bestimmte Prämien Wenden Sie sich bitte an einen Werbespezialisten oder den Online-Kundendienst.'
  },
  rechargeDesc: {
    nav: 'Beschreibung der Aufladung',
    p1: 'Wir haben jetzt eine große Anzahl von Mitarbeitern, die Aufgaben gleichzeitig erledigen.',
    p2: 'Ein einzelner Verkäufer kann nicht genügend Konten bereitstellen.',
    p3: 'Also veröffentlichen wir unser Verkäuferkonto an einem anderen Ort.',
    p4: 'Wenn Sie eine Aufgabe erledigen möchten Sie können den Vertrieb kontaktieren, um Ihre Zahlungsmethode zu registrieren.'
  },
  chat: {
    list: {
      nav: 'Nachricht',
      hours: '{h} Stunde',
      minutes: '{m} Minuten',
      ago: '{time}Vorher',
      image: 'Bild'
    },
    room: {
      msgPlaceholder: 'Geben Sie eine Nachricht ein...',
      send: 'schicken',
      sender: 'Absender',
      sendTime: 'Uhrzeit senden',
      content: 'Inhalt'
    }
  },
  newBank: {
    usdt: {
      name: 'Name',
      currency: 'Währung',
      chainType: 'Kettentyp',
      depositAddress: 'Hinterlegungsadresse',
      submit: 'schicken',
      "bank": "Bank",
      "wallet": "Brieftasche",
      "bankName": "Bankname",
      "bankCard": "Bankkarte"
    },
    "walletList": {
      pageTitle: 'Brieftaschenverwaltung',
      name: 'Wallet-Alias:',
      coinType: 'Währung:',
      chainType: 'Kettentyp:',
      walletAddress: 'Adresse:',
      addDate: 'Datum:',
      btnText: 'Brieftaschenadresse hinzufügen',
      deleteConfirmText: 'Möchten Sie die Wallet-Adresse wirklich löschen?',
      btnConfirm: 'löschen',
      btnCancle: 'stornieren',
      successHint: 'erfolgreich'
    },
    withdrawNum: 'Auszahlungsbetrag',
    AllOut: 'alle',
    balance: 'Gesamtbilanz',
    handlingFee: 'Verwaltungsgebühr',
    actualAmount: 'echter Betrag'
  },
  "hashPlaceholder": "Bitte geben Sie den Einzelhandel ein",
  "recharge.status.error": "Amazon Standard -Konstruktionsfehler, bitte senden Sie den richtigen Einzelhandelswert und den Betrag des Elements",
  "recharge.actualAmount": "Tatsächlicher Konto",
  "login.userAgreement": "Nutzungsbedingungen",
  "login.title1": "Singen",
  "login.title2": "Willkommen zurück!",
  "registe.title1": "Registrieren",
  "registe.title2": "Willkommen zurück!",
  "Login.Daftar.selectCurrency": "Wählen Sie die Währung",
  "mine.totalBalance": "Gesamtsaldo",
  "login.forgetPassword": "Sie haben Ihr Passwort vergessen. Bitte wenden Sie sich an den Kundendienst!",
  "Home.HallofFame": "Ruhmeshalle",
  "btn.Identify": "Sofort lernen",
  "My.service": "Service",
  "mine.credit": "Kredit",
  "page.checkin": "Einchecken",
  "checkin.submit": "Einchecken",
  "checkin.success": "Melden Sie sich erfolgreich an",
  "checkin.fail": "Anmeldung fehlgeschlagen",
  "todayChecked": "Sie haben sich angemeldet, bitte wiederholen Sie den Vorgang nicht!",
  "submit.checked": "angemeldet",
  "hint.inviteBanned": "Entschuldigung, Ihr Konto hat noch kein Familienkonto eröffnet, bitte wenden Sie sich an den Kundenservice oder Ihren Mentor, um die Eröffnung zu beantragen!",
  "bannerText1": "{Name} vollständig {Money} Betrag Aufgabe, {Money1} Provision zu erhalten",
  "bannerText2": "Herzlichen Glückwunsch {Name} Wählen Sie die Premium -Aufgabe aus, um 30%Provision zu erhalten",
  "grap": {
    "btask": "Bonusaufgaben",
    "wtask": "Arbeitsaufgaben"
  },
  "new_goods": {
    "custdown": "Countdown",
    "hours": "STD",
    "minutes": "PROTOKOLL",
    "seconds": "Sekunden",
    "descBtn": "Aufgabenbeschreibung",
    "taskBtn": "Die Aufgabe erledigen"
  },
  "gift": {
    "gift_shop": "Geschenkeladen",
    "my_gift": "Mein Geschenk",
    "away": "Verschenken",
    "buy": "Kaufen",
    "overlay": {
      "inputPlaceholder": "Zimmernummer eingeben",
      "num": "Anzahl der Geschenke",
      "room": "Geschenk eine Zimmernummer",
      "no_data": "Kein Geschenk"
    },
    "detailTitle": "Geschenkbilanz",
    "num": "Menge",
    "room": "Zimmernummer"
  },
  "p2p": {
    "trade_method": {
      "buy": "Kaufen",
      "sell": "Verkaufen"
    },
    "coinList": {
      "orders": "{__} Bestellung(en)",
      "label": {
        "amount": "Betrag",
        "price": "Preis",
        "limit": "Limit"
      }
    },
    "tradeDetails": {
      "trade_title_sell": "Wie viele {__} möchten Sie verkaufen?",
      "trade_title_buy": "Wie viele {__} möchten Sie kaufen?",
      "submit_now": "{__} jetzt"
    },
    "orderDetails": {
      "sellTitle": "Warten auf Zahlung von der anderen Partei",
      "buyTitle": "Warten auf Ihre Zahlung",
      "sellHint": "Die andere Partei muss die Zahlung innerhalb von {__} abschließen, sonst wird die Bestellung automatisch storniert.",
      "buyHint": "Schließen Sie die Zahlung innerhalb von {__} ab. Nachdem Sie die Zahlung abgeschlossen haben, klicken Sie auf Verkäufer kontaktieren",
      "payInfo_title": "Zahlungsinformationen",
      "label": {
        "totalAmount": "Gesamtbetrag",
        "pricePerUnit": "Preis pro Einheit",
        "quantity": "Menge",
        "counterpaty": "Gegenpartei",
        "payMethod": "Zahlungsmethode",
        "fullName": "Vollständiger Name",
        "email": "E-Mail",
        "phone": "Telefonnummer"
      },
      "btn_service": "Verkäufer kontaktieren",
      "btn_sell": "Käufer kontaktieren"
    },
    "limitHint": {
      "notEnough": "Fehlgeschlagene Bestellung, Verkäufer hat nicht genügend USD",
      "less": "Fehlgeschlagene Bestellungen, Mindesthandelslimit {__}",
      "more": "Fehlgeschlagene Bestellungen, maximales Handelslimit {__}"
    }
  },
  "paypassDialog": "Sie haben noch kein Zahlungspasswort festgelegt. Gehen Sie zur Einrichtung."
}